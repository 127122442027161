<template>
  <div class="flex-row items-end page space-x-40">
    <div class="flex-col shrink-0 section">
      <div class="flex-col group">
        <div class="flex-col items-start group_2 space-y-3">
          <img
            class="image_2"
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882894718807943965.png"
          />
          <span class="font_2 text_2 text_3">CICA结构设计软件</span>
        </div>
        <img
          class="image_3"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882894729367824975.png"
        />
      </div>
      <div class="flex-row items-center group_5 space-x-26">
        <img
          class="image_4"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882057507488602865.png"
        />
        <span class="font_3 text_4">菜单栏</span>
      </div>
      <div class="flex-col group_9 space-y-41">
        <div class="flex-row items-center space-x-14">
          <img
            class="image_5"
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882633136208404824.png"
          />
          <span class="font_2 text_6">设计要素输入</span>
        </div>
        <span class="font_4 text_7">A1 单柱单拉结构-几何输入</span>
        <span class="self-start font_4 text_8">B1 工况输入-XX工况</span>
        <div class="flex-col items-start space-y-40">
          <span class="font_5 text_9">C1 结构设计性能的可视化</span>
          <span class="font_4 text_9">C2 结构设计性能的总结</span>
        </div>
      </div>
      <span class="self-center font_5 text_12">设置</span>
    </div>
    <div class="flex-col flex-auto group_3">
      <div class="flex-row justify-end items-center group_4 space-x-43-reverse">
        <span class="font_1 text">zhangsan，你好</span>
        <img
          class="image"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882633136164065978.png"
        />
      </div>
      <div class="flex-col section_2">
        <div class="flex-row group_6">
         <!-- <div class="flex-col items-center self-start group_15 space-y-241">
            <span class="font_1 text_2">疲劳</span>
            <span class="font_1 text_2">蠕变</span>
          </div>
          <div class="flex-col self-start group_11">
            <span class="self-center font_1 text_2">管件应力</span>
            <div class="flex-col justify-start items-center image-wrapper">
              <img
                class="image_7"
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882633136365875049.png"
              />
            </div>
            <span class="self-center font_1 text_2 text_11">管件应力</span>
          </div>
          <div class="flex-col items-start self-start group_14 space-y-250">
            <span class="font_1 text_2">管件应力</span>
            <span class="font_1 text_2">连接</span>
          </div> -->
          <div class="flex-col group_7 space-y-45">
               <div id="radar" ref="radar" style="width: 600px;height:600px;"></div>
          </div>
          <div class="flex-col group_7 space-y-45">
            <div class="flex-col self-end section_3">
              <div class="flex-col group_8">
                <span class="self-center font_3 text_2 text_5">工况输入-XX工况</span>
                <div class="flex-row items-center group_10 space-x-36">
                  <img
                    class="section_4" @click="echange(1)" v-if="eshow1"
                    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/638b18425a7e3f03100a62af/649947d8726ab50011f379b8/5b8668edfeb7f618c44b19484fe21183.png"
                  />
                   <div class="section_4" @click="change(1)" v-if="show1"></div>
                  <span class="font_3 text_2">单柱单拉</span>
                </div>
                <div class="flex-row items-center group_12 space-x-36">
                  <img
                    class="section_4" @click="echange(2)" v-if="eshow2"
                    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/638b18425a7e3f03100a62af/649947d8726ab50011f379b8/5b8668edfeb7f618c44b19484fe21183.png"
                  />
                  <div class="section_4" @click="change(2)" v-if="show2"></div>
                  <span class="font_3 text_2">单柱双拉</span>
                </div>
                <div class="flex-row items-center group_13 space-x-36">
                  <img
                    class="section_4"    @click="echange(3)" v-if="eshow3"
                    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/638b18425a7e3f03100a62af/649947d8726ab50011f379b8/5b8668edfeb7f618c44b19484fe21183.png"
                  />
                  <div class="section_4" @click="change(3)" v-if="show3"></div>
                  <span class="font_3 text_2">单柱三拉</span>
                </div>
              </div>
              <div class="flex-col justify-start items-center self-center button">
                <span class="text_2 text_10">下一步</span>
              </div>
            </div>
            <div class="flex-col justify-start items-center image-wrapper_2">
              <img
                class="image_8"
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882633136440219245.png"
              />
            </div>
          </div>
        </div>
        <div class="flex-col justify-start items-end relative group_16">
          <div class="section_6"></div>
          <div class="section_5 pos"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as echarts from 'echarts';
  export default {
     mounted() {
        let myChart = echarts.init(this.$refs.radar);
        let option = {

              title: {
                  text: '工况数据',
                  subtext: '工况数据',
                  x: '20',
                  y: '20',
                  textStyle: {
                      color: '#ffffff',
                      fontFamily: 'Microsoft YaHei',
                      fontSize: '32'
                  }
              },
              color: ['#46B9B2', '#D372B2'],
              tooltip: {
                  trigger: 'item'
              },
              grid: {
                  top: '23%'
              },
              legend: {
                  show: true,
                  padding: [3, 5],
                  right: '20',
                  y: '20',
                  bottom: 30,
                  center: 0,
                  itemWidth: 14,
                  itemHeight: 14,
                  itemGap: 21,
                  orient: "horizontal",
                  data: ['大连', '青岛'],
                  textStyle: {
                      fontSize: 0,
                  },
              },
              radar: {

                  // shape: 'circle',
                  name: {
                      textStyle: {
                          color: '#000000',
                          borderRadius: 13,
                          padding: [3, 5]
                      }
                  },
                  radius: '80%',
                  center: ['50%', '53%'],
                   splitLine: {//蛛网环形的线条颜色
                              lineStyle: {
                                  color: '#113865', // 分隔线颜色
                                  width: 1, // 分隔线线宽
                                  opacity: 0.5
                              }
                          },
                  indicator: [{
                          name: '管件应力',
                          max: 100
                      },
                      {
                          name: '管件应力',
                          max: 100
                      },
                      {
                          name: '连接',
                          max: 100
                      },
                      {
                          name: '管件应力',
                          max: 100
                      },
                      {
                          name: '蠕变',
                          max: 100
                      },
                      {
                          name: '疲劳',
                          max: 100
                      }
                  ]
              },
              series: [{
                  name: '单柱单拉' + '单柱双拉' + '单柱三拉',
                  type: 'radar',
                  data: [{
                          value: [30, 50, 40, 20, 80, 90],
                          name: '大连',
                          lineStyle: {
                               normal: {
                                  color: '#46B9B2',
                                  width:4
                              }
                          },
                          areaStyle: {
                              normal: {
                                  color: '#46B9B2',
                                  opacity: 0.5 ,
                              }
                          }
                      },
                      {
                          value: [60, 70, 80, 80, 70, 50],
                          name: '日本',
                            lineStyle: {
                               normal: {
                                  color: 'rgba(67,199,152,0.5)',
                                  width:4
                              }
                          },
                          areaStyle: {
                              normal: {
                                  color: 'rgba(67,199,152,0.5)',
                                   opacity: 0.5 ,
                              }
                          }
                      },
                      {
                          value: [50, 70, 90, 50, 64, 74],
                          name: '青岛',
                            lineStyle: {
                               normal: {
                                  color: '#D372B2',
                                  width:4
                              }
                          },
                          areaStyle: {
                              normal: {
                                  color: '#D372B2',
                                   opacity: 0.5 ,
                              },
                          },
                      }
                  ]
              }]

        };
        myChart.setOption(option);
      },
    components: {},
    data() {
      return {
        eshow1:0,
        eshow2:0,
        eshow3:0,
        show1:1,
        show2:1,
        show3:1,
      };
    },

    methods: {
          echange(val) {
             switch(val) {
               case 1:
                 this.eshow1 = 0;
                 this.show1 = 1;
                 break;
               case 2:
                 this.eshow2 = 0;
                 this.show2 = 1;
                 break;
               case 3:
                 this.eshow3 = 0;
                 this.show3 = 1;
                 break;
               default:
                 // 无效的参数
                 console.log('无效的参数');
             }
           },
         change(val) {
            switch(val) {
              case 1:
                this.eshow1 = 1;
                this.show1 = 0;
                break;
              case 2:
                this.eshow2 = 1;
                this.show2 = 0;
                break;
              case 3:
                this.eshow3 = 1;
                this.show3 = 0;
                break;
              default:
                // 无效的参数
                console.log('无效的参数');
            }
          },
    },
  };
</script>

<style scoped lang="scss">
  .page {
    background-color: #617ca1;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    .section {
      padding-bottom: 3.63rem;
      border-radius: 0px;
      background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882633136069826807.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 17.38rem;
      height: 67.5rem;
      .group {
        padding: 0 3.63rem;
        .group_2 {
          padding: 1.63rem 0 0.19rem;
          .image_2 {
            width: 3.25rem;
            height: 0.81rem;
          }
          .text_3 {
            font-size: 0.94rem;
            line-height: 1.06rem;
          }
        }
        .space-y-3 {
          & > *:not(:first-child) {
            margin-top: 0.19rem;
          }
        }
        .image_3 {
          margin-right: 0.88rem;
          width: 9.19rem;
          height: 0.5rem;
        }
      }
      .group_5 {
        margin-top: 3.63rem;
        padding-bottom: 2rem;
        border-bottom: solid 0.25rem #546f9a;
        .image_4 {
          margin-left: 4.63rem;
          width: 1.5rem;
          height: 1.5rem;
        }
        .text_4 {
          margin-right: 5.44rem;
          color: #fcfcfc;
          font-size: 1.31rem;
          letter-spacing: 0.066rem;
          text-transform: uppercase;
          opacity: 0.96;
        }
      }
      .space-x-26 {
        & > *:not(:first-child) {
          margin-left: 1.63rem;
        }
      }
      .group_9 {
        margin-top: 2.44rem;
        padding-left: 3.44rem;
        padding-right: 2.75rem;
        .space-x-14 {
          & > *:not(:first-child) {
            margin-left: 0.88rem;
          }
          .image_5 {
            width: 0.63rem;
            height: 0.5rem;
          }
          .text_6 {
            font-size: 0.94rem;
            line-height: 1.06rem;
            text-transform: uppercase;
            opacity: 0.89;
          }
        }
        .font_4 {
          font-size: 0.88rem;
          font-family: FZLTZHK;
          line-height: 0.94rem;
          color: #fcfcfc;
        }
        .text_7 {
          text-transform: uppercase;
          opacity: 0.87;
        }
        .text_8 {
          text-transform: uppercase;
          opacity: 0.86;
        }
        .space-y-40 {
          & > *:not(:first-child) {
            margin-top: 2.5rem;
          }
          .text_9 {
            text-transform: uppercase;
            opacity: 0.84;
          }
        }
      }
      .space-y-41 {
        & > *:not(:first-child) {
          margin-top: 2.56rem;
        }
      }
      .font_2 {
        font-size: 0.88rem;
        font-family: FZLTZHK;
        line-height: 1.13rem;
        color: #fcfcfc;
      }
      .font_5 {
        font-size: 0.88rem;
        font-family: FZLTZHK;
        line-height: 1rem;
        color: #fcfcfc;
      }
      .text_12 {
        margin-top: 33.56rem;
        font-size: 0.94rem;
        text-transform: uppercase;
        opacity: 0.48;
      }
    }
    .group_3 {
      margin-right: 0.81rem;
      .group_4 {
        padding-bottom: 2.06rem;
        .text {
          color: #fcfcfc;
          font-size: 1.06rem;
          line-height: 1.06rem;
          text-transform: uppercase;
          opacity: 0.74;
        }
        .image {
          width: 3.06rem;
          height: 3.06rem;
        }
      }
      .space-x-43-reverse {
        & > *:not(:last-child) {
          margin-right: 2.69rem;
        }
      }
      .section_2 {
        margin-right: 1.5rem;
        padding: 4.81rem 3.81rem 3.38rem 15.25rem;
        background-color: #ffffff;
        border-radius: 2.5rem 2.5rem 0 0;
        .group_6 {
          padding: 0.75rem 0;
          .group_15 {
            margin-top: 15.38rem;
          }
          .space-y-241 {
            & > *:not(:first-child) {
              margin-top: 15.06rem;
            }
          }
          .group_11 {
            margin-left: 3.5rem;
            margin-top: 5.63rem;
            width: 28.25rem;
            .image-wrapper {
              margin-top: 1.38rem;
              padding: 6.81rem 0 2.94rem;
              background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882633136327548000.png');
              background-size: 100% 100%;
              background-repeat: no-repeat;
              .image_7 {
                width: 23.94rem;
                height: 22.13rem;
              }
            }
            .text_11 {
              margin-top: 1.69rem;
            }
          }
          .group_14 {
            margin-left: 1.44rem;
            margin-top: 15rem;
          }
          .space-y-250 {
            & > *:not(:first-child) {
              margin-top: 15.63rem;
            }
          }
          .group_7 {
            margin-left: 3.38rem;
            width: 35.19rem;
            .section_3 {
              padding: 0 3.13rem 1.88rem;
              background-color: #494949;
              border-radius: 0.5rem;
              width: 19.88rem;
              .group_8 {
                padding: 1.88rem 0 2.75rem;
                .text_5 {
                  line-height: 1.13rem;
                }
                .group_10 {
                  margin-top: 2.44rem;
                  .image_6 {
                    border-radius: 0.5rem;
                    width: 2.31rem;
                    height: 2.25rem;
                  }
                }
                .space-x-36 {
                  & > *:not(:first-child) {
                    margin-left: 2.25rem;
                  }
                }
                .group_12 {
                  margin-top: 1rem;
                }
                .group_13 {
                  margin-top: 1.06rem;
                }
                .section_4 {
                  background-color: #2222227d;
                  border-radius: 0.5rem;
                  width: 2.31rem;
                  height: 2.19rem;
                }
              }
              .button {
                padding: 0.5rem 0;
                background-color: #cb8234;
                border-radius: 0.5rem;
                width: 10.94rem;
                border: solid 0.19rem #cb8234;
                .text_10 {
                  color: #2f2e2f;
                  font-size: 1.56rem;
                  font-family: FZLTZHK;
                  line-height: 1.63rem;
                }
              }
            }
            .image-wrapper_2 {
              margin-right: 0.31rem;
              padding: 11.38rem 0 10.44rem;
              background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882633136407985333.png');
              background-size: 100% 100%;
              background-repeat: no-repeat;
              .image_8 {
                width: 3.88rem;
                height: 3.88rem;
              }
            }
          }
          .space-y-45 {
            & > *:not(:first-child) {
              margin-top: 2.81rem;
            }
          }
        }
        .group_16 {
          padding: 0.13rem 0;
          .section_6 {
            background-color: #e0811c;
            border-radius: 0.16rem;
            width: 35.06rem;
            height: 0.63rem;
          }
          .section_5 {
            background-color: #494949;
            border-radius: 0.28rem;
            width: 4.25rem;
            height: 0.94rem;
          }
          .pos {
            position: absolute;
            right: 4rem;
            top: 0;
          }
        }
      }
      .font_1 {
        font-size: 1.13rem;
        font-family: FZLTZHK;
        line-height: 1.13rem;
        color: #b0aeae;
      }
    }
    .text_2 {
      text-transform: uppercase;
    }
    .font_3 {
      font-size: 1.38rem;
      font-family: FZLTZHK;
      line-height: 1.25rem;
      color: #a3a3a3;
    }
  }
  .space-x-40 {
    & > *:not(:first-child) {
      margin-left: 2.5rem;
    }
  }
</style>
