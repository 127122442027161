<template>
  <div class="flex-row items-end page space-x-39">
    <div class="flex-col shrink-0 section">
      <div class="flex-col items-start group_3 space-y-4">
        <img
          class="image_2"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16879472155531168818.png"
        />
        <img
          class="image_3"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/638b18425a7e3f03100a62af/649947d8726ab50011f379b8/0d1441c91362d4281a71ecf86af4f4d0.png"
        />
      </div>
      <div class="flex-row items-center group_4 space-x-25">
        <img
          class="shrink-0 image_4"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16879472162578572814.png"
        />
        <span class="font_1 text_2">菜单栏</span>
      </div>
      <div class="flex-col group_7 space-y-40">
        <div class="flex-row items-center space-x-15">
          <img
            class="image_6"
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16879472161729399565.png"
          />
          <img
            class="image_5"
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16879472155819800443.png"
          />
        </div>
        <img
          class="image_7"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16879472155891765814.png"
        />
        <img
          class="self-start image_8"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16879472162696467228.png"
        />
        <img
          class="image_9"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16879472155960412083.png"
        />
        <span class="self-start font_2 text_7">C2 结构设计性能的总结</span>
      </div>
      <span class="self-center text_11">设置</span>
    </div>
    <div class="flex-col flex-auto group">
      <div class="flex-row justify-end items-center group_2 space-x-43-reverse">
        <span class="text">zhangsan，你好</span>
        <img
          class="image"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16879472155595808633.png"
        />
      </div>
      <div class="flex-col justify-start items-start relative section_2">
        <div class="flex-col section_5">
          <span class="self-center font_1 text_3">输入坐标</span>
          <div class="flex-col justify-start items-start text-wrapper">
            <span class="font_2 text_5">
              <input data-v-094e468a="" class="font_2 text_5" style=" border: none;width: 100%;text-align: center;" placeholder="x坐标输入参数" />
              <br />
            </span>
          </div>
          <div class="flex-col justify-start items-start text-wrapper_2 view_3">
            <span class="font_2 text_5">
              y坐标输入参数
              <br />
            </span>
          </div>
          <div class="flex-col justify-start items-start text-wrapper_2 view_4">
            <span class="font_2 text_5">z坐标输入参数</span>
          </div>
        </div>
        <div class="flex-col justify-start group_5 pos">
          <div class="flex-col justify-start items-end section_7">
            <div class="flex-col section_8">
              <span class="self-center font_1 text_3">输入坐标</span>
              <div class="flex-col justify-start items-start text-wrapper_3">
                <span class="font_2 text_5">
                  x坐标输入参数
                  <br />
                </span>
              </div>
              <div class="flex-col justify-start items-start text-wrapper_4">
                <span class="font_2 text_5">
                  y坐标输入参数
                  <br />
                </span>
              </div>
              <div class="flex-col justify-start items-start text-wrapper_5">
                <span class="font_2 text_5">z坐标输入参数</span>
              </div>
            </div>
          </div>
          <div class="flex-col section_3 pos_2">
            <div class="flex-col group_6">
              <span class="self-center font_1 text_3 text_4">工况输入1-XX工况</span>
              <div class="section_4 view"></div>
              <div class="section_6"></div>
              <div class="section_4 view_2"></div>
              <div class="flex-row group_8 space-x-13">
                <div class="flex-col justify-start items-center flex-auto relative group_9">
                  <img
                    class="image_10"
                    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16879472162816614124.png"
                  />
                  <span class="font_3 text_6 pos_3">横向载荷</span>
                </div>
                <span class="self-start font_3 text_6 text_8">垂直载荷</span>
              </div>
              <span class="self-center font_3 text_6 text_9">纵向载荷</span>
            </div>
            <div class="flex-col justify-start items-center self-center button">
              <span class="text_3 text_10">下一步</span>
            </div>
          </div>
          <img
            class="image_11 pos_4"
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16879472163417085230.png"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    data() {
      return {};
    },

    methods: {},
  };
</script>

<style scoped lang="scss">
  .page {
    background-color: #617ca1;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    .section {
      padding: 2.56rem 0 3.25rem;
      border-radius: 0px;
      background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16879472155451018763.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      height: 67.5rem;
      .group_3 {
        padding: 0 3.19rem;
        .image_2 {
          width: 3.19rem;
          height: 0.69rem;
        }
        .image_3 {
          width: 9.13rem;
          height: 1.81rem;
        }
      }
      .space-y-4 {
        & > *:not(:first-child) {
          margin-top: 0.25rem;
        }
      }
      .group_4 {
        margin-top: 3.56rem;
        padding: 0 4.19rem 2rem;
        border-bottom: solid 0.19rem #546f9a;
        .image_4 {
          width: 1.56rem;
          height: 1.5rem;
        }
        .text_2 {
          margin-right: 1.38rem;
          color: #fcfcfc;
          font-size: 1.31rem;
          line-height: 1.31rem;
          letter-spacing: 0.066rem;
          text-transform: uppercase;
          opacity: 0.96;
        }
      }
      .space-x-25 {
        & > *:not(:first-child) {
          margin-left: 1.56rem;
        }
      }
      .group_7 {
        margin-top: 2.44rem;
        padding-left: 3rem;
        padding-right: 2.19rem;
        .space-x-15 {
          & > *:not(:first-child) {
            margin-left: 0.94rem;
          }
          .image_6 {
            width: 0.56rem;
            height: 0.5rem;
          }
          .image_5 {
            width: 6rem;
            height: 1.13rem;
          }
        }
        .image_7 {
          width: 11.5rem;
          height: 1rem;
        }
        .image_8 {
          width: 9.38rem;
          height: 1rem;
        }
        .image_9 {
          margin-right: 0.63rem;
          width: 10.88rem;
          height: 1rem;
        }
        .text_7 {
          color: #fcfcfc;
          font-size: 0.88rem;
          line-height: 0.94rem;
          text-transform: uppercase;
          opacity: 0.84;
        }
      }
      .space-y-40 {
        & > *:not(:first-child) {
          margin-top: 2.5rem;
        }
      }
      .text_11 {
        margin-top: 33.31rem;
        color: #fcfcfc;
        font-size: 0.94rem;
        font-family: FZLanTingHei-DB-GBK;
        line-height: 0.94rem;
        text-transform: uppercase;
        opacity: 0.48;
      }
    }
    .group {
      margin-right: 2.06rem;
      .group_2 {
        padding-bottom: 2rem;
        .text {
          color: #fcfcfc;
          font-size: 1.06rem;
          font-family: FZLanTingHei-DB-GBK;
          line-height: 1.06rem;
          text-transform: uppercase;
          opacity: 0.74;
        }
        .image {
          width: 3.06rem;
          height: 3rem;
        }
      }
      .space-x-43-reverse {
        & > *:not(:last-child) {
          margin-right: 2.69rem;
        }
      }
      .section_2 {
        margin-right: 1.56rem;
        padding: 10.88rem 0 34.5rem;
        background-color: #ffffff;
        border-radius: 2.5rem 2.5rem 0 0;
        .section_5 {
          margin-left: 12rem;
          padding: 1.38rem 1.31rem 1.69rem;
          background-color: #4949493d;
          border-radius: 0.5rem;
          .text-wrapper {
            margin-top: 1.94rem;
            padding: 0.63rem 0 0.38rem;
            background-color: #fcfcfc7d;
            border-radius: 0.5rem;
            width: 13.25rem;
          }
          .text-wrapper_2 {
            padding: 0.5rem 0;
            background-color: #fcfcfc7d;
            border-radius: 0.5rem;
            width: 13.25rem;
          }
          .view_3 {
            margin-top: 1.06rem;
          }
          .view_4 {
            margin-top: 1rem;
          }
        }
        .group_5 {
          padding-top: 26rem;
          .section_7 {
            padding: 10.31rem 0 5.81rem;
            background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16879472155388557851.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            .section_8 {
              margin-right: 24rem;
              padding: 1.44rem 1.25rem 1.63rem;
              background-color: #d3d3d3;
              border-radius: 0.5rem;
              .text-wrapper_3 {
                margin-top: 1.88rem;
                padding: 0.63rem 0 0.5rem;
                background-color: #fcfcfc7d;
                border-radius: 0.5rem;
                width: 13.31rem;
              }
              .text-wrapper_4 {
                margin-top: 1rem;
                padding: 0.5rem 0;
                background-color: #fcfcfc7d;
                border-radius: 0.5rem;
                width: 13.31rem;
              }
              .text-wrapper_5 {
                margin-top: 1rem;
                padding: 0.5rem 0 0.63rem;
                background-color: #fcfcfc7d;
                border-radius: 0.5rem;
                width: 13.31rem;
              }
            }
          }
          .section_3 {
            padding: 0 3.06rem 3.44rem;
            background-color: #494949;
            border-radius: 0.5rem;
            width: 19.75rem;
            .group_6 {
              padding: 1.81rem 0 3.63rem;
              .text_4 {
                color: #a3a3a3;
              }
              .section_4 {
                background-color: #2222227d;
                border-radius: 0.5rem;
                height: 2.25rem;
              }
              .view {
                margin-top: 1.81rem;
              }
              .section_6 {
                margin-top: 1rem;
                background-color: #2222227d;
                border-radius: 0.5rem;
                height: 2.19rem;
              }
              .view_2 {
                margin-right: 0.19rem;
                margin-top: 1rem;
              }
              .group_8 {
                margin-top: 3.06rem;
                padding-left: 1.44rem;
                .group_9 {
                  width: 8.56rem;
                  .image_10 {
                    width: 7.69rem;
                    height: 6.69rem;
                  }
                  .pos_3 {
                    position: absolute;
                    left: 0;
                    top: 0.63rem;
                  }
                }
                .text_8 {
                  margin-top: 2.25rem;
                }
              }
              .space-x-13 {
                & > *:not(:first-child) {
                  margin-left: 0.81rem;
                }
              }
              .font_3 {
                font-size: 0.69rem;
                font-family: FZLanTingHei-DB-GBK;
                line-height: 0.69rem;
                color: #ffffff;
              }
              .text_6 {
                text-transform: uppercase;
                opacity: 0.73;
              }
              .text_9 {
                margin-top: 0.5rem;
              }
            }
            .button {
              padding: 0.75rem 0;
              background-color: #df8d34;
              border-radius: 0.5rem;
              width: 10.81rem;
              .text_10 {
                color: #ffffff;
                font-size: 1.56rem;
                font-family: FZLanTingHei-DB-GBK;
                line-height: 1.63rem;
              }
            }
          }
          .pos_2 {
            position: absolute;
            right: 3.25rem;
            top: 0;
          }
          .image_11 {
            width: 48.13rem;
            height: 9.38rem;
          }
          .pos_4 {
            position: absolute;
            left: 19.88rem;
            bottom: 23.13rem;
          }
        }
        .pos {
          position: absolute;
          left: 0;
          right: -0.31rem;
          top: 3.25rem;
        }
        .text_3 {
          text-transform: uppercase;
        }
        .text_5 {
          margin-left: 2.06rem;
          text-transform: uppercase;
          opacity: 0.34;
        }
      }
    }
    .font_1 {
      font-size: 1.38rem;
      font-family: FZLanTingHei-DB-GBK;
      line-height: 1.25rem;
      color: #494949;
    }
    .font_2 {
      font-size: 0.81rem;
      font-family: FZLanTingHei-DB-GBK;
      line-height: 1.13rem;
      color: #3d3d3d;
    }
  }
  .space-x-39 {
    & > *:not(:first-child) {
      margin-left: 2.44rem;
    }
  }
</style>