<template>
  <div class="flex-row items-end page space-x-40">
    <div class="flex-col shrink-0 section">
      <div class="flex-col items-start group_3 space-y-4">
        <img
          class="image"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882627399555466452.png"
        />
        <img
          class="image_2"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/638b18425a7e3f03100a62af/649947d8726ab50011f379b8/413fcb0b988ab3fe969b423e42d73d2c.png"
        />
      </div>
      <div class="flex-row items-center group_4 space-x-27">
        <img
          class="image_3"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882057507488602865.png"
        />
        <img
          class="image_4"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882627399788399675.png"
        />
      </div>
      <div class="flex-col group_5 space-y-42">
        <div class="flex-col space-y-40">
          <div class="flex-row items-center space-x-16">
            <img
              class="image_6"
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882627405483255875.png"
            />
            <img
              class="image_5"
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882627399725499010.png"
            />
          </div>
          <img
            class="image_7"
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882627404341952313.png"
          />
        </div>
        <div class="flex-col items-start space-y-41">
          <img
            class="image_8"
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882627406239084929.png"
          />
          <img
            class="image_9"
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882627405042899386.png"
          />
          <img
            class="image_11"
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882627405383524038.png"
          />
        </div>
      </div>
      <img
        class="self-center image_13"
        src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882627399840067071.png"
      />
    </div>
    <div class="flex-col flex-auto group">
      <div class="flex-row justify-end items-center group_2 space-x-51-reverse">
        <span class="text">zhangsan，你好</span>
        <img
          class="switch"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882627399592791306.png"
        />
      </div>
      <div class="flex-col justify-start items-start relative section_2">
        <div class="flex-col justify-start items-end image-wrapper">
          <img
            class="image_12"
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882627406386157592.png"
          />
        </div>
        <div class="flex-col section_3 space-y-38 pos">
          <span class="self-center font_1 text_2 text_3">工况输入-XX工况</span>
          <div class="flex-col">
            <van-field
              placeholder="横向载荷&#10;"
              :border="false"
              class="inputValue_jga"
              v-model="inputValue_jga"
            ></van-field>
            <div class="flex-col justify-start items-start text-wrapper view">
              <span class="font_2 text_4">
                垂直载荷
                <br />
              </span>
            </div>
            <div class="flex-col justify-start items-start text-wrapper_2">
              <span class="font_2 text_4">纵向载荷</span>
            </div>
            <div class="flex-row group_6 space-x-13">
              <div class="flex-col justify-start items-center flex-auto relative group_7">
                <img
                  class="image_10"
                  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882627407284002130.png"
                />
                <span class="font_3 text_6 pos_3">横向载荷</span>
              </div>
              <span class="self-start font_3 text_6 text_15">垂直载荷</span>
            </div>
            <span class="self-center font_3 text_6 text_9">纵向载荷</span>
            <div class="flex-col justify-start items-center self-center text-wrapper_3">
              <span class="text_2 text_10">下一步</span>
            </div>
          </div>
        </div>
        <div class="flex-row justify-between section_4 pos_2">
          <div class="flex-col self-center group_8 space-y-318">
            <div class="flex-col justify-start items-center self-end button">
              <span class="font_1 text_2 text_8">请输入参数</span>
            </div>
            <div class="flex-col justify-start items-center self-start button">
              <span class="font_1 text_2 text_12">请输入参数</span>
            </div>
          </div>
          <div class="flex-col justify-start items-center self-start text-wrapper_4">
            <span class="font_1 text_2 text_11">请输入参数</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    data() {
      return {
        inputValue_jga: '',
      };
    },

    methods: {},
  };
</script>

<style scoped lang="scss">
  .page {
    background-color: #617ca1;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    .section {
      padding: 1.69rem 0 3.56rem;
      border-radius: 0px;
      background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882627399500356658.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      height: 67.5rem;
      .group_3 {
        padding: 0 3.63rem;
        .image {
          width: 3.25rem;
          height: 0.81rem;
        }
        .image_2 {
          width: 8.56rem;
          height: 1.63rem;
        }
      }
      .space-y-4 {
        & > *:not(:first-child) {
          margin-top: 0.25rem;
        }
      }
      .group_4 {
        margin-top: 3.75rem;
        padding-bottom: 2rem;
        border-bottom: solid 0.19rem #546f9a;
        .image_3 {
          margin-left: 4.69rem;
          width: 1.5rem;
          height: 1.5rem;
        }
        .image_4 {
          margin-right: 5.5rem;
          width: 3.94rem;
          height: 1.25rem;
        }
      }
      .space-x-27 {
        & > *:not(:first-child) {
          margin-left: 1.69rem;
        }
      }
      .group_5 {
        margin-top: 2.56rem;
        padding-left: 3.44rem;
        padding-right: 2.56rem;
        .space-y-40 {
          & > *:not(:first-child) {
            margin-top: 2.5rem;
          }
          .space-x-16 {
            & > *:not(:first-child) {
              margin-left: 1rem;
            }
            .image_6 {
              width: 0.56rem;
              height: 0.5rem;
            }
            .image_5 {
              width: 6rem;
              height: 1rem;
            }
          }
          .image_7 {
            width: 11.19rem;
            height: 0.94rem;
          }
        }
        .space-y-41 {
          & > *:not(:first-child) {
            margin-top: 2.56rem;
          }
          .image_8 {
            width: 8.56rem;
            height: 0.94rem;
          }
          .image_9 {
            width: 10.81rem;
            height: 0.94rem;
          }
          .image_11 {
            width: 9.88rem;
            height: 0.94rem;
          }
        }
      }
      .space-y-42 {
        & > *:not(:first-child) {
          margin-top: 2.63rem;
        }
      }
      .image_13 {
        margin-top: 33.63rem;
        width: 1.88rem;
        height: 0.94rem;
      }
    }
    .group {
      margin-right: 0.81rem;
      .group_2 {
        padding-bottom: 2.13rem;
        .text {
          color: #fcfcfc;
          font-size: 1.06rem;
          font-family: Adobe Heiti Std;
          line-height: 1rem;
          text-transform: uppercase;
          opacity: 0.74;
        }
        .switch {
          width: 3.06rem;
          height: 3rem;
        }
      }
      .space-x-51-reverse {
        & > *:not(:last-child) {
          margin-right: 3.19rem;
        }
      }
      .section_2 {
        margin-right: 1.5rem;
        padding-top: 29.44rem;
        background-color: #ffffff;
        border-radius: 2.5rem 2.5rem 0 0;
        .image-wrapper {
          padding: 10rem 0 16.5rem;
          background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882627399445101743.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          width: 98.25rem;
          .image_12 {
            margin-right: 45.31rem;
            width: 2.13rem;
            height: 5.06rem;
          }
        }
        .section_3 {
          padding: 2.06rem 3.19rem 2.88rem;
          background-color: #4949493d;
          border-radius: 0.5rem;
          width: 19.81rem;
          .text_3 {
            color: #494949;
            line-height: 1.19rem;
          }
          .inputValue_jga {
            background-color: #fcfcfc7d;
            border-radius: 0.5rem;
            padding: 0.44rem 1.56rem;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
          }
          .text-wrapper {
            padding: 0.5rem 0;
            background-color: #fcfcfc7d;
            border-radius: 0.5rem;
            width: 13.44rem;
          }
          .view {
            margin-top: 1.06rem;
          }
          .text-wrapper_2 {
            margin-top: 1.06rem;
            padding: 0.38rem 0 0.63rem;
            background-color: #fcfcfc7d;
            border-radius: 0.5rem;
            width: 13.44rem;
          }
          .font_2 {
            font-size: 0.81rem;
            font-family: Adobe Heiti Std;
            line-height: 1.13rem;
            color: #3d3d3d;
          }
          .text_4 {
            margin-left: 1.63rem;
            text-transform: uppercase;
            opacity: 0.34;
          }
          .group_6 {
            margin-top: 2.88rem;
            padding-left: 0.75rem;
            padding-right: 0.44rem;
            .group_7 {
              width: 8.63rem;
              .image_10 {
                width: 7.75rem;
                height: 6.81rem;
              }
              .pos_3 {
                position: absolute;
                left: 0;
                top: 0.63rem;
              }
            }
            .text_15 {
              margin-top: 2.31rem;
            }
          }
          .space-x-13 {
            & > *:not(:first-child) {
              margin-left: 0.81rem;
            }
          }
          .font_3 {
            font-size: 0.69rem;
            font-family: Adobe Heiti Std;
            line-height: 0.69rem;
            color: #3d3d3d;
          }
          .text_6 {
            text-transform: uppercase;
            opacity: 0.73;
          }
          .text_9 {
            margin-top: 0.38rem;
          }
          .text-wrapper_3 {
            margin-top: 3rem;
            padding: 0.75rem 0;
            background-color: #df8d34;
            border-radius: 0.5rem;
            width: 10.94rem;
            .text_10 {
              color: #ffffff;
              font-size: 1.56rem;
              font-family: Adobe Heiti Std;
              line-height: 1.63rem;
            }
          }
        }
        .space-y-38 {
          & > *:not(:first-child) {
            margin-top: 2.38rem;
          }
        }
        .pos {
          position: absolute;
          right: 4.5rem;
          top: 4.38rem;
        }
        .section_4 {
          padding: 16.44rem 3.06rem 1.25rem;
          background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882627406468658216.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          width: 51.19rem;
          .group_8 {
            width: 24.88rem;
            .button {
              padding: 0.63rem 0 0.81rem;
              background-color: #f7f7f7;
              border-radius: 0.5rem;
              width: 13.38rem;
              border: solid 0.19rem #cb8234;
              .text_8 {
                color: #3b2a2a;
              }
              .text_12 {
                color: #433232;
              }
            }
          }
          .space-y-318 {
            & > *:not(:first-child) {
              margin-top: 19.88rem;
            }
          }
          .text-wrapper_4 {
            margin-top: 14.75rem;
            padding: 0.75rem 0;
            background-color: #f7f7f7;
            border-radius: 0.5rem;
            width: 13.38rem;
            height: 3.19rem;
            border: solid 0.19rem #cb8234;
            .text_11 {
              color: #292e35;
            }
          }
        }
        .pos_2 {
          position: absolute;
          left: 22rem;
          top: 7.56rem;
        }
        .font_1 {
          font-size: 1.38rem;
          font-family: Adobe Heiti Std;
          line-height: 1.44rem;
        }
        .text_2 {
          text-transform: uppercase;
        }
      }
    }
  }
  .space-x-40 {
    & > *:not(:first-child) {
      margin-left: 2.5rem;
    }
  }
</style>