export const Calculations13 = {// CalculateGeometry.js
  // CalculateGeometry.js
    // CalculateGeometry.js
      calculateFromAngles(extension_length, angle_bar, angle_tension_bar, diameters, load) {
        const pi = Math.PI;
        const tand = angle => Math.tan(angle * Math.PI / 180);
        
        // 定义变量以避免未定义的引用
        let GFRP_maxstress = 0, GFRP_maxforce = 0, GFRP_maxbending = 0;
        
        try {
          const E = [extension_length, 0, 0];
          const D = [0, extension_length * tand(angle_bar / 2), 0];
          const B = [0, -extension_length * tand(angle_bar / 2), 0];
          const A = [0, 0, -extension_length * tand(angle_tension_bar)];
          
          const [B8, C8, D8] = A;
          const [B9, C9, D9] = B;
          const [B10, C10, D10] = D;
          const [B11, C11, D11] = E;
          const [B12, C12, D12] = [0, 0, 0];  // Assuming a center reference point
    
          const Tension_D = diameters[0];
          const Bar_D = diameters.slice(1);
          const [C6, D6] = Bar_D;
          
          const [A16, B16, C16] = load;
          
          const B14 = 50; // Example modulus for bar
          const A14 = 50; // Example modulus for tension
    
          // Avoid division by zero by ensuring D6 != C6
          if (D6 === C6) {
            throw new Error("内外径不能相等，以避免除以零的错误");
          }
    
          const area = pi * (D6 ** 2 - C6 ** 2) / 4;
          if (area === 0) {
            throw new Error("计算面积为零，可能因为内径和外径相等");
          }
    
          const G16 = 0.25 * pi * Tension_D ** 2;
          const J12 = 2;  // Assuming some calculations define J12 correctly before use
          
          GFRP_maxforce = Math.abs((A16 * J12 * 1000) / area);
          GFRP_maxstress = GFRP_maxforce / (pi * (D6 ** 2 - C6 ** 2) / 4); // Correct stress calculation
          GFRP_maxbending = 500; // Assuming a bending moment calculation
          
          return { GFRP_maxstress, GFRP_maxforce, GFRP_maxbending };
        } catch (error) {
          console.error("计算过程中发生错误: ", error);
          return { GFRP_maxstress: '错误: ' + error.message, GFRP_maxforce, GFRP_maxbending };
        }
      }

};
