export const Calculations21= {
	CalculateExcelfromAngle2b1t_for(extension_length, angle_bar, angle_tension_bar, diameters, load) {
	    const D = [extension_length, 0, 0];
	    const A = [0, 0, -extension_length * Math.tan(angle_tension_bar * Math.PI / 180)];
	    const C = [0, extension_length * Math.tan(angle_bar / 2 * Math.PI / 180), 0];
	    const B = [0, -extension_length * Math.tan(angle_bar / 2 * Math.PI / 180), 0];
	
	    const B8 = A[0], C8 = A[1], D8 = A[2];
	    const B9 = B[0], C9 = B[1], D9 = B[2];
	    const B10 = C[0], C10 = C[1], D10 = C[2];
	    const B11 = D[0], C11 = D[1], D11 = D[2];
	    const Tension_D = diameters[0];
	    const A6 = Tension_D;
	    const Bar_D = diameters.slice(1);
	    const B6 = Bar_D[0], C6 = Bar_D[1];
	
	    const A15 = load[0], B15 = load[1], C15 = load[2];
	    const B13 = 50;
	    const A13 = 50;
	
	    const F12 = 0.5 * (B9 + B10);
	    const G12 = 0.5 * (C9 + C10);
	    const H12 = 0.5 * (D9 + D10);
	    const G14 = ((B8 - B11) ** 2 + (C8 - C11) ** 2 + (D8 - D11) ** 2 + (F12 - B11) ** 2 + (G12 - C11) ** 2 + (H12 - D11) ** 2 - ((B8 - F12) ** 2 + (C8 - G12) ** 2 + (D8 - H12) ** 2)) / 2 / Math.sqrt((B8 - B11) ** 2 + (C8 - C11) ** 2 + (D8 - D11) ** 2) / Math.sqrt((F12 - B11) ** 2 + (G12 - C11) ** 2 + (H12 - D11) ** 2);
	    const F14 = Math.sqrt(1 - G14 ** 2);
	    const H14 = F14 / G14;
	    const J14 = ((B10 - B11) ** 2 + (C10 - C11) ** 2 + (D10 - D11) ** 2 + (F12 - B11) ** 2 + (G12 - C11) ** 2 + (H12 - D11) ** 2 - ((B10 - F12) ** 2 + (C10 - G12) ** 2 + (D10 - H12) ** 2)) / 2 / Math.sqrt((B10 - B11) ** 2 + (C10 - C11) ** 2 + (D10 - D11) ** 2) / Math.sqrt((F12 - B11) ** 2 + (G12 - C11) ** 2 + (H12 - D11) ** 2);
	    const I14 = Math.sqrt(1 - J14 ** 2);
	    const K14 = I14 / J14;
	
	    const G16 = (B11 - F12) / Math.sqrt((B11 - F12) ** 2 + (C11 - G12) ** 2 + (D11 - H12) ** 2);
	    const F16 = Math.sqrt(1 - G16 ** 2);
	    const H16 = F16 / G16;
	    const I16 = 0.25 * Math.PI * A6 ** 2;
	    const J16 = 0.25 * Math.PI * (B6 ** 2 - C6 ** 2);
	    const K16 = Math.PI * (B6 ** 4 - C6 ** 4) / 64;
	
	    const F18 = Math.sqrt((B8 - B11) ** 2 + (C8 - C11) ** 2 + (D8 - D11) ** 2);
	    const G18 = Math.sqrt((B10 - B11) ** 2 + (C10 - C11) ** 2 + (D10 - D11) ** 2);
	    const I18 = B15 / 2 / (3 * K16 * G18 * J14 / K14 / (J16 * G18 ** 3) + I14);
	    const H18 = 3 * K16 * G18 / (K14 * J16 * G18 ** 3) * I18;
	    const A17_1 = (A15 * G16 - C15 * F16) * G18;
	    const A17_2 = 3 * K16 * G18 * K14 * I14 / (J16 * G18 ** 3) + J14;
	    const A17_3 = A17_1 / (2 * H14 * B13 * J16 * J14 * A17_2);
	    const A17_4 = (C15 * G16 + A15 * F16) * G18 ** 3 / (2 * 3 * B13 * K16);
	    const A17_5 = (3 * K16 * G18 * K14 * I14 / (J16 * G18 ** 3) + J14);
	    const A17_6 = (F18 / (A13 * I16 * F14) + G18 * G14 / (2 * H14 * B13 * J16 * J14 * A17_5) + F14 * G18 ** 3 / (2 * 3 * B13 * K16));
	    const A17 = ((A17_3 + A17_4) / A17_6) * 1000;
	    const A19 = Math.max(A17, 0);
	    const K18 = 0.5 * (A19 / 1000 * G14 - (A15 * G16 - C15 * F16)) / ((3 * K16 * G18 * K14 * I14 / (J16 * G18 ** 3) + J14));
	    const J18 = 3 * K16 * G18 * K14 / (J16 * G18 ** 3) * K18;
	
	    const B19 = (I18 - K18) * 1000;
	    const C19 = (-I18 - K18) * 1000;
	    const D19 = G18 * Math.sqrt((H18 + J18) ** 2 + (((C15 * G16 + A15 * F16) - A19 / 1000 * F14) / 2) ** 2);
	    const E19 = G18 * Math.sqrt((H18 - J18) ** 2 + (((C15 * G16 + A15 * F16) - A19 / 1000 * F14) / 2) ** 2);
	
	    const H27 = C6 === 0 ? 420 : 520;
	    const H28 = B13 * 1000;
	    const H29 = 542;
	    const H30 = 424;
	    const H32 = 1;
	    const H33 = 1;
	    const B24 = C6, B25 = C6;
	    const C24 = B6, C25 = B6;
	    const D24 = J16, D25 = J16;
	    const E24 = K16, E25 = K16;
	    const F24 = Math.PI / 32 * B6 ** 3 * (1 - (C6 / B6) ** 4);
	    const F25 = F24;  // Same as F24 due to same calculation
	    const G24 = G18, G25 = G18;
	    const H24 = 1, H25 = 1;  // In MATLAB code, H24 and H25 seems to be placeholders for a condition which we assume to be always true.
	    const I24 = H24 * G24 / Math.sqrt(E24 / D24);
	    const I25 = H25 * G25 / Math.sqrt(E25 / D25);
	
	    const K27 = H32 * I25 / Math.PI * Math.sqrt(H27 / H28);
	    const K28 = Math.PI * (H28 * D25 * E25 / H27 / F25 ** 2) ** 0.5;
	    const K29 = 1 / 2 / K27 ** 2 * ((K27 ** 2 + 0.9806 * K28 / H29 * K27 + 0.9993) - ((K27 ** 2 + 0.9806 * K28 / H29 * K27 + 0.9993) ** 2 - 4 * K27 ** 2) ** 0.5);
	    const K30 = 1 / 2 / K27 ** 2 * ((K27 ** 2 + 0.4034 * K28 / H30 * K27 + 0.9952) - ((K27 ** 2 + 0.4034 * K28 / H30 * K27 + 0.9952) ** 2 - 4 * K27 ** 2) ** 0.5);
	    const K33 = K29 * H33;
	    const K34 = K30 * H33;
	    const J24 = C6 === 0 ? K34 : K33;
	    const J25 = J24;
	    const K24 = Math.abs(B19) / D24 / J24 + D19 * 1000 / F24;
	    const K25 = Math.abs(C19) / D25 / J25 + E19 * 1000 / F25;
	    const gkAD = C6 === 0 ? 168 : 208;
	    const M24 = K24 / gkAD;
	    const M25 = K25 / gkAD;
	
	    // Result calculations
	    const K24new = Math.abs(B19) / D24 + D19 * 1000 / F24;
	    const K25new = Math.abs(C19) / D25 + E19 * 1000 / F25;
	    const GFRP_maxstress = Math.max(Math.abs(K24new), Math.abs(K25new));
	    const GFRP_maxforce = Math.max(Math.abs(B19), Math.abs(C19));
	    const GFRP_maxbending = Math.max(Math.abs(D19), Math.abs(E19));
	
	    return { GFRP_maxstress, GFRP_maxforce, GFRP_maxbending };
	}

}