//下面是vue代码，如何通过lang的值在代码中所有提示词及元素的中英文状态
<template>
  <div class="flex-col justify-start page">
    <div class="flex-col justify-start section">
      <div class="flex-col section_2 space-y-105">
        <img
          class="self-start image" style="width:10rem;height:2.35rem;"
          src="logo1.png"
        />
		<div style="font-size:1.5rem;color: #fff;margin-top:-1.5rem;margin-left:90rem;"><span @click="langcn" style="font-size:1.5rem;color: #fff;">中文</span>/<span @click="langen" style="font-size:1.5rem;color: #fff;">English</span></div>
        <div class="flex-col">
          <div class="flex-col space-y-26">
            <span class="self-center text" style="font-size:4rem;">CICA结构设计软件</span>
            <span class="self-start text_2 text_3">CICA structural design software</span>
          </div>
          <div class="flex-col group space-y-31">
            <div class="flex-col justify-start items-center relative">
              <div class="section_3"></div>
              <img
                class="image_2 pos"
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/638b18425a7e3f03100a62af/649947d8726ab50011f379b8/6cdd0a85295d4f95db6ad271a11730e5.png"
              />
              <!-- <input class="font_1 text_4 pos_2" type="text" v-model="username" placeholder="请输入用户名" /> -->
              <van-field :placeholder="lang === 'en' ? 'username' : '请输入用户名'" :border="false" class="font_1 text_4 pos_2" v-model="username" style="background-color: transparent;"></van-field>

              <!-- <span class="font_1 text_4 pos_2">输入您的用户名</span> -->
            </div>

            <!-- <div class="flex-col justify-start items-center relative"> -->
             <!-- <div class="section_4"></div>
              <img
                class="image_2 pos_3"
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/638b18425a7e3f03100a62af/649947d8726ab50011f379b8/9f1d8f19dcde53fbbcfd2efcd2f5c8d7.png"
              />
              <van-field placeholder="请输入您的密码" :border="false" class="font_1 text_4 pos_2" v-model="password" style="background-color: transparent;"></van-field>
              --><!-- <span class="font_1 text_5 text_6 pos_5">输入您的密码</span> -->
            <!--  <div class="flex-col justify-start items-center text-wrapper pos_4" @click="checkyzm()">
                <span class="text_2 text_7">验证码</span>
              </div> -->
            <!-- </div> -->
            <div class="flex-col justify-start items-center relative">
              <div class="section_5"></div>
              <img
                class="image_3 pos_6"
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/638b18425a7e3f03100a62af/649947d8726ab50011f379b8/78354f990c3e5cfe813257634ace62a2.png"
              />
              <van-field :placeholder="lang === 'en' ? 'Password' : '请输入您的密码'" :border="false" class="font_1 text_4 pos_2" v-model="password" style="background-color: transparent;"></van-field>
              <!-- <span class="font_1 text_5 pos_7">输入您的验证码</span> -->
            </div>
            <span class="self-end font_1 text_2 text_8"  @click="checkmm" :style='lang === "en"?"font-size:0.8rem;":""'>{{lang === 'en' ? 'Forgot the Password' : '忘记密码'}}</span>
          </div>
          <div class="flex-col justify-start items-center relative button" >
            <div class="section_6"  @click="handleLogin" :style='(username&&password)?"background-color: rgba(231, 152, 52, 0.8784313725);":""'></div>
			<!-- <span v-if="username&&password" style="background-color: rgba(231, 152, 52, 0.8784313725);" class="font_1 text_2 text_9 pos_8" @click="handleLogin">登录</span> -->
            <span class="font_1 text_2 text_9 pos_8" @click="handleLogin">{{lang === 'en' ? 'Login' : '登陆'}}</span>
          </div>
          <!-- <div class="error-message">{{ errorMessage }}</div> -->
        </div>
      </div>
    </div>

  </div>
</template>

<script>
   import axios from 'axios';
  export default {
    components: {},
    data() {
      return {
		lang: localStorage.getItem("lang") || 'cn',
        username: '',
        password: '',
        captcha: '',
        errorMessage: ''
      };
    },

    methods: {
	  langcn()
	  {
		  this.lang='cn',
		  localStorage.setItem("lang",'cn');
	  },
	  langen()
	  {
	  		  this.lang='en',
	  		  localStorage.setItem("lang",'en');
			  console.log("this.lang");
	  },
	  checkyzm(){
		   this.$message.info('系统尚未配置短信服务');
	  },
	  checkmm(){
	  		   this.$message.info('请联系管理员获取访问权限');
	  },
      handleLogin() {
      // 执行登录逻辑
      // ...
      if (!this.username) {
            this.errorMessage = '请填写用户名';
          return;
          }
      if (!this.password) {
            this.errorMessage = '请填写密码';
          return;
          }
	    
	   const url = 'http://101.91.220.18/api/user/login';
	        const credentials = {
	          account: this.username,
	          password: this.password,
	        };
	        
	        axios.post(url, credentials)
	          .then(response => {
	            console.log('Success:', response.data);
				if(response.data.code==1)
				   {
				   // localStorage.clear();
				   localStorage.setItem("userinfo",JSON.stringify(response.data.data.userinfo));
				   this.$router.push('/chanpin1');
				   }
				   else
				   {
					this.$message.info(response.data.msg);
				   }
	            // 这里可以添加一些成功后的处理，如页面跳转或状态更新
	          })
	          .catch(error => {
	            console.error('Error logging in:', error);
	            // 这里可以添加一些错误处理代码
	          });  
      // localStorage.clear();
      // // 页面跳转
      // this.$router.push('/chanpin1');
    }




    },
  };
</script>

<style scoped lang="scss">
  .page {
    width:100vw;
	height:100vh;
    overflow-y: hidden;
    overflow-x: hidden;
    .section {
	  width:100vw;
	  height:100vh;
      background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16881986496739809568.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      .section_2 {
        padding: 10rem 6.13rem 20.06rem;
        background-color: #27365582;
        .image {
          width: 13.88rem;
          height: 3.44rem;
        }
        .space-y-26 {
          & > *:not(:first-child) {
            margin-top: 1.63rem;
          }
          .text {
            color: #ffffff;
            font-size: 3.81rem;
            font-family: FZLTHK;
            line-height: 3.63rem;
            letter-spacing: 0.19rem;
            text-transform: uppercase;
            text-shadow: 0 0.063rem #ffffff, 0 -0.063rem #ffffff, 0.063rem 0 #ffffff, -0.063rem 0 #ffffff;
          }
          .text_3 {
            margin-left: 38rem;
            color: #ffffff;
            font-size: 1.56rem;
            font-family: Arial;
            line-height: 1.19rem;
            letter-spacing: 0.078rem;
          }
        }

        .group {
          margin-top: 4.5rem;
          .section_3 {
            opacity: 0.91;
            background-color: #9c9b9b;
            border-radius: 0.75rem;
            width: 24.94rem;
            height: 3.56rem;
            border: solid 0.19rem #e59a37;
          }
          .image_2 {
            border-radius: 0.5rem;
            width: 3.13rem;
            height: 2.88rem;
          }
          .pos {
            position: absolute;
            left: 41.8rem;
            top: 50%;
            transform: translateY(-50%);
          }
          .text_4 {
            text-transform: uppercase;
            opacity: 0.46;
          }
          .pos_2 {
            position: absolute;
            left: 44.69rem;
            top: 50%;
            transform: translateY(-50%);
          }
          .section_4 {
            opacity: 0.88;
            background-color: #9c9b9b;
            border-radius: 0.75rem;
            width: 24.94rem;
            height: 3.63rem;
            border: solid 0.19rem #a6875e;
          }
          .pos_3 {
            position: absolute;
            left:  41.8rem;
            top: 50%;
            transform: translateY(-50%);
          }
          .text_5 {
            text-transform: uppercase;
            opacity: 0.21;
          }
          .text_6 {
            line-height: 1.44rem;
          }
          .pos_5 {
            position: absolute;
            left: 44.69rem;
            top: 50%;
            transform: translateY(-50%);
          }
          .text-wrapper {
            padding: 0.88rem 0;
            background-color: #e79834e0;
            border-radius: 0.75rem;
            width: 5.25rem;
            .text_7 {
              color: #ffffff;
              font-size: 0.94rem;
              font-family: FZLTHK;
              line-height: 1rem;
            }
          }
          .pos_4 {
            position: absolute;
            right: 42rem;
            top: 50%;
            transform: translateY(-50%);
          }
          .section_5 {
            opacity: 0.88;
            background-color: #9c9b9b;
            border-radius: 0.75rem;
            width: 24.94rem;
            height: 3.56rem;
            border: solid 0.19rem #a68458;
          }
          .image_3 {
            border-radius: 0.5rem;
            width: 3.13rem;
            height: 2.94rem;
          }
          .pos_6 {
            position: absolute;
            left:  41.8rem;
            top: 50%;
            transform: translateY(-50%);
          }
          .pos_7 {
            position: absolute;
            left: 44.69rem;
            top: 50%;
            transform: translateY(-50%);
          }
          .text_8 {
            margin-right: 43.13rem;
            line-height: 1.38rem;
          }
        }
        .space-y-31 {
          & > *:not(:first-child) {
            margin-top: 1.94rem;
          }
        }
        .text_2 {
          text-transform: uppercase;
        }
        .button {
          margin-top: 2rem;
          .section_6 {
            opacity: 0.95;
            background-color: #7b6a54;
            border-radius: 0.75rem;
            width: 24.94rem;
            height: 3.56rem;
            border: solid 0.19rem #836a49;
          }
          .text_9 {
            color: #403b38;
            line-height: 1.25rem;
          }
          .pos_8 {
            position: absolute;
            left: 50.88rem;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        .font_1 {
          font-size: 1.38rem;
          font-family: FZLTHK;
          letter-spacing: 0.069rem;
          line-height: 1.5rem;
          color: #ffffff;
        }
      }
      .space-y-105 {
        & > *:not(:first-child) {
          margin-top: 4.56rem;
        }
      }
    }
  }
</style>
