export const Calculations11 = {
   calculateFromAngle(extension_length, angle_bar, diameters, load) {
       const geometry = this.readGeometry(extension_length, angle_bar, diameters);
       const material = {
           B11: 50, // GPa
           A11: 50  // GPa
       };
       return this.calculateExcel(geometry, material, load);
   },
   
   readGeometry(extension_length, angle_bar, diameters) {
       const C = [extension_length, 0, 0];
       const B = [0, 0, 0];
       const A = [0, 0, -extension_length * Math.tan(angle_bar * Math.PI / 180)];
       const Tension_D = diameters[0];
       const Bar_D = diameters.slice(1, 3);
       
       return {
           B7: A[0], C7: A[1], D7: A[2],
           B8: B[0], C8: B[1], D8: B[2],
           B9: C[0], C9: C[1], D9: C[2],
           A5: Tension_D,
           B5: Bar_D[0], C5: Bar_D[1]
       };
   },
   
   calculateExcel(geometry, material, load) {
       const {B7, C7, D7, B8, C8, D8, B9, C9, D9, A5, B5, C5} = geometry;
       const [A13, B13, C13] = load;
       const {B11, A11} = material;
   
       const G11 = ((B9-B8)**2 + (C9-C8)**2 + (D9-D8)**2 + (B7-B9)**2 + (C7-C9)**2 + (D7-D9)**2 - ((B7-B8)**2 + (C7-C8)**2 + (D7-D8)**2)) / (2 * Math.sqrt((B9-B8)**2 + (C9-C8)**2 + (D9-D8)**2) * Math.sqrt((B7-B9)**2 + (C7-C9)**2 + (D7-D9)**2));
       const F11 = Math.sqrt(1 - G11**2);
       const H11 = F11 / G11;
       const I11 = 0.25 * Math.PI * A5**2;
       const J11 = 0.25 * Math.PI * (B5**2 - C5**2);
       const H13 = Math.PI * (B5**4 - C5**4) / 64;
       const I13 = Math.sqrt((B7-B9)**2 + (C7-C9)**2 + (D7-D9)**2);
       const J13 = Math.sqrt((B9-B8)**2 + (C9-C8)**2 + (D9-D8)**2);
       const G13 = (B9 - B8) / J13;
       const F13 = Math.sqrt(1 - G13**2);
   
       const A15 = (A11 * B11 * I11 * J11 * F11 * H11 * J13**3 * (C13 * G13 + A13 * F13) + 3 * B11 * H13 * A11 * I11 * F11 * J13 * (A13 * G13 - C13 * F13)) / (3 * B11 * J11 * H11 * B11 * H13 * I13 + 3 * B11 * H13 * A11 * I11 * F11 * J13 * G11 + A11 * B11 * I11 * J11 * F11 * H11 * J13**3 * F11) * 1000;
       const A17 = Math.max(A15, 0);
       const B17 = (A13 * G13 - C13 * F13) * 1000 - A17 * G11;
       const C17 = Math.sqrt(((C13 * G13 + A13 * F13) - A17 / 1000 * F11)**2 + (B13)**2) * J13;
   
       const G24 = C5 === 0 ? 420 : 520;
       const G25 = B11 * 1000;
       const G26 = 542;
       const G27 = 424;
       const G29 = 1;
       const G30 = 1;
       const E22 = Math.PI / 32 * B5**3 * (1 - (C5 / B5)**4);
       const F22 = J13;
       const H22 = G29 * F22 / Math.sqrt(H13 / J11);
   
       const J24 = G29 * H22 / Math.PI * Math.sqrt(G24 / G25);
       const J25 = Math.PI * Math.sqrt(G25 * J11 * H13 / G24 / E22**2);
       const J26 = 0.5 / J24**2 * ((J24**2 + 0.9806 * J25 / G26 * J24 + 0.9993) - Math.sqrt((J24**2 + 0.9806 * J25 / G26 * J24 + 0.9993)**2 - 4 * J24**2));
       const J27 = 0.5 / J24**2 * ((J24**2 + 0.4034 * J25 / G27 * J24 + 0.9952) - Math.sqrt((J24**2 + 0.4034 * J25 / G27 * J24 + 0.9952)**2 - 4 * J24**2));
       const J30 = J26 * G30;
       const J31 = J27 * G30;
   
       const I22 = C5 === 0 ? J31 : J30;
       const J22 = Math.abs(B17 / 1000) * 1000 / J11 / I22 + C17 * 1e3 / E22;
       const gkAD = C5 === 0 ? 152 : 148;
       const L22 = J22 / gkAD;
   
       const J22new = Math.abs(B17 / 1000) * 1000 / J11 + C17 * 1e3 / E22;
       return {
           GFRP_maxstress: Math.abs(J22new),
           GFRP_maxforce: Math.abs(B17),
           GFRP_maxbending: C17
       };
   }

};
