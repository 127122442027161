<template>
  <div class="flex-row items-end page space-x-40">
    <div class="flex-col shrink-0 section">
      <div class="flex-col group">
        <div class="flex-col items-start group_2 space-y-3">
          <img
            class="image"
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882686267446789198.png"
          />
          <span class="font_2 text_2 text_3">CICA结构设计软件</span>
        </div>
        <img
          class="image_2"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882830817324807202.png"
        />
      </div>
      <div class="flex-row items-center group_5 space-x-25">
        <img
          class="image_3 image_4"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882057507488602865.png"
        />
        <span class="text_4">菜单栏</span>
      </div>
      <div class="flex-col group_6 space-y-41">
        <div class="flex-row items-center space-x-15">
          <img
            class="image_5"
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882686267627318612.png"
          />
          <span class="font_2 text_5">设计要素输入</span>
        </div>
        <span class="font_3 text_7">A1 单柱单拉结构-几何输入</span>
        <span class="self-start font_3 text_8">B1 工况输入-XX工况</span>
        <div class="flex-col items-center space-y-41">
          <img
            class="image_8"
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882830828026393201.png"
          />
          <img
            class="image_10"
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882686267553867181.png"
          />
        </div>
      </div>
      <span class="self-center font_3 text_12">设置</span>
    </div>
    <div class="flex-col flex-auto group_3">
      <div class="flex-row justify-end items-center group_4 space-x-44-reverse">
        <span class="font_1 text">zhangsan，你好</span>
        <img
          class="switch"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882686267502537415.png"
        />
      </div>
      <div class="flex-col section_2 space-y-84">
        <div class="flex-col space-y-10">
          <div class="flex-row items-start">
            <div class="flex-col group_8">
              <span class="self-start text_2 text_6">结构设计性能的总结</span>
              <div class="flex-col group_9 space-y-14">
                <div class="flex-row items-center space-x-26">
                  <img
                    class="image_6"
                    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882686267728260941.png"
                  />
                  <span class="font_4 text_2">
                    管材规格：
                    <br />
                  </span>
                </div>
                <div class="flex-row items-center space-x-23">
                  <img
                    class="image_3"
                    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882686267794579177.png"
                  />
                  <span class="font_4 text_2">
                    结构类型：
                    <br />
                  </span>
                </div>
                <div class="flex-row items-center space-x-23">
                  <img
                    class="image_9"
                    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882686272004128677.png"
                  />
                  <span class="font_4 text_2">
                    连接参数：
                    <br />
                  </span>
                </div>
              </div>
              <div class="divider"></div>
              <span class="self-start text_2 text_9">校核结果</span>
              <div class="flex-col group_12 space-y-14">
                <div class="flex-row items-center group_13 space-x-26">
                  <img
                    class="image_11"
                    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882686272253650946.png"
                  />
                  <span class="font_4 text_2">
                    管件最大应力:
                    <br />
                  </span>
                </div>
                <div class="flex-row items-center group_13 space-x-24">
                  <img
                    class="image_12"
                    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882686272437802980.png"
                  />
                  <span class="font_4 text_2">
                    最大载荷：
                    <br />
                  </span>
                </div>
                <div class="flex-row items-center group_13 space-x-25">
                  <img
                    class="image_13"
                    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882686273104965378.png"
                  />
                  <span class="font_4 text_2">
                    材料许用强度：
                    <br />
                  </span>
                </div>
              </div>
            </div>
            <div class="flex-col items-center group_11 space-y-241">
              <span class="font_1 text_2">疲劳</span>
              <span class="font_1 text_2">蠕变</span>
            </div>
            <div class="flex-col group_7 space-y-20">
              <span class="self-center font_1 text_2">管件应力</span>
              <div class="flex-col justify-start items-center image-wrapper">
                <img
                  class="image_7"
                  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882686267332681334.png"
                />
              </div>
            </div>
            <div class="flex-col items-start group_10 space-y-250">
              <span class="font_1 text_2">管件应力</span>
              <span class="font_1 text_2">连接</span>
            </div>
          </div>
          <div class="flex-row justify-between group_14">
            <div class="flex-row items-center self-center space-x-25">
              <img
                class="shrink-0 image_14"
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882686273323415618.png"
              />
              <span class="font_4 text_2">
                许用失稳载荷：
                <br />
              </span>
            </div>
            
          </div>
        </div>
        <div class="flex-col justify-start items-center self-center text-wrapper">
          <span class="text_2 text_11">导出结果</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    data() {
      return {};
    },

    methods: {},
  };
</script>

<style scoped lang="scss">
  .page {
    background-color: #617ca1;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    .section {
      padding-bottom: 3.44rem;
      border-radius: 0px;
      background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882686267392035855.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 17.25rem;
      height: 67.5rem;
      .group {
        padding: 0 3.56rem;
        .group_2 {
          padding: 1.81rem 0 0.25rem;
          .image {
            width: 3.25rem;
            height: 0.75rem;
          }
          .text_3 {
            line-height: 1.06rem;
          }
        }
        .space-y-3 {
          & > *:not(:first-child) {
            margin-top: 0.19rem;
          }
        }
        .image_2 {
          margin-right: 1rem;
          width: 9.06rem;
          height: 0.44rem;
        }
      }
      .group_5 {
        margin-top: 3.56rem;
        padding-bottom: 2.06rem;
        border-bottom: solid 0.25rem #546f9a;
        .image_4 {
          margin-left: 4.56rem;
        }
        .text_4 {
          margin-right: 5.44rem;
          color: #fcfcfc;
          font-size: 1.31rem;
          font-family: FZLTZHK;
          line-height: 1.25rem;
          letter-spacing: 0.066rem;
          text-transform: uppercase;
          opacity: 0.96;
        }
      }
      .group_6 {
        margin-top: 2.5rem;
        padding-left: 3.31rem;
        padding-right: 2.69rem;
        .space-x-15 {
          & > *:not(:first-child) {
            margin-left: 0.94rem;
          }
          .image_5 {
            width: 0.56rem;
            height: 0.56rem;
          }
          .text_5 {
            line-height: 1.06rem;
            text-transform: uppercase;
            opacity: 0.89;
          }
        }
        .text_7 {
          font-size: 0.88rem;
          text-transform: uppercase;
          opacity: 0.87;
        }
        .text_8 {
          font-size: 0.88rem;
          text-transform: uppercase;
          opacity: 0.86;
        }
      }
      .space-y-41 {
        & > *:not(:first-child) {
          margin-top: 2.56rem;
        }
        .image_8 {
          width: 11rem;
          height: 0.88rem;
        }
        .image_10 {
          width: 10.25rem;
          height: 1rem;
        }
      }
      .font_2 {
        font-size: 0.94rem;
        font-family: FZLTZHK;
        line-height: 1.13rem;
        color: #fcfcfc;
      }
      .font_3 {
        font-size: 0.94rem;
        font-family: FZLTZHK;
        line-height: 0.94rem;
        color: #fcfcfc;
      }
      .text_12 {
        margin-top: 33.56rem;
        line-height: 1rem;
        text-transform: uppercase;
        opacity: 0.48;
      }
    }
    .group_3 {
      margin-right: 0.81rem;
      .group_4 {
        padding-bottom: 2.06rem;
        .text {
          color: #fcfcfc;
          font-size: 1.06rem;
          line-height: 1.06rem;
          text-transform: uppercase;
          opacity: 0.74;
        }
        .switch {
          width: 3rem;
          height: 3.06rem;
        }
      }
      .space-x-44-reverse {
        & > *:not(:last-child) {
          margin-right: 2.75rem;
        }
      }
      .section_2 {
        margin-right: 1.5rem;
        padding: 7.94rem 9.5rem 6.31rem 10.44rem;
        background-color: #ffffff;
        border-radius: 2.5rem 2.5rem 0 0;
        .space-y-10 {
          & > *:not(:first-child) {
            margin-top: 0.63rem;
          }
          .group_8 {
            margin-top: 1.63rem;
            width: 30.69rem;
            .text_6 {
              margin-left: 0.19rem;
              color: #e0e0e0;
              font-size: 1.81rem;
              font-family: FZLTZHK;
              line-height: 1.75rem;
            }
            .group_9 {
              margin-top: 4.5rem;
              .space-x-23 {
                & > *:not(:first-child) {
                  margin-left: 1.44rem;
                }
                .image_9 {
                  width: 1.38rem;
                  height: 1.38rem;
                }
              }
            }
            .space-y-14 {
              & > *:not(:first-child) {
                margin-top: 0.88rem;
              }
            }
            .divider {
              margin-top: 2.75rem;
              background-color: #e0e0e061;
              border-radius: 0.063rem;
              height: 0.25rem;
            }
            .text_9 {
              margin-top: 4.31rem;
              color: #e0e0e0;
              font-size: 1.75rem;
              font-family: FZLTZHK;
              line-height: 1.75rem;
            }
            .group_12 {
              margin-top: 3.44rem;
              .group_13 {
                padding: 0 0.13rem;
                .image_11 {
                  width: 1.44rem;
                  height: 1.5rem;
                }
                .image_12 {
                  width: 1.44rem;
                  height: 1.31rem;
                }
                .image_13 {
                  width: 1.31rem;
                  height: 1.25rem;
                }
              }
              .space-x-24 {
                & > *:not(:first-child) {
                  margin-left: 1.5rem;
                }
              }
            }
            .space-x-26 {
              & > *:not(:first-child) {
                margin-left: 1.63rem;
              }
              .image_6 {
                width: 1.44rem;
                height: 1.44rem;
              }
            }
          }
          .group_11 {
            margin-left: 7.13rem;
            margin-top: 9.81rem;
          }
          .space-y-241 {
            & > *:not(:first-child) {
              margin-top: 15.06rem;
            }
          }
          .group_7 {
            margin-left: 3.56rem;
            width: 28.25rem;
            .image-wrapper {
              padding: 6.88rem 0 3.25rem;
              background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882686267272119912.png');
              background-size: 100% 100%;
              background-repeat: no-repeat;
              .image_7 {
                width: 23.88rem;
                height: 22.19rem;
              }
            }
          }
          .space-y-20 {
            & > *:not(:first-child) {
              margin-top: 1.25rem;
            }
          }
          .group_10 {
            margin-left: 1.44rem;
            margin-top: 9.38rem;
          }
          .space-y-250 {
            & > *:not(:first-child) {
              margin-top: 15.63rem;
            }
          }
          .group_14 {
            padding: 0 0.19rem;
            .text_10 {
              margin-right: 17.25rem;
            }
          }
          .font_4 {
            font-size: 1.38rem;
            font-family: FZLTZHK;
            line-height: 1.94rem;
            color: #e0e0e0;
          }
        }
        .text-wrapper {
          padding: 0.81rem 0 0.56rem;
          background-color: #f59e40;
          border-radius: 0.5rem;
          width: 10.94rem;
          .text_11 {
            color: #f0f0f0;
            font-size: 1.56rem;
            font-family: FZLTZHK;
            line-height: 1.69rem;
          }
        }
      }
      .space-y-84 {
        & > *:not(:first-child) {
          margin-top: 5.25rem;
        }
      }
      .font_1 {
        font-size: 1.13rem;
        font-family: FZLTZHK;
        line-height: 1.13rem;
        color: #b0aeae;
      }
    }
    .text_2 {
      text-transform: uppercase;
    }
    .image_3 {
      width: 1.5rem;
      height: 1.5rem;
    }
    .space-x-25 {
      & > *:not(:first-child) {
        margin-left: 1.56rem;
      }
      .image_14 {
        width: 1.38rem;
        height: 1.13rem;
      }
    }
  }
  .space-x-40 {
    & > *:not(:first-child) {
      margin-left: 2.5rem;
    }
  }
</style>