<template>
	<div class="flex-col page">
		<div class="flex-col justify-start items-end relative header">
			<div class="flex-row items-center section_2 space-x-48">
				<span class="self-start text" style="margin-top:1.2rem;" @click="loginout">{{userinfo.username}}，你好（点击退出）</span>
				<img class="shrink-0 image"
					src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882057507383748530.png" />
			</div>
			<!-- <div class="flex-row justify-end section_2 space-x-43-reverse pos_2" style="top:50px;">
	       <van-tabbar v-model="activeTab" style="top:4.3rem;width:98.4rem;left:21.6rem;position:absolute;">
	           <van-tabbar-item v-for="(tab, index) in tabs" :key="index" :class="activeTab === index ? 'active' : ''">
	           <input type="checkbox" :value="index"> {{ tab }}
	           </van-tabbar-item>
	         </van-tabbar>
	  </div> -->

			<div class="flex-col items-start section space-y-3 pos">
				<img class="image_2"
					src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882057507649290089.png" />
				<img class="image_3"
					src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/638b18425a7e3f03100a62af/649947d8726ab50011f379b8/50306348c9b05809c4d214e0868b960e.png" />
			</div>
		</div>
		<div class="flex-row flex-auto group" style="overflow-x:hidden">
			<div class="flex-col shrink-0 section_3">
				<div class="flex-row items-center group_3 space-x-24">
					<img class="shrink-0 image_4"
						src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882057507488602865.png" />
					<span class="text_2 text_3"  :style='lang === "en"?"font-size:1.2rem;":""'>{{ lang === 'en' ? 'Design workflow' : '设计要素流程' }}</span>
				</div>

				<div class="flex-col group_5 space-y-46">
					<div class="flex-col justify-start items-center text-wrapper_2">
						<div class="section_6"></div>
						<span class="font_2 text_6" style="font-size:1rem;" @click="next(1)">{{ lang === 'en' ? 'A1 Geom' : 'A1 结构-几何输入' }}</span>
					</div>
					<div class="flex-col justify-start items-center relative group_4">
						<div class="section_6" style="background-color: rgba(252, 252, 252, 0.568627451);border:none">
						</div>
						<span class="font_2 text_2 pos_4" @click="next(2)" style="font-size:1rem;">{{ lang === 'en' ? 'B1 Loads' : 'B1 工况输入' }}</span>
					</div>
					<div class="flex-col justify-start items-center relative group_4">
						<div class="section_6"></div>
						<span class="font_2 text_2 pos_44" style="font-size:1rem;" @click="next(6)">{{ lang === 'en' ? 'B2 Comp&Results' : 'B2 选项计算及结果' }}</span>
					</div>
					<div class="flex-col justify-start items-center image-wrapper">
						<div class="section_6"></div>
						<span class="font_2 text_2 pos_4" @click="next(3)" style="font-size:1rem;">{{ lang === 'en' ? 'C1 Visualization' : 'C1 结构设计性能的可视化' }}</span>
					</div>
					<div class="flex-col justify-start items-center button">
						<div class="section_6"></div>
						<span class="font_2 text_2 pos_4" @click="next(4)" style="font-size:1rem;">{{ lang === 'en' ? 'C2 Summary' : 'C2 结构设计性能的总结' }}</span>
					</div>
					<!-- <div class="flex-col justify-start items-center button_3">
            <span class="font_2 text_9 text_13" style="font-size:1.1rem;">完成流程</span>
          </div> -->
				</div>

				<span class="self-center text_16"></span>
			</div>
			<div class="flex-col justify-start flex-auto relative group_c_2">

				<div class="flex-col section_c_3 space-y-82">
					<div class="flex-col space-y-8">
						<div class="flex-row items-start">
							<div class="flex-row group_4">
								<span class="self-start text_2 text_7">{{ lang === 'en' ? 'Results' : '管件选项尺寸' }}</span>
								<div class="divider"></div>
								<div class="flex-col group_9 space-y-14">
									<div class="flex-row items-center group_10 space-x-23">
										<div class="flex-row items-center self-center text-wrapper_4"
											style="align-items: center;align-self: center;margin-left:-15rem;margin-top:12rem;">
											<span class="text_2 text_15" @click="daochu"
												style="margin-left:1rem;">{{ lang === 'en' ? 'Calculate' : '提交选项计算' }}</span>
										</div>
									</div>
								</div>
                            
                          <div class="flex-col group_9 space-y-14">
                          		<span class="self-start text_2 text_11">{{ lang === 'en' ? '1B' : '单柱' }}</span>
                          		<div class="flex-row items-center group_10 space-x-23">
                          			<img class="image_7"
                          				src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882686272253650946.png" />
                          			<span class="font_3 text_2">
                          				{{ lang === 'en' ? 'Tension bar' : '支柱尺寸' }}：mm
                          				<br />
                          			</span>
                          		</div>
                          		<div class="flex-row items-center group_10 space-x-23">
                          		</div>
                          
                          		<span class="self-start text_2 text_11" style="margin-top:2rem;">{{ lang === 'en' ? '1B-2T' : '单柱双拉' }}</span>
                          		<div class="flex-row items-center group_10 space-x-23">
                          			<img class="image_7"
                          				src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882686272253650946.png" />
                          			<span class="font_3 text_2">
                          				{{ lang === 'en' ? 'Tension bar' : '支柱尺寸' }}：{{checkResults1?checkResults1.mand_ext_dia:''}}mm
                          				<br />
                          			</span>
                          		</div>
                          		<div class="flex-row items-center group_10 space-x-23">
                          			<img class="image_7"
                          				src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882686272253650946.png" />
                          			<span class="font_3 text_2">
                          				{{ lang === 'en' ? 'Cross bar' : '拉杆尺寸' }}：{{checkResults1?checkResults1.rod_dia:''}}mm
                          				<br />
                          			</span>
                          		</div>
                          
                          
                          		<span class="self-start text_2 text_11" style="margin-top:2rem;">{{ lang === 'en' ? '1B-3T' : '单柱三拉' }}</span>
                          		<div class="flex-row items-center group_10 space-x-23">
                          			<img class="image_7"
                          				src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882686272253650946.png" />
                          			<span class="font_3 text_2">
                          				{{ lang === 'en' ? 'Tension bar' : '支柱尺寸' }}：{{checkResults2?checkResults2.mand_int_dia:''}}mm-{{checkResults2?checkResults2.mand_ext_dia:''}}mm
                          				<br />
                          			</span>
                          		</div>
                          		<div class="flex-row items-center group_10 space-x-23">
                          			<img class="image_7"
                          				src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882686272253650946.png" />
                          			<span class="font_3 text_2">
                          				{{ lang === 'en' ? 'Cross bar' : '拉杆尺寸' }}：{{checkResults2?checkResults2.rod_dia:''}}mm
                          				<br />
                          			</span>
                          		</div>
                          
                          	</div>
                          
                          
                          
                          
                          	<div class="flex-col group_9 space-y-14" style="margin-left: 20rem;">
                          		<span class="self-start text_2 text_11">{{ lang === 'en' ? '2B-1T' : '双柱单拉' }}</span>
                          		<div class="flex-row items-center group_10 space-x-23">
                          			<img class="image_7"
                          				src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882686272253650946.png" />
                          			<span class="font_3 text_2">
                          				{{ lang === 'en' ? 'Tension bar' : '支柱尺寸' }}：{{checkResults3?checkResults3.mand_ext_dia:''}}mm
                          				<br />
                          			</span>
                          		</div>
                          		<div class="flex-row items-center group_10 space-x-23">
                          			<img class="image_7"
                          				src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882686272253650946.png" />
                          			<span class="font_3 text_2">
                          				{{ lang === 'en' ? 'Cross bar' : '拉杆尺寸' }}：{{checkResults3?checkResults3.rod_dia:''}}mm
                          				<br />
                          			</span>
                          		</div>
                          
                          
                          		<span class="self-start text_2 text_11" style="margin-top:2rem;">{{ lang === 'en' ? '2B-2T' : '双柱双拉' }}</span>
                          		<div class="flex-row items-center group_10 space-x-23">
                          			<img class="image_7"
                          				src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882686272253650946.png" />
                          			<span class="font_3 text_2">
                          				{{ lang === 'en' ? 'Tension bar' : '支柱尺寸' }}：{{checkResults4?checkResults4.mand_int_dia:''}}--{{checkResults4?checkResults4.mand_ext_dia:''}}mm
                          				<br />
                          			</span>
                          		</div>
                          		<div class="flex-row items-center group_10 space-x-23">
                          			<img class="image_7"
                          				src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882686272253650946.png" />
                          			<span class="font_3 text_2">
                          				{{ lang === 'en' ? 'Cross bar' : '拉杆尺寸' }}：{{checkResults4?checkResults4.rod_dia:''}}mm
                          				<br />
                          			</span>
                          		</div>
                          
                          	</div>
                          </div>
							<div class="flex-col group_4">
								<!-- <div id="radar" ref="radar" style="width: 600px;height:600px;"></div> -->
							</div>
						</div>
						<div class="flex-row justify-between">

						</div>
					</div>
					<div class="flex-row justify-center items-center self-start">
					</div>
				</div>


			</div>

		</div>
	</div>
	</div>
</template>

<script>
	import * as XLSX from 'xlsx';
	import * as echarts from 'echarts'
	import axios from 'axios';
	import {
		saveAs
	} from 'file-saver'
    import { Calculations11 } from './Calculations11.js';
	import { Calculations13 } from './Calculations13.js';
	import { Calculations21 } from './Calculations21.js';
	import { Calculations22 } from './Calculations22.js';
	export default {
		components: {},
		mounted() {
			if(JSON.parse(localStorage.getItem(`userinfo`)))
			  {
				  this.userinfo=JSON.parse(localStorage.getItem(`userinfo`));
				  console.info(this.userinfo);
			  }
			  else
			  {
				  this.$router.push('/');
			  }
			let myChart = echarts.init(this.$refs.radar);
			// this.szsllgcc=this.checkResults.rod_dia;
			// localStorage.setItem("szsllgcc",this.checkResults.rod_dia);
			// this.szslzzcc=this.checkResults.mand_int_dia+"-"+this.checkResults.mand_ext_dia;
			// localStorage.setItem("szslzzcc",this.checkResults.mand_int_dia+"-"+this.checkResults.mand_ext_dia);
			this.szsllgcc = localStorage.getItem("szsllgcc");
			this.szslzzcc = localStorage.getItem("szslzzcc");
			this.checkedItems=localStorage.getItem(`checkedItems`).split(",");
			this.R = parseFloat(localStorage.getItem("R值"));
			myChart.setOption(option);
		},
		data() {
			return {
				lang: localStorage.getItem('lang') || 'cn',
				R: 0.1,
			    userinfo: null,
				checkedItems:null,
				xxjg: null,
				init: {},
				checkResults1:{},
				checkResults2:{},
				checkResults3:{},
				checkResults4:{},
				checkResults: {},
				Resultsdata:[],
				rsValues: [], // 存储每个结果的RS值
				pro_len: null,
				clamp_ang: null,
				elev_ang: null,
				zjb: 0,
				zq: 0,
				pl1: [],
				pl2: [],
				op_param:{},
				caselist:[],
				op_params: Array(12).fill(null), // 初始化12个参数为null
				tableData: [{
						name: '管材规格',
						type: 1,
						jg: '单'
					},
					{
						name: '结构类型',
						type: 2,
						jg: '单'
					},
					{
						name: '连接参数',
						type: 3,
						jg: '双'
					},
				],
				szslzzcc: localStorage.getItem("szslzzcc"),
				szsllgcc: localStorage.getItem("szsllgcc"),
				dzslzzcc: null,
				dzsllgcc: null,
				showdz: 0,
				showsz: 1,
				eshow1: 0,
				eshow2: 0,
				eshow3: 0,
				eshow4: 0,
				eshow5: 0,
				show1: 1,
				show2: 1,
				show3: 1,
				show4: 1,
				show5: 1,
			};
		},

		methods: {
			async fatigue(stress1, stress2, n) {
				try {
					const response = await axios.post('http://101.91.220.18/api/index/f', {
						stress1,
						stress2,
						n
					});
					return response.data;
				} catch (error) {
					console.error('请求错误:', error);
					throw error; // 抛出错误，以便可以在调用函数时捕获
				}
			},
			exportExcel() {
				const wb = XLSX.utils.book_new()
				const ws = XLSX.utils.json_to_sheet(this.tableData)
				XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
				const wbout = XLSX.write(wb, {
					bookType: 'xlsx',
					bookSST: true,
					type: 'array'
				})
				try {
					saveAs(new Blob([wbout], {
						type: 'application/octet-stream'
					}), 'output.xlsx')
				} catch (e) {
					if (typeof console !== 'undefined') console.log(e, wbout)
				}
			},
			propertyBending(Glueratio, ddi, ddo, Mbend) {
				// Glueratio=1;
				// ddi=130;
				// ddo=147;
				// Mbend=1.3251e3;

				// 本函数用于校核不同胶状比下抗弯的强度，仅校核支柱
				// 输入胶装比，支柱内外直径，弯矩载荷，然后跟计算的结构强度比，输出使用率
				let SStrength;
				// 计算许用结构强度
				if (Glueratio >= 0.2 && Glueratio < 0.99) {
					SStrength = (506.42 * Glueratio + 7.6517) / 1.28;
				} else if (Glueratio >= 0.99 && Glueratio <= 1.5) {
					SStrength = 460 / 1.156;
				}
				console.log("SStrength----------------------")
				console.log(SStrength)
				// 计算设计结构强度
				const Iz = Math.PI / 64 * (Math.pow(ddo, 4) - Math.pow(ddi, 4));
				console.log("Iz",Iz)
				const Stest = Mbend * 1000 / Iz * ddo / 2.0; // Nm to Nmm
                 console.log("Stest",Stest)
				console.log("Mbend",Mbend)
				// 计算使用率
				const RSbending = Stest / SStrength;
				return RSbending;
			},
			propertyCompression(Glueratio, ddi, ddo, Cload) {
				// 计算许用结构强度
				// 计算许用结构强度
				const SStrength = 428.72 * Glueratio + 162.27;

				// 计算设计结构强度
				const Area = Math.PI / 4 * (Math.pow(ddo, 2) - Math.pow(ddi, 2));
				const Stest = Cload / Area * 1.41;

				// 计算使用率
				const RScom = Stest / SStrength;

				return RScom;
			},
			propertyCreep(sigma1) {
				const Efail = 5.5e-3;
				const Sfail = 45.3;
				const ModL = 15154.9;
				const ModA = 4606.4; // 30Mpa 后模量
				const Time = 30 * 365 * 24.0 * 3600;
				if (sigma1 > 45 || sigma1 < 0) {
					throw new Error('输入的应力幅值超出静载强度或为负');
				}
				const Alogt = 0.1274 * (sigma1 - 22.22) + 0.2051;
				const Logtime = Math.log10(Time);
				let l = 0;
				const CreepCom = 0.000338 * Math.pow(Logtime + Alogt, 3) - 0.00326 * Math.pow(Logtime + Alogt, 2) + 0.012 *
					(Logtime + Alogt) - 0.0144;
				const CreepMod = 1.0 / CreepCom * 1000.0;
				// 应变计算
				const Eten = 6.95e-8 * Math.pow(sigma1, 3) - 3.06e-6 * Math.pow(sigma1, 2) + 1.01e-4 * sigma1 - 4.31e-5;
				const Ecreep = sigma1 / CreepMod;
				const Etot = Math.max(Eten + Ecreep, Eten);
				const Modtemp = sigma1 / Eten;
				const Eaftercp = sigma1 / Etot;
				let I = Etot > Efail ? 0 : 1; // 0 破坏, 1 安全
				let RS1 = Math.min(sigma1 / Etot / Modtemp * 100, 100);
				let ML;
				if (I === 1) {
					ML = 100 - RS1;
				} else {
					ML = 100.0;
				}
				let RS2 = ModA * Efail + (30.0 - ModA * 30.0 / Eaftercp);
				RS2 = Math.min(RS2 / Sfail * 100, 100);
				let RS;
				if (I === 1) {
					RS = sigma1 > 30 ? RS1 / 100 : RS2 / 100;
				} else {
					RS = 0;
				}
				console.log("RS", RS);
				return RS;
			},
			loginout(){
						  localStorage.removeItem("userinfo");
			   this.$router.push(`/`);
			},
			checkResult(item) {
				let minTotalVolumeRecord = null;
				let minVolumeForAllConditions = null;
				console.log("最终选型输入参数", this.init[item]);
				this.init[item].forEach(item => {
					// 将属性转换为数字，以便比较
					const rs = Number(item.rs);
					const RScom = Number(item.maxRScom);
					const RSbending = Number(item.maxRSbending);
					const pl = Number(item.pl);
					const total_volume = Number(item.total_volume);

					// 检查属性是否都小于 1
					const allLessThanOne = rs < 1 && RScom < 1 && RSbending < 1 && pl < 1 && item.check_gka && item
						.check_gkb && item.check_gkc && item.check_gkd;

					// 更新最小 total_volume 记录
					if (minTotalVolumeRecord === null || total_volume < minTotalVolumeRecord.total_volume) {
						minTotalVolumeRecord = item;
					}
					console.log("最终选型11", minTotalVolumeRecord);
					// 更新满足条件且 total_volume 最小的记录
					if (allLessThanOne) {
						if (minVolumeForAllConditions === null || total_volume < minVolumeForAllConditions
							.total_volume) {
							minVolumeForAllConditions = item;
						}
					}
				});

				// 如果存在满足所有条件的记录，则使用该记录；否则使用 total_volume 最小的记录
				var result = minVolumeForAllConditions || minTotalVolumeRecord;
				return result
			},
		processResults(data, item) {
			 console.log("this.init:",this.init);
		    this.init[item].forEach((result, initindex) => {
		        console.log("Initial result:", result);
		        let maxRScom = 0;
		        let maxRSbending = 0;
		        result.mand_ext_dia = parseFloat(result.mand_ext_dia);
		         this.caselist.forEach((caseItem, index) => {
		                    const loadParams = this.op_param[caseItem];
		                    if (!loadParams || loadParams.length < 3) {
		                        console.error(`Load parameters for ${caseItem} are invalid:`, loadParams);
		                        return;
		                    }
		            let calculationResults;
		            switch(item) {
		                case "1":
						   //extension_length, angle_bar, diameters, load
						     // calculationResults = Calculations11.calculateFromAngle(1875, 30.68, [34, 133, 0], [-1.4,15,17]);
		                    calculationResults = Calculations11.calculateFromAngle(result.pro_len, result.elev_ang, [result.rod_dia, result.mand_ext_dia, result.mand_int_dia], loadParams);
		                    break;
		                case "2":
		                    calculationResults = Calculations13.calculateFromAngles(result.pro_len, result.clamp_ang, result.elev_ang, [result.rod_dia, result.mand_ext_dia, result.mand_int_dia], loadParams);
		                    break;
		                case "3":
						//calculationResults =  {'GFRP_maxbending':6636.3, 'GFRP_maxforce':145420,'GFRP_maxstress':86.11};
		                calculationResults = Calculations21.CalculateExcelfromAngle2b1t_for(result.pro_len, result.clamp_ang, result.elev_ang, [result.rod_dia, result.mand_ext_dia, result.mand_int_dia], loadParams);
		                    break;
		                case "4":
		                default:
		                    calculationResults = Calculations22.calculateExcelfromAngle(result.pro_len, result.clamp_ang, result.elev_ang, [result.rod_dia, result.mand_ext_dia, result.mand_int_dia], loadParams);
		                    break;
		            }
		            console.log(calculationResults);
		            const { GFRP_maxbending, GFRP_maxforce } = calculationResults;
		            const RSbending = this.propertyBending(this.jzb, result.mand_int_dia, result.mand_ext_dia, GFRP_maxbending);
		            const RScom = this.propertyCompression(this.jzb, result.mand_int_dia, result.mand_ext_dia, GFRP_maxforce);
		            
		            console.log("RSbending:", RSbending);
		            maxRScom = Math.max(maxRScom, RScom);
		            maxRSbending = Math.max(maxRSbending, RSbending);
		        });
		        const minRodMaxS22 = Math.max(
		            ...this.caselist.map(caseItem => result[caseItem] && result[caseItem].rod_max_s22 || 0)
		        );
		       
		        this.init[item][initindex].rs = this.propertyCreep(minRodMaxS22);
		
		        // Update the line below to use the correct calculations class based on `item`
		        let result1;
		        switch(item) {
		            case "1":
		                result1 = Calculations11.calculateFromAngle(result.pro_len, result.elev_ang, [result.rod_dia, result.mand_ext_dia, result.mand_int_dia], this.pl1);
		                break;
		            case "2":
		                result1 = Calculations13.calculateFromAngles(result.pro_len, result.clamp_ang, result.elev_ang, [result.rod_dia, result.mand_ext_dia, result.mand_int_dia], this.pl1);
		                break;
		            case "3":
					 // result1  =  {'GFRP_maxbending':6636.3, 'GFRP_maxforce':145420,'GFRP_maxstress':86.11};
		               result1 = Calculations21.CalculateExcelfromAngle2b1t_for(result.pro_len, result.clamp_ang, result.elev_ang, [result.rod_dia, result.mand_ext_dia, result.mand_int_dia], this.pl1);
		                break;
		            case "4":
		            default:
		                result1 = Calculations22.calculateExcelfromAngle(result.pro_len, result.clamp_ang, result.elev_ang, [result.rod_dia, result.mand_ext_dia, result.mand_int_dia], this.pl1);
		                break;
		        }
		
		        if (typeof result1 !== 'object' || result1.GFRP_maxstress === undefined) {
		            throw new Error('calculateExcelfromAngle did not return expected results');
		        }
		
		        const stress1 = result1.GFRP_maxstress;
		        const stress2 = stress1 * 0.1;
		        console.log("stress1", stress1);
		        console.log("stress2", stress2);
		
		        this.fatigue(stress1, stress2, this.zq)
		            .then(responseData => {
		                console.log('Response data:', responseData);
		                const Sr = responseData.data * 1;
		                console.log('Response data Sr:', Sr);
		
		                const pl = 1 - Sr / 729;
		                this.init[item][initindex].Sr = Sr;
		                this.init[item][initindex].pl = pl;
		                this.init[item][initindex].maxRScom = maxRScom;
		                this.init[item][initindex].maxRSbending = maxRSbending;
		
		                if (initindex === 4) {
		                    this.checkResults[item] = this.checkResult(item);
							 this.Resultsdata[item] = this.checkResult(item);
		                    console.log('Final selection results', this.checkResults);
		                }
		
		                this.rxjg = result;
		                localStorage.setItem('resultitem' + item, JSON.stringify(result));
		            })
		            .catch(error => {
		                console.error('Error processing request:', error);
		            });
		    });
		    console.log('Processed results:', this.init[item])
		},
		// processResults(data, item) {
		//     this.init[item].forEach((result, initindex) => {
		//         console.log("Initial result:", result);
		//         let maxRScom = 0;
		//         let maxRSbending = 0;
		
		//         result.mand_ext_dia = parseFloat(result.mand_ext_dia);
		
		//         // 使用 caselist 数组替代原始硬编码的工况数组
		//         this.caselist.forEach((caseItem) => {
		//             // 调整 loadParams 取值为 this.checkResults[caseItem]
		//             const loadParams = this.op_param[caseItem];
		            
		//             // 确保 loadParams 是数组或有效对象
		//             if (!Array.isArray(loadParams) || loadParams.length < 3) {
		//                 console.error('Invalid load parameters for', caseItem);
		//                 return; // 跳过当前循环
		//             }
		
		//             let calculationResults;
		//             switch(item) {
		//                 case "1":
		//                     calculationResults = Calculations11.calculateFromAngle(result.pro_len, result.elev_ang, [result.rod_dia, result.mand_ext_dia, result.mand_int_dia], loadParams);
		//                     break;
		//                 case "2":
		//                     calculationResults = Calculations13.calculateFromAngles(result.pro_len, result.clamp_ang, result.elev_ang, [result.rod_dia, result.mand_ext_dia, result.mand_int_dia], loadParams);
		//                     break;
		//                 case "3":
		//                     calculationResults = Calculations21.CalculateExcelfromAngle2b1t_for(result.pro_len, result.clamp_ang, result.elev_ang, [result.rod_dia, result.mand_ext_dia, result.mand_int_dia], loadParams);
		//                     break;
		//                 case "4":
		//                 default:
		//                     calculationResults = Calculations22.calculateExcelfromAngle(result.pro_len, result.clamp_ang, result.elev_ang, [result.rod_dia, result.mand_ext_dia, result.mand_int_dia], loadParams);
		//                     break;
		//             }
		//             console.log(calculationResults);
		//             const { GFRP_maxbending, GFRP_maxforce } = calculationResults;
		//             const RSbending = this.propertyBending(this.jzb, result.mand_int_dia, result.mand_ext_dia, GFRP_maxbending);
		//             const RScom = this.propertyCompression(this.jzb, result.mand_int_dia, result.mand_ext_dia, GFRP_maxforce);
		            
		//             console.log("RSbending:", RSbending);
		//             maxRScom = Math.max(maxRScom, RScom);
		//             maxRSbending = Math.max(maxRSbending, RSbending);
		//         });
				
		//             const minRodMaxS22 = this.caselist.reduce((max, caseItem) => {
		//                      return Math.max(max, result[caseItem] && result[caseItem].rod_max_s22 || 0);
		//                  }, 0);
		         
		//                  this.init[initindex].rs = this.propertyCreep(minRodMaxS22);
		         
		//                  // 更新 CalculateExcelfromAngle 的调用
		//                  const result1 = this.calculateExcelfromAngle(
		//                      result.pro_len, 
		//                      result.clamp_ang, 
		//                      result.elev_ang,
		//                      [result.rod_dia, result.mand_ext_dia, result.mand_int_dia], 
		//                      this.pl1
		//                  );
		         
		//                  if (typeof result1 !== 'object' || result1.GFRP_maxstress === undefined) {
		//                      throw new Error('calculateExcelfromAngle did not return expected results');
		//                  }
		         
		//                  const stress1 = result1.GFRP_maxstress;
		//                  const stress2 = stress1 * 0.1;
		//                  console.log("stress1", stress1);
		//                  console.log("stress2", stress2);
		         
		//                  this.fatigue(stress1, stress2, this.zq)
		//                      .then(responseData => {
		//                          console.log('响应数据:', responseData);
		//                          const Sr = responseData.data * 1;
		//                          console.log('响应数据sr:', Sr);
		//                          const pl = 1 - Sr / 729;
		//                          this.init[initindex].Sr = Sr;
		//                          this.init[initindex].pl = pl;
		//                          this.init[initindex].maxRScom = maxRScom;
		//                          this.init[initindex].maxRSbending = maxRSbending;
		//                          if (initindex === 4) {
		//                                this.checkResults = this.checkResult();
		//                                   this.Resultsdata = this.compileResultsData(this.init);
		//                                   console.log('最终选型结果', this.checkResults);
		//                                   console.log('Compiled Results Data', this.Resultsdata);
		                              
		//                                   // 存储最终结果到本地存储，以便未来参考或恢复会话
		//                                   localStorage.setItem('finalResults', JSON.stringify(this.Resultsdata));
		                              
		//                                   // 可能的UI更新或状态反馈
		//                                   this.updateUIWithResults(this.Resultsdata);
		                              
		//                                   // 发送数据到服务器或外部API
		//                                   this.sendResultsToServer(this.Resultsdata)
		//                                       .then(serverResponse => {
		//                                           console.log('服务器响应:', serverResponse);
		//                                           this.handleServerResponse(serverResponse);
		//                                       })
		//                                       .catch(error => {
		//                                           console.error('向服务器发送数据时出错:', error);
		//                                       });
		//                               }
		                              
		//                               // 继续处理每次迭代的通用部分
		//                               this.rxjg = result;
		//                               localStorage.setItem('resultitem' + item, JSON.stringify(result));
		//         // 后续处理与逻辑更新
		//         // ...
		//     });
		//     console.log('Processed results:', this.init[item])
		// 	}
		// },
        
			 async loadDataAndPost(item) {
				console.log("现在处理的结构id是",item);
				let pointAngleResult=null;
				let apiurl='http://101.91.220.18/api/index11/index';
				switch (item) {
				  case "0":
				    break;
				  case "1":
				    apiurl='http://101.91.220.18/api/index11/index';
				    pointAngleResult = JSON.parse(localStorage.getItem('pointAngleResult11'));
					console.log(pointAngleResult);
				    break;
				  case "2":
				    apiurl='http://101.91.220.18/api/index13/index';
				    pointAngleResult = JSON.parse(localStorage.getItem('pointAngleResult13'));
				    break;
				  case "3":
				    apiurl='http://101.91.220.18/api/index21/index';
				    pointAngleResult = JSON.parse(localStorage.getItem('pointAngleResult21'));
				    break;	
				  case "4":
				     apiurl='http://101.91.220.18/api/index/index';
					pointAngleResult = JSON.parse(localStorage.getItem('pointAngleResult22'));
					break;
				  // 可以添加更多的case分支来处理不同的情况
				  default:
				  console.log('default',pointAngleResult);
				    // 默认情况下的处理，或者当item值不匹配任何case时的错误处理
				}
				console.log('pointAngleResult',pointAngleResult);
				if (pointAngleResult) {
					console.log('pointAngleResult768',pointAngleResult);
					this.pro_len = pointAngleResult.length;
					this.clamp_ang = pointAngleResult.alfa;
					this.elev_ang = pointAngleResult.beta;
				}
				else
				{
					return;
				}

				// 读取并设置其他参数
				this.zq = JSON.parse(localStorage.getItem('疲劳周期'));
				this.jzb = JSON.parse(localStorage.getItem('胶装比'));
				this.pl1 = JSON.parse(localStorage.getItem('疲劳工况max'));
				this.pl2 = JSON.parse(localStorage.getItem('疲劳工况min'));
				 const conditionPrefix = '工况';
				 const casePrefix = 'case';
				 var caseName = '';
				for (let i = 1; i <= 10; i++) {
				  const conditionName = `${conditionPrefix}${i}`;
				  const storedData = localStorage.getItem(conditionName);
				
				  if (storedData) {
				    const values = JSON.parse(storedData);
				
				    // 检查分割后的数组长度是否为3且每个元素都不为空
				    if (values.length === 3 && values.every(value => value !== null && value !== undefined && value.trim() !== '')) {
				      caseName = `${casePrefix}${i}`;
				      this.op_param[caseName] = values;
				      this.caselist.push(caseName);
				    }

				  }
				}
				
			    console.log(this.op_param)
				console.log("caselist",this.caselist)
				// const paramMappings = ['工况1', '工况2', '工况3','工况4','工况5','工况6','工况7','工况8','工况9','工况10'];
				// paramMappings.forEach((name, index) => {
				// 	const values = JSON.parse(localStorage.getItem(name));
				// 	if (values && values.length === 3) {
				// 		this.op_params.splice(index * 3, 3, ...values);
				// 	} 
				// 	else {
				// 		// 如果values为空或长度小于3，将对应位置的op_params值设置为0
				// 		this.op_params.splice(index * 3, 3, null, null, null);
				// 	  }
				// });
				localStorage.getItem('lang') === "en" ? this.$message.info('Waiting for the server to return the preliminary selection results') : this.$message.info('正在等待服务器返回初选型结果');
                console.log('pointAngleResult787',this.op_params);
				console.log((this.pro_len||this.pro_len==0));
				console.log((this.clamp_ang||this.clamp_ang==0));
				console.log((this.elev_ang||this.clamp_ang==0));
				if ((this.pro_len !== null && this.pro_len !== undefined) &&(this.clamp_ang !== null && this.clamp_ang !== undefined) &&(this.elev_ang !== null && this.elev_ang !== undefined) &&this.caselist.length >= 1) {
					console.log('789');
					const data = {
						pro_len: this.pro_len,
						clamp_ang: this.clamp_ang,
						elev_ang: this.elev_ang,
						op_param:this.op_param,
						caselist:this.caselist
					};
					 try {
					      // 使用await等待axios请求完成
					      const response = await axios.post(apiurl, data);
						  console.log("api返回数据",response.data.data);
					      this.init[item] = response.data.data;
						  localStorage.getItem('lang') === "en" ? this.$message.info('The preliminary screening results have been successfully returned!') : this.$message.info('初筛结果返回成功！！');
					      this.processResults(response.data.data,item);
						  		if (!this.checkResults[item]) 
								    {
									localStorage.getItem('lang') === "en" ? this.$message.info('Calculating verification, please wait!!') : this.$message.info('正在计算验证，请稍候！！');
						  			this.checkResults[item] = response.data.data[0];
									this.Resultsdata[item] = response.data.data[0];
									console.log("最终选型结果1", this.checkResults[item]);
									console.log("checkResults", this.checkResults);
									localStorage.setItem("checkResults", JSON.stringify(this.checkResults))
									switch(item) {
									    case "1":
									        this.checkResults1 = response.data.data[0];
											localStorage.setItem("checkResults1", JSON.stringify(this.checkResults1))
									        break;
									    case "2":
									         this.checkResults2 = response.data.data[0];
											 localStorage.setItem("checkResults2", JSON.stringify(this.checkResults2))
											break;
									    case "3":
									         this.checkResults3 = response.data.data[0];
											 localStorage.setItem("checkResults3", JSON.stringify(this.checkResults3))
											break;
									    case "4":
											this.checkResults4 = response.data.data[0];
											localStorage.setItem("checkResults4", JSON.stringify(this.checkResults4))
											break;
									    default:
									        break;
									}

									}
									else
									{
									this.checkResults[item] = response.data.data[0];
									this.Resultsdata[item] = response.data.data[0];
									switch(item) {
									    case "1":
									        this.checkResults1 = response.data.data[0];
											localStorage.setItem("checkResults1", JSON.stringify(this.checkResults1))
									        break;
									    case "2":
									         this.checkResults2 = response.data.data[0];
											 localStorage.setItem("checkResults2", JSON.stringify(this.checkResults2))
											break;
									    case "3":
									         this.checkResults3 = response.data.data[0];
											 localStorage.setItem("checkResults3", JSON.stringify(this.checkResults3))
											break;
									    case "4":
											this.checkResults4 = response.data.data[0];
											localStorage.setItem("checkResults4", JSON.stringify(this.checkResults4))
											break;
									    default:
									        break;
									}
									};
					      // 后续处理保持不变
					      // 注意: setTimeout内的逻辑不会"等待"，但外部逻辑可以同步执行
					
					    } catch (error) {
					      // 如果请求失败，这里捕获异常
						  localStorage.getItem('lang') === "en" ? this.$message.info('The structure has not been selected to a reasonable size. It is recommended to use offline design verification program!') : this.$message.info('此结构未选到合理尺寸，建议采用离线设计校核程序！');
					      console.error('API 请求失败', error);
					      // 可以在这里返回，或处理错误
					    }
					// axios.post('http://101.91.220.18/api/index/index', data)
						// .then(response => {
						// 	console.log(response.data);
						// 	this.init[item] = response.data.data;
						// 	console.log(response.data);

						// 	setTimeout(() => {
						// 		this.$message.info('正在提交几何数据');
						// 	}, 500);
						// 	setTimeout(() => {
						// 		this.$message.info('正在提交工况数据');
						// 	}, 1000);
						// 	setTimeout(() => {
						// 		this.$message.info('正在等待服务器返回初选型结果');
						// 	}, 1500);
						// 	if (response.data.data == null) {
						// 		this.$message.info('没有筛选到合适的选型，正在自动重新提交！');
						// 		return
						// 	}
						// 	this.processResults(response.data.data);
						// 	setTimeout(() => {
						// 		this.$message.info('正在验证初筛结果！');
						// 	}, 3500);

						// 	setTimeout(() => {
						// 		this.$message.info('返回结果！！');
						// 		if (!this.checkResults) {
						// 			this.$message.info('筛选计算出现错误，请检查输入参数！！');
						// 			console.log("最终选型结果", this.checkResults);
						// 			this.checkResults = response.data.data[0];
						// 			localStorage.setItem("checkResults", JSON.stringify(this.checkResults));
						// 			if (this.showdz) {
						// 				this.dzsllgcc = this.checkResults.rod_dia;

						// 				this.dzslzzcc = this.checkResults.mand_int_dia + "-" + this
						// 					.checkResults.mand_ext_dia;
						// 			}
						// 			if (this.showsz) {
						// 				this.szsllgcc = this.checkResults.rod_dia;
						// 				localStorage.setItem("szsllgcc", this.checkResults.rod_dia);
						// 				this.szslzzcc = this.checkResults.mand_int_dia + "-" + this
						// 					.checkResults.mand_ext_dia;
						// 				localStorage.setItem("szslzzcc", this.checkResults.mand_int_dia + "-" +
						// 					this.checkResults.mand_ext_dia);
						// 			}
						// 		} else {
						// 			console.log("最终选型结果", this.checkResults);
						// 			localStorage.setItem("checkResults", JSON.stringify(this.checkResults));
						// 			if (this.showdz) {
						// 				this.dzsllgcc = this.checkResults.rod_dia;

						// 				this.dzslzzcc = this.checkResults.mand_int_dia + "-" + this
						// 					.checkResults.mand_ext_dia;
						// 			}
						// 			if (this.showsz) {
						// 				this.szsllgcc = this.checkResults.rod_dia;
						// 				localStorage.setItem("szsllgcc", this.checkResults.rod_dia);
						// 				this.szslzzcc = this.checkResults.mand_int_dia + "-" + this
						// 					.checkResults.mand_ext_dia;
						// 				localStorage.setItem("szslzzcc", this.checkResults.mand_int_dia + "-" +
						// 					this.checkResults.mand_ext_dia);
						// 			}
						// 		}

						// 		// if(checkedItems.length==1&&checkedItems[0]==2)
						// 		//    {
						// 		// 	  this.dzsllgcc="24";
						// 		// 	  this.dzslzzcc="130";
						// 		//    }else
						// 		// {
						// 		// this.szsllgcc="24";
						// 		// this.szslzzcc="130-147";
						// 		// }

						// 	}, 4000);
						// 	console.log(response.data);
						// })
						// .catch(error => {
						// 	console.error('API 请求失败', error);
						// 	return
						// });
				} else {}
			},
			postData() {

			},
			next(productNumber) {
				if (![1, 2, 3, 4, 6].includes(productNumber)) {
					throw new Error('参数必须是1、2、3或4');
				}
				this.$router.push(`/chanpin${productNumber}`);
			},
			daochu() {
			    const langIsEn = localStorage.getItem('lang') === "en";
			    
			    // 显示计算消息
			    langIsEn ? this.$message.info('Calculating, please wait') : this.$message.info('正在计算请稍候');
			
			    // 定义键的数组，根据语言选择
			    const keys = langIsEn ? ['case 1', 'case 2', 'case 3', 'case 4', 'Binding Ratio', 'Safety Factor'] : ['工况1', '工况2', '工况3', '工况4', '胶装比', '安全系数'];
			
			    keys.forEach(key => {
			        const value = localStorage.getItem(key);
			        if (value) {
			            console.log(`${key}:`, JSON.parse(value));
			        } else {
			            langIsEn ? this.$message.info(`${key} is not set`) : this.$message.info(`${key} 未设置`);
			            console.log(langIsEn ? `${key} is not found or not set` : `${key} 未找到或未设置`);
			        }
			    });
			
			    const checkedItems = localStorage.getItem('checkedItems').split(",");
			    if (checkedItems && checkedItems.length > 0) {
			        checkedItems.forEach((item) => {
			            this.loadDataAndPost(item);
			        });
			    } else {
			        console.log(checkedItems);
			        langIsEn ? this.$message.info('No structures selected for calculation!') : this.$message.info('未选择需要计算的结构！');
			        return;
			    }
			}

		},
	};
</script>

<style scoped lang="scss">
	.group_c_2 {
		margin-top: 4.75rem;
		padding: 0 3.75rem 2.44rem;
		border-bottom: solid 0.25rem #575b66;

		.text_6 {
			margin-right: 1.19rem;
			color: #000000;
			font-size: 1.44rem;
			font-family: FZLTZHK;
			line-height: 1.56rem;
			letter-spacing: 0.072rem;
		}
	}

	table {
		width: 100%;
		border-collapse: collapse;
		background-color: #fff7d9;
		/* Light Yellow */
		border: 2px solid white;
	}

	th,
	td {
		border: 2px solid white;
		padding: 8px;
		text-align: left;
	}

	th,
	td:nth-child(1) {
		text-align: center;
		/* Center alignment for the header and the first column */
	}

	th {
		background-color: #ffe066;
		/* Orange */
		border-top-left-radius: 1rem;
		/* 5px radius for the top-left corner */
		border-top-right-radius: 1rem;
		/* 5px radius for the top-right corner */
	}

	/* Widths */
	td:nth-child(1) {
		width: 16.66%;
		/* 1 part */
	}

	td:nth-child(2),
	td:nth-child(3),
	td:nth-child(4) {
		width: 33.33%;
		/* 2 parts */
	}

	.pos_777 {
		position: absolute;
		right: 31.13rem;
		bottom: 40rem;
	}

	input {
		width: 7rem;
		padding-left: 2rem;
		border: none;
		/* Removes the border */
		background: transparent;
		/* Makes the background transparent */
		outline: none;
		box-sizing: border-box;
	}

	.font_2 {
		font-size: 0.88rem;
		font-family: Adobe Heiti Std;
		line-height: 0.88rem;
		color: #a3a3a3;
	}

	.section_bg6 {
		padding: 0.56rem 1.63rem 1.56rem;
		background-image: url('/public/tabbg.png');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		width: 20.94rem;
	}

	.pos__bg10 {
		position: absolute;
		right: 7.94rem;
		top: 0;
	}

	.group_bg11 {
		margin-top: 1.13rem;
		padding-left: 0.94rem;
		padding-right: 0.5rem;
	}

	.space-x-bg36>*:not(:first-child) {
		margin-left: 2.25rem;
	}

	.text_bg11 {
		text-transform: uppercase;
		opacity: 0.34;
	}

	.text_bg12 {
		line-height: 0.81rem;
	}

	.group_bg13 {
		margin-top: 1.06rem;
		padding-left: 1rem;
		padding-right: 0.44rem;
	}

	.space-x-bg35>*:not(:first-child) {
		margin-left: 2.19rem;
	}

	.text_bg13 {
		line-height: 0.81rem;
	}

	.group_bg14 {
		margin-top: 1.06rem;
	}

	.space-x-bg18>*:not(:first-child) {
		margin-left: 1.13rem;
	}

	.text_bg17 {
		margin-right: 0.44rem;
		line-height: 0.81rem;
	}

	.group_bg15 {
		margin-top: 1.06rem;
		padding: 0 0.5rem;
	}

	.space-x-bg28>*:not(:first-child) {
		margin-left: 1.75rem;
	}

	.text_bg18 {
		line-height: 0.81rem;
	}

	.group_bg16 {
		margin-top: 1.13rem;
		padding-left: 1.06rem;
		padding-right: 0.44rem;
	}

	.space-x-bg34>*:not(:first-child) {
		margin-left: 2.13rem;
	}

	.text_bg20 {
		line-height: 0.81rem;
	}

	.group_bg17 {
		margin-top: 1.5rem;
	}

	.group_bg18 {
		width: 6.94rem;
	}

	.image_6 {
		width: 5.5rem;
		height: 4.88rem;
	}

	.font_6 {
		font-size: 0.5rem;
		font-family: HiraginoSansGB;
		line-height: 0.5rem;
		color: #3d3d3d;
	}

	.text_bg21 {
		text-transform: uppercase;
		opacity: 0.73;
	}

	.pos__bg14 {
		position: absolute;
		left: 0;
		top: 0.75rem;
	}

	.group_bg19 {
		width: 11.94rem;
	}

	.space-y-31>*:not(:first-child) {
		margin-top: 1.94rem;
	}

	.pos__bg15 {
		position: absolute;
		right: 2.38rem;
		top: 1.56rem;
	}

	.text_bg2 {
		text-transform: uppercase;
	}

	.text_bg10 {
		color: #535353;
		font-size: 1rem;
		font-family: Adobe Heiti Std;
		line-height: 1rem;
	}

	.page {
		background-color: #f1f1f1;
		width: 100%;
		overflow-y: hidden;
		overflow-x: hidden;
		height: 100%;

		.header {
			padding-bottom: 1.63rem;
			height: 5.88rem;

			.section_2 {
				padding: 0.31rem 3.69rem 0.94rem;
				background-color: #df8d34;

				.text {
					margin-left: 88.75rem;
					color: #fcfcfc;
					font-size: 1.06rem;
					font-family: Adobe Heiti Std;
					line-height: 1rem;
					text-transform: uppercase;
					opacity: 0.93;
				}

				.image {
					width: 3rem;
					height: 3rem;
				}
			}

			.space-x-48 {
				&>*:not(:first-child) {
					margin-left: 3rem;
				}
			}

			.section {
				padding: 1.5rem 3.38rem 1.75rem;
				background-color: #164683;
				border-radius: 0px 2.5rem 0px 0px;
				width: 21.63rem;

				.image_2 {
					width: 3.19rem;
					height: 0.81rem;
				}

				.image_3 {
					width: 8.38rem;
					height: 1.63rem;
				}
			}

			.space-y-3 {
				&>*:not(:first-child) {
					margin-top: 0.19rem;
				}
			}

			.pos {
				position: absolute;
				left: 0;
				top: 0;
			}
		}

		.group {
			overflow-y: auto;

			.section_c_3 {
				padding: 2rem 0.1rem 3rem 8rem;

				.space-y-8 {
					&>*:not(:first-child) {
						margin-top: 0.5rem;
					}

					.group_4 {
						margin-top: 1.44rem;
						width: 30rem;

						.section_3 {
							padding: 0 3.13rem 2.38rem;
							background-color: #494949;
							border-radius: 0.5rem;
							width: 19.69rem;

							.group_4 {
								padding: 1.31rem 0 2.75rem;

								.font_2 {
									font-size: 1.13rem;
									font-family: FZLTZHK;
									line-height: 1.19rem;
									color: #a3a3a3;
								}

								.text_6 {
									line-height: 1.13rem;
								}

								.space-y-16 {
									&>*:not(:first-child) {
										margin-top: 1rem;
									}

									.group_6 {
										padding: 0.31rem 0 0.75rem;

										.image_5 {
											border-radius: 0.5rem;
											width: 2.31rem;
											height: 2.25rem;
										}

										.pos_5 {
											position: absolute;
											left: 0;
											top: 50%;
											transform: translateY(-50%);
										}

										.text_7 {
											line-height: 1.13rem;
										}
									}

									.group_8 {
										padding: 0.5rem 0;

										.section_4 {
											background-color: #2222227d;
											border-radius: 0.5rem;
											width: 2.31rem;
											height: 2.19rem;
										}

										.pos_7 {
											position: absolute;
											left: 0;
											top: 50%;
											transform: translateY(-50%);
										}

										.text_8 {
											line-height: 1.13rem;
										}

										.pos_9 {
											position: absolute;
											left: 0;
											top: 50%;
											transform: translateY(-50%);
										}
									}
								}
							}

							.space-y-37 {
								&>*:not(:first-child) {
									margin-top: 2.31rem;
								}
							}

							.button {
								margin: 0 1.25rem;
								padding: 0.5rem 0;
								background-color: #cb8234;
								border-radius: 0.5rem;
								width: 10.81rem;
								border: solid 0.19rem #cb8234;

								.text_11 {
									color: #2f2e2f;
									font-size: 1.56rem;
									font-family: FZLTZHK;
									line-height: 1.63rem;
								}
							}
						}


						.text_7 {
							color: #000000;
							font-size: 1.81rem;
							font-family: FZLTZHK;
							line-height: 1.69rem;
						}

						.group_6 {
							margin-top: 4.5rem;

							.space-x-22 {
								&>*:not(:first-child) {
									margin-left: 1.38rem;
								}

								.image_6 {
									width: 1.31rem;
									height: 1.31rem;
								}
							}
						}

						.space-y-14 {
							&>*:not(:first-child) {
								margin-top: 0.88rem;
							}
						}

						.divider {
							margin-top: 2.63rem;
							background-color: #e0e0e061;
							border-radius: 0.063rem;
							height: 0.19rem;
						}

						.text_11 {
							margin-top: 4.25rem;
							color: #000000;
							font-size: 1.75rem;
							font-family: FZLTZHK;
							line-height: 1.75rem;
						}

						.group_9 {
							margin-top: 3.38rem;

							.group_10 {
								padding: 0 0.13rem;

								.image_7 {
									width: 1.44rem;
									height: 1.5rem;
								}

								.image_8 {
									width: 1.44rem;
									height: 1.31rem;
								}

								.image_9 {
									width: 1.31rem;
									height: 1.19rem;
								}
							}
						}

						.space-x-23 {
							&>*:not(:first-child) {
								margin-left: 1.44rem;
							}

							.image_4 {
								width: 1.38rem;
								height: 1.38rem;
							}
						}
					}

					.group_8 {
						margin-left: 7.06rem;
						margin-top: 9.5rem;

						.text_8 {
							line-height: 1.25rem;
						}
					}

					.space-y-236 {
						&>*:not(:first-child) {
							margin-top: 14.75rem;
						}
					}

					.group_3 {
						margin-left: 3.38rem;
						width: 27.75rem;

						.image-wrapper {
							padding: 6.75rem 0 3.19rem;
							background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882779348592060112.png');
							background-size: 100% 100%;
							background-repeat: no-repeat;

							.image_5 {
								width: 23.5rem;
								height: 21.75rem;
							}
						}
					}

					.space-y-20 {
						&>*:not(:first-child) {
							margin-top: 1.25rem;
						}
					}

					.group_7 {
						margin-left: 1.38rem;
						margin-top: 9.13rem;

						.text_12 {
							line-height: 1.25rem;
						}
					}

					.space-y-245 {
						&>*:not(:first-child) {
							margin-top: 15.31rem;
						}
					}

					.space-x-24 {
						&>*:not(:first-child) {
							margin-left: 1.5rem;
						}

						.image_10 {
							width: 1.31rem;
							height: 1.06rem;
						}
					}

					.font_3 {
						font-size: 1.38rem;
						font-family: FZLTZHK;
						line-height: 1.94rem;
						color: #000000;
					}

					.text_14 {
						margin-right: 17rem;
						line-height: 1.25rem;
					}
				}

				.text-wrapper_4 {
					margin-left: 30rem;
					padding: 0.69rem 0;
					background-color: #f59e40;
					border-radius: 0.5rem;
					width: 10.75rem;

					.text_15 {
						color: #000000;
						font-size: 1.56rem;
						font-family: FZLTZHK;
						line-height: 1.63rem;
					}
				}
			}

			.section_3 {
				padding: 3rem 0 3.88rem 0.75rem;
				background-color: #164683;
				border-radius: 0px 0px 2.5rem 0px;
				width: 21.63rem;
				height: 61.63rem;

				.group_3 {
					padding: 0 3.75rem 2.44rem;
					border-bottom: solid 0.25rem #575b66;

					.image_4 {
						width: 1.5rem;
						height: 1.5rem;
					}

					.text_3 {
						margin-right: 1.31rem;
						color: #ffffff;
						font-size: 1.44rem;
						font-family: Adobe Heiti Std;
						line-height: 1.44rem;
						letter-spacing: 0.072rem;
					}
				}

				.space-x-24 {
					&>*:not(:first-child) {
						margin-left: 1.5rem;
					}
				}

				.group_4 {
					margin-top: 3.56rem;

					.section_6 {
						opacity: 0.95;
						background-color: #e9e9e9;
						border-radius: 0.94rem;
						width: 15.63rem;
						height: 2.94rem;
						border: solid 0.19rem #df8d34;
					}

					.pos_4 {
						position: absolute;
						left: 3.75rem;
						top: 50%;
						transform: translateY(-50%);
					}

					.pos_44 {
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
					}
				}

				.text_2 {
					text-transform: uppercase;
					opacity: 0.8;
					font-size: 1.1rem;
				}

				.group_5 {
					font-size: 1.1rem;
					margin-top: 2.88rem;
					padding: 0 2.38rem;

					.text-wrapper_2 {
						margin-right: 0.5rem;
						padding: 1rem 0;
						background-color: #fcfcfc91;
						border-radius: 0.94rem;
						width: 15.63rem;

						.text_6 {
							line-height: 0.94rem;
							text-transform: uppercase;
							opacity: 0.84;
						}
					}

					.image-wrapper {
						margin-right: 0.5rem;
						padding: 1rem 0;
						background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882057507515801764.png');
						background-size: 100% 100%;
						background-repeat: no-repeat;

						.image_7 {
							width: 11.5rem;
							height: 1rem;
						}
					}

					.button {
						margin-right: 0.5rem;
						padding: 1rem 0;
						background-color: #fcfcfc80;
						border-radius: 0.94rem;

						.image_8 {
							width: 10.5rem;
							height: 1rem;
						}
					}

					.button_3 {
						margin-right: 0.5rem;
						padding: 1rem 0;
						background-color: #fcfcfc80;
						border-radius: 0.94rem;
						width: 15.63rem;

						.text_13 {
							color: #434343;
						}
					}
				}

				.space-y-46 {
					&>*:not(:first-child) {
						margin-top: 2.88rem;
					}
				}

				.font_2 {
					font-size: 0.94rem;
					font-family: Adobe Heiti Std;
					line-height: 1rem;
					color: #2d2d2d;
				}

				.text_16 {
					margin-top: 19.81rem;
					color: #fcfcfc;
					font-size: 1.13rem;
					font-family: Adobe Heiti Std;
					line-height: 1.06rem;
					text-transform: uppercase;
					opacity: 0.79;
				}
			}

			.group_2 {
				padding-top: 28.44rem;

				.image-wrapper_2 {
					padding: 6rem 0 20rem;
					background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882057507331846491.png');
					background-size: 100% 100%;
					background-repeat: no-repeat;

					.image_9 {
						margin-right: 43.88rem;
						width: 2rem;
						height: 5rem;
					}
				}

				.section_4 {
					padding: 2rem 3.19rem 2.81rem;
					background-color: #4949493d;
					border-radius: 0.5rem;
					width: 19.56rem;

					.image_5 {
						width: 9.25rem;
						height: 1.19rem;
					}

					.text-wrapper {
						margin-top: 1rem;
						padding: 0.5rem 0;
						background-color: #fcfcfc7d;
						border-radius: 0.5rem;
						width: 13.19rem;

						.font_1 {
							font-size: 0.81rem;
							font-family: Adobe Heiti Std;
							line-height: 1.13rem;
							color: #3d3d3d;
						}

						.text_4 {
							margin-left: 1.56rem;
							text-transform: uppercase;
							opacity: 0.34;
						}

						.text_5 {
							margin-left: 1.63rem;
						}
					}

					.view {
						margin-top: 0;
					}

					.group_6 {
						margin-top: 3.31rem;
						padding-left: 1.13rem;

						.group_7 {
							width: 8.38rem;

							.image_6 {
								width: 7.56rem;
								height: 6.69rem;
							}

							.pos_5 {
								position: absolute;
								left: 0;
								top: 0.63rem;
							}
						}

						.text_8 {
							margin-top: 2.25rem;
						}
					}

					.space-x-14 {
						&>*:not(:first-child) {
							margin-left: 0.88rem;
						}
					}

					.font_3 {
						font-size: 0.69rem;
						font-family: Adobe Heiti Std;
						line-height: 0.69rem;
						color: #3d3d3d;
					}

					.text_7 {
						text-transform: uppercase;
						opacity: 0.73;
					}

					.text_11 {
						margin-top: 0.38rem;
					}

					.button_2 {
						margin-top: 2.5rem;
						padding: 0.75rem 0;
						background-color: #df8d34;
						border-radius: 0.5rem;
						width: 10.81rem;

						.text_12 {
							color: #ffffff;
							font-size: 1.56rem;
							font-family: Adobe Heiti Std;
							line-height: 1.63rem;
						}
					}
				}

				.space-y-37 {
					&>*:not(:first-child) {
						margin-top: 2.31rem;
					}
				}

				.pos_2 {
					position: absolute;
					right: 6.13rem;
					top: 12rem;
				}

				.section_5 {
					padding: 15.25rem 4.56rem 8rem;
					background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882057513498483953.png');
					background-size: 100% 100%;
					background-repeat: no-repeat;
					width: 61.88rem;

					.text-wrapper_3 {
						padding: 0.63rem 0 0.88rem;
						background-color: #f7f7f7;
						border-radius: 0.5rem;
						width: 13.19rem;
						border: solid 0.19rem #cb8234;

						.text_10 {
							color: #3b2a2a;
						}
					}

					.text-wrapper_4 {
						padding: 0.63rem 0 0.81rem;
						background-color: #f7f7f7;
						border-radius: 0.5rem;
						width: 13.19rem;
						border: solid 0.19rem #cb8234;

						.text_14 {
							color: #292e35;
						}
					}
				}

				.space-y-178 {
					&>*:not(:first-child) {
						margin-top: 11.13rem;
					}
				}

				.pos_3 {
					position: absolute;
					left: 16rem;
					top: 16rem;
				}

				.text-wrapper_5 {
					padding: 0.63rem 0 0.88rem;
					background-color: #f7f7f7;
					border-radius: 0.5rem;
					width: 13.13rem;
					border: solid 0.19rem #cb8234;

					.text_15 {
						color: #433232;
					}
				}

				.pos_6 {
					position: absolute;
					left: 27.19rem;
					top: 42.25rem;
				}

				.font_4 {
					font-size: 1.38rem;
					font-family: Adobe Heiti Std;
					line-height: 1.31rem;
				}
			}

			.text_9 {
				text-transform: uppercase;
			}
		}
	}

	input::placeholder {
		color: #000000;
		font-size: 1.2em;
	}

	.unit {
		display: inline-block;
	}

	.van-tabbar-item {
		/* Adjust these to change the style of the tabs */
		width: 150px;
		text-align: center;
		border-radius: 10px 10px 0 0;
		border: 2px solid orange;
		font-size: 1.2rem;
	}

	.van-tabbar-item.active {
		color: #164683;
		border-bottom: none;
		font-weight: 300;
	}
</style>