<template>
  <div class="flex-col page">
    <div class="flex-col justify-start items-end relative header">
      <div class="flex-row items-center section_2 space-x-48">
         <span class="self-start text" style="margin-top:1.2rem;" @click="loginout">{{userinfo.nickname}}， {{ lang === 'en' ? 'Hello (Click to logout)' : '你好（点击退出）' }}</span>
        <img
          class="shrink-0 image"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882057507383748530.png"
        />
      </div>
	 <!-- <div class="flex-row justify-end section_2 space-x-43-reverse pos_2" style="top:50px;">
	       <van-tabbar v-model="activeTab" style="top:4.3rem;width:98.4rem;left:21.6rem;position:absolute;">
	           <van-tabbar-item v-for="(tab, index) in tabs" :key="index" :class="activeTab === index ? 'active' : ''">
	           <input type="checkbox" :value="index"> {{ tab }}
	           </van-tabbar-item>
	         </van-tabbar>
	  </div> -->
	  <div class="flex-row section_2 justify-start space-x-43-reverse pos_2" style="top:4rem;background:none;margin-left:-20rem;">
	  	<div style="margin-left:-50rem;">{{ lang === 'en' ? 'Please select a structure type' : '请选择结构形式' }}</div>
	       <van-tabbar v-model="activeTab" style="top:6rem;width:81vw;left:18vw;position:absolute;height:3.5rem;">
	           <van-tabbar-item class="custom-checkbox" v-for="(tab, index) in tabs" :key="index" :class="activeTab === index ? 'active' : ''">
	           <input name="myCheckbox" type="checkbox" :value="index" v-model="checkedItems" style="font-weight:600;width:2rem;padding-left:0;"> {{ tab }}
	           </van-tabbar-item>
	         </van-tabbar>
	  </div>
      <div class="flex-col items-start section space-y-3 pos">
        <img
          class="image_2"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882057507649290089.png"
        />
        <img
          class="image_3"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/638b18425a7e3f03100a62af/649947d8726ab50011f379b8/50306348c9b05809c4d214e0868b960e.png"
        />
      </div>
    </div>
    <div class="flex-row items-end flex-auto group" style="overflow-x:hidden">
      <div class="flex-col shrink-0 section_3">
        <div class="flex-row items-center group_3 space-x-24">
          <img
            class="shrink-0 image_4"
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882057507488602865.png"
          />
          <span class="text_2 text_3" :style='lang === "en"?"font-size:1.2rem;":""'> {{ lang === 'en' ? 'Design workflow' : '设计要素流程' }}</span>
        </div>

        <div class="flex-col group_5 space-y-46">
          <div class="flex-col justify-start items-center text-wrapper_2" style="background-color: #e9e9e9;border: solid 0.19rem #df8d34;">
			<div class="section_6"></div>
            <span class="font_2 text_6" style="font-size:1rem;text-transform: none;">{{ lang === 'en' ? 'A1 Geom' : 'A1 结构-几何输入' }}</span>
          </div>
          <div class="flex-col justify-start items-center relative group_4">
            <div class="section_6" style="background-color: rgba(252, 252, 252, 0.568627451);border:none"></div>
            <span class="font_2 text_2 pos_4"  @click="next(2)" style="font-size:1rem;">{{ lang === 'en' ? 'B1 Loads' : 'B1 工况输入' }}</span>
          </div>
		  <div class="flex-col justify-start items-center relative group_4" >
		    <div class="section_6" style="background-color: rgba(252, 252, 252, 0.568627451);border:none"></div>
		    <span class="font_2 text_2 pos_44" style="font-size:1rem;" @click="next(6)">{{ lang === 'en' ? 'B2 Comp&Results' : 'B2 选项计算及结果' }}</span>
		  </div>
          <div class="flex-col justify-start items-center image-wrapper">
           <div class="section_6"></div>
           <span class="font_2 text_2 pos_4" @click="next(3)" style="font-size:1rem;">{{ lang === 'en' ? 'C1 Visualization' : 'C1 结构设计性能的可视化' }}</span>
          </div>
          <div class="flex-col justify-start items-center button">
           <div class="section_6"></div>
           <span class="font_2 text_2 pos_4" @click="next(4)" style="font-size:1rem;">{{ lang === 'en' ? 'C2 Summary' : 'C2 结构设计性能的总结' }}</span>
          </div>
          <!-- <div class="flex-col justify-start items-center button_3">
            <span class="font_2 text_9 text_13" style="font-size:1.1rem;">完成流程</span>
          </div> -->
        </div>
		
        <span class="self-center text_16"></span>
      </div>
      <div class="flex-col justify-start flex-auto relative group_2">
        <div class="flex-col justify-start items-end image-wrapper_2">
            <img class="pos_777"
              @click="changshow" :style='"height:"+imgstyle[activeTab].h+"rem;width:"+imgstyle[activeTab].w+"rem;top:"+imgstyle[activeTab].mt+"rem;"'
              :src='activeTab+"-zb.png"'
            />
			<img style="margin-right:80rem;margin-top:-10rem;height:10rem;width:10rem"
			  class="image_6"
			  src="xyz.png"
			/>
			<div class="button_3 pos_8" style="position: absolute;padding:0.3rem;border:3px solid #fec56d;border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;top:2rem;right:5rem;background:none;">
			 <table>
			        <thead>
			            <th colspan="4" style="background-color:#fec56d;padding: 0 10px;">
			                {{ lang === 'en' ? 'Input coord(mm)' : '输入坐标(mm)' }}
			                <button @click="reset"
			                        style="border-radius:5px;line-height:1; padding-top:0px;
			                               outline: none; box-shadow: none; border: 1px solid transparent;float: inline-end;font-size:0.8rem;margin: top 10px;">
			                        {{ lang === 'en' ? 'Reset' : '重置' }}
			                    </button>
			            </th>
			        </thead>
			      <tbody>
			      	<tr>
			      		<td><span style="width:5rem;" >A</span></td>
			      		<td><input type="text" placeholder="X" v-model="point_A[0]" @blur="basehandleBlur(1,activeTab)" ></td>
			      		<td><input type="text" placeholder="Y" v-model="point_A[1]" @blur="basehandleBlur(2,activeTab)"></td>
			      		<td><input type="text" placeholder="Z" v-model="point_A[2]" @blur="basehandleBlur(3,activeTab)"></td>
			      	</tr>
			      	<tr>
			      		<td>B</td>
			      		<td><input type="text" placeholder="X" v-model="point_B[0]" @blur="basehandleBlur(11,activeTab)"></td>
			      		<td><input type="text" placeholder="Y" v-model="point_B[1]" @blur="basehandleBlur(12,activeTab)"></td>
			      		<td><input type="text" placeholder="Z" v-model="point_B[2]" @blur="basehandleBlur(13,activeTab)"></td>
			      	</tr>
			      	<tr v-if="activeTab>=1">
			      		<td>C</td>
			      		<td><input type="text" placeholder="X" v-model="point_C[0]" @blur="basehandleBlur(21,activeTab)"></td>
			      		<td><input type="text" placeholder="Y" v-model="point_C[1]" @blur="basehandleBlur(22,activeTab)"></td>
			      		<td><input type="text" placeholder="Z" v-model="point_C[2]" @blur="basehandleBlur(23,activeTab)"></td>
			      	</tr>
			      	<tr v-if="activeTab>1">
			      		<td>D</td>
			      		<td><input type="text" placeholder="X" v-model="point_D[0]" @blur="basehandleBlur(31,activeTab)"></td>
			      		<td><input type="text" placeholder="Y" v-model="point_D[1]" @blur="basehandleBlur(32,activeTab)"></td>
			      		<td><input type="text" placeholder="Z" v-model="point_D[2]" @blur="basehandleBlur(33,activeTab)"></td>
			      	</tr>
			      	<tr v-if="activeTab==2||activeTab==4">
			      		<td>E</td>
			      		<td><input type="text" placeholder="X" v-model="point_E[0]" @blur="basehandleBlur(41,activeTab)"></td>
			      		<td><input type="text" placeholder="Y" v-model="point_E[1]" @blur="basehandleBlur(42,activeTab)"></td>
			      		<td><input type="text" placeholder="Z" v-model="point_E[2]" @blur="basehandleBlur(43,activeTab)"></td>
			      	</tr>
			      </tbody>
			    </table> 
			</div>
        </div>
        

         
        </div>
       
      </div>
    </div>
  </div>
</template>

<script>
import { Checkbox, Switch } from 'element-ui';
  export default {
    components: {},
    data() {
      return {
		   lang: localStorage.getItem('lang') || 'cn',
	       activeTab0data: null,
	       activeTab1data: null,
	       activeTab2data: null,
	       activeTab3data: null,
	       activeTab4data: null,	  
			 point_A: [null, null, null],
			 point_B: [null, null, null],
			 point_C: [null, null, null],
			 point_D: [null, null, null],
			 point_E: [null, null, null],			
		items: [
			{ id: 1, label: '选项 1' },
			{ id: 2, label: '选项 2' },
			{ id: 3, label: '选项 3' }
					],
			checkedItems:[],
			gksr:0,
			zbsr:1,
			zbsr2:1,
			activeTab: 0,
			userinfo:null,
			imgstyle:[
					  {"mt":17, "w": 48, "h": 9.36}, 
					  {"mt":5,"w":39.5, "h": 34}, 
					  {"mt":8,"w": 48, "h": 29.25}, 
					  {"mt":8,"w": 48, "h": 25.47}, 
					  {"mt":8,"w": 48, "h": 31.70}
					],
		tabs: ['单柱', '单柱单拉', '单柱三拉', '双柱单拉', '双柱双拉'],
      };
    },
	mounted() {
		 if(localStorage.getItem('lang')=="en")
		    {
				this.tabs=['1B', '1B-1T', '1B-3T', '2B-1T', '2B-2T'];
			}
		if(JSON.parse(localStorage.getItem(`userinfo`)))
		  {
			  this.userinfo=JSON.parse(localStorage.getItem(`userinfo`));
			  console.info(this.userinfo);
		  }
		  else
		  {
			  this.$router.push('/');
		  }
		if(localStorage.getItem(`checkedItems`))
		  {
			  console.log(localStorage.getItem(`checkedItems`));
			  this.checkedItems=localStorage.getItem(`checkedItems`).split(",");
		  }
		localStorage.setItem('jgtype',this.activeTab);
		const storedData = localStorage.getItem(`activeTab0data`);
		if (storedData) {
			console.log("存在更新");
		  // 如果存在，解析数据并更新点的值
		  const newData = JSON.parse(storedData);
		  this.point_A = newData.point_A;
		  this.point_B = newData.point_B;
		  this.point_C = newData.point_C;
		  this.point_D = newData.point_D;
		  this.point_E = newData.point_E;
		}
		else
		{
			this.point_A = [null, null, null];
			this.point_B = [null, null, null];
			this.point_C = [null, null, null];
			this.point_D = [null, null, null];
			this.point_E = [null, null, null];
		}
		},
	 watch: {
           checkedItems(newValue,oldValue) {
              console.log(`变量改变了: 从 ${oldValue} 变为 ${newValue}`);
			  console.info(newValue);
			  if(this.arraysAreDifferent(newValue,this.transformAndUnique(newValue)))
			  {
				newValue=this.transformAndUnique(newValue);
				this.checkedItems=newValue;
			  }
			  localStorage.setItem('checkedItems',newValue);
              // 在这里可以执行任何响应变化的操作
            },
	    activeTab(newTab) {
			  console.log('原有tab项是',localStorage.getItem('jgtype'));	
			//选缓存数据
	         localStorage.setItem(`activeTab${localStorage.getItem('jgtype')}data`, JSON.stringify({
	              point_A: this.point_A,
	              point_B: this.point_B,
	              point_C: this.point_C,
	              point_D: this.point_D,
	              point_E: this.point_E,
	            }));
	      
	            // 检查新Tab对应的数据是否在本地缓存中存在
	            const storedData = localStorage.getItem(`activeTab${newTab}data`);
	            if (storedData) {
					console.log("存在更新");
	              // 如果存在，解析数据并更新点的值
	              const newData = JSON.parse(storedData);
	              this.point_A = newData.point_A;
	              this.point_B = newData.point_B;
	              this.point_C = newData.point_C;
	              this.point_D = newData.point_D;
	              this.point_E = newData.point_E;
	            }
				else
				{
					this.point_A = [null, null, null];
					this.point_B = [null, null, null];
					this.point_C = [null, null, null];
					this.point_D = [null, null, null];
					this.point_E = [null, null, null];
				}
	      
	            // 更新当前激活的Tab
		  	
	            this.activeTab = newTab;
				
	      console.log('Tab changed to: ', newTab);
		  console.log(this.activeTab);
		  localStorage.setItem('jgtype',this.activeTab);
		  let value = localStorage.getItem('jgtype');
		  console.log(value);
	    },
	  },
    methods: {
		reset(){
			this.point_A = [null, null, null];
			this.point_B = [null, null, null];
			this.point_C = [null, null, null];
			this.point_D = [null, null, null];
			this.point_E = [null, null, null];
		},
		arraysAreDifferent(arr1, arr2) {
		  // 首先检查长度
		  if (arr1.length !== arr2.length) {
		    return true; // 不相同
		  }
		  // 然后比较每个位置的元素
		  for (let i = 0; i < arr1.length; i++) {
		    if (arr1[i] !== arr2[i]) {
		      return true; // 不相同
		    }
		  }
		  // 如果以上检查都通过，则认为数组相同
		  return false; // 相同
		},
		transformAndUnique(array) {
		  // 将所有文本数字项转换为数字
		  const transformedArray = array.map(item => {
		    const parsed = parseFloat(item);
		    return isNaN(parsed) ? item : parsed;
		  });
		
		  // 使用Set去重
		  return Array.from(new Set(transformedArray));
		},
		checkPoints() {
		     // 列出所有要检查的点
		     const points = [
		       this.point_A,
		       this.point_B,
		       this.point_C,
		       this.point_D,
		       this.point_E,
		     ];
				 
		     // 遍历每个点
		     for (let point of points) {
		       // 检查点内的每个元素
		       for (let element of point) {
		         if (element === null || element === undefined) {
		           return false;
		         }
		       }
		     }
			  
		     // 如果所有点的所有元素都不为空或未定义，返回 true
		     return true;
		   },
		 basehandleBlur(index,activeTab) {
			 localStorage.setItem(`activeTab${this.activeTab}data`, JSON.stringify({
			      point_A: this.point_A,
			      point_B: this.point_B,
			      point_C: this.point_C,
			      point_D: this.point_D,
			      point_E: this.point_E,
			    }));
              if(index==13&&activeTab==0)
			    {
				this.checkedItems=this.addValueIfNotPresent(this.checkedItems,activeTab);
				this.checkedItems=this.checkedItems.filter(item => item !== null && item !== undefined && item !== "" && !Array.isArray(item))
				this.handleBlur()
				}
				if(index==23&&activeTab==1)
				  {
					this.checkedItems=this.addValueIfNotPresent(this.checkedItems,activeTab);
					this.checkedItems=this.checkedItems.filter(item => item !== null && item !== undefined && item !== "" && !Array.isArray(item))
					this.handleBlur()
				   }	
			  	if(index==43&&activeTab==2)
			  	  {
			  		this.checkedItems=this.addValueIfNotPresent(this.checkedItems,activeTab);
			  		this.checkedItems=this.checkedItems.filter(item => item !== null && item !== undefined && item !== "" && !Array.isArray(item))
			  		this.handleBlur()
			  	   }
				 if(index==33&&activeTab==3)
				   {
				 	this.checkedItems=this.addValueIfNotPresent(this.checkedItems,activeTab);
				 	this.checkedItems=this.checkedItems.filter(item => item !== null && item !== undefined && item !== "" && !Array.isArray(item))
				 	this.handleBlur()
				    } 
				if(index==43&&activeTab==4)
				  {
					this.checkedItems=this.addValueIfNotPresent(this.checkedItems,activeTab);
					this.checkedItems=this.checkedItems.filter(item => item !== null && item !== undefined && item !== "" && !Array.isArray(item))
					this.handleBlur()
				   } 
			  
			  
		 },
		 addValueIfNotPresent(array,value) {
			 console.log(value*1);
		   // 检查数组中是否包含给定的值
		   if (!array.includes(value*1)) {
		     // 如果不包含，就添加这个值到数组中
		     array.push(value*1);
		   }
		   // 返回修改后的数组
		   return array;
		 },
		  handleBlur(index,activeTab){
			        localStorage.setItem(`activeTab${this.activeTab}data`, JSON.stringify({
			             point_A: this.point_A,
			             point_B: this.point_B,
			             point_C: this.point_C,
			             point_D: this.point_D,
			             point_E: this.point_E,
			           }));
		      // 输入框失去焦点时触发
			        console.log(this.checkedItems)
					localStorage.setItem('checkedItems', JSON.stringify(this.checkedItems));
			        // const points = [
			        //   { name: 'A', values: this.point_A },
			        //   { name: 'B', values: this.point_B },
			        //   { name: 'C', values: this.point_C, condition: () => this.activeTab >= 1 },
			        //   { name: 'D', values: this.point_D, condition: () => this.activeTab > 1 },
			        //   { name: 'E', values: this.point_E, condition: () => this.activeTab === 2 || this.activeTab === 4 }
			        // ];
			      
			    //     for (const point of points) {
					  // console.log("输入检查中");
			    //       // 检查是否需要检查该点
			    //       if (point.condition && !point.condition()) {
			    //         continue;
			    //       }
			      
			    //       for (let i = 0; i < point.values.length; i++) {
						 //  console.log(point.values[i]);
			    //         if (!point.values[i]) {
			    //           this.$message.error(`点 ${point.name} 的 ${['X', 'Y', 'Z'][i]} 坐标缺失`);
			    //           return;
			    //         }
			    //       }
			    //     }
					const storedData0 = localStorage.getItem(`activeTab0data`);
					const storedData1 = localStorage.getItem(`activeTab1data`);
					const storedData2 = localStorage.getItem(`activeTab2data`);
					const storedData3 = localStorage.getItem(`activeTab3data`);
				    const storedData4 = localStorage.getItem(`activeTab4data`); 
					const pointData0 = JSON.parse(storedData0);
					const pointData1 = JSON.parse(storedData1);
					const pointData2 = JSON.parse(storedData2);
					const pointData3 = JSON.parse(storedData3);
					const pointData4 = JSON.parse(storedData4);
					if (this.checkedItems && this.checkedItems.length > 0) {
					    this.checkedItems.forEach((item) => {
					        switch (item) {
					            case 0:
								    if(pointData0&&this.checkxyz(pointData0,"point_A","单柱结构","1B")&&this.checkxyz(pointData0,"point_B","单柱结构","1B"))
									this.pointAngle0(pointData1.point_A, pointData1.point_B);
					                break;
					            case 1:
					                if(pointData1&&this.checkxyz(pointData1,"point_A","单柱单拉结构","1B1T")&&this.checkxyz(pointData1,"point_B","单柱单拉结构","1B1T")&&this.checkxyz(pointData1,"point_C","单柱单拉结构","1B1T"))
					                this.pointAngle1(pointData1.point_A,pointData1.point_B,pointData1.point_C);
									break;
					            case 2:
					               if(pointData2&&this.checkxyz(pointData2,"point_A","单柱三拉结构","1B3T")&&this.checkxyz(pointData2,"point_B","单柱三拉结构","1B3T")&&this.checkxyz(pointData2,"point_C","单柱三拉结构","1B3T")&&this.checkxyz(pointData2,"point_D","单柱三拉结构","1B3T")&&this.checkxyz(pointData2,"point_E","单柱三拉结构"))
					               this.pointAngle2(pointData2.point_A,pointData2.point_B,pointData2.point_C,pointData2.point_D,pointData2.point_E);
					               break;
					            case 3:
					               if(pointData3&&this.checkxyz(pointData3,"point_A","双柱单拉结构","2B1T")&&this.checkxyz(pointData3,"point_B","双柱单拉结构","2B1T")&&this.checkxyz(pointData3,"point_C","双柱单拉结构","2B1T")&&this.checkxyz(pointData3,"point_D","双柱单拉结构","2B1T"))
					               this.pointAngle3(pointData3.point_A,pointData3.point_B,pointData3.point_C,pointData3.point_D);
					               break;
					            case 4:
					               if(pointData4&&this.checkxyz(pointData4,"point_A","双柱双拉结构","2B2T")&&this.checkxyz(pointData4,"point_B","双柱双拉结构","2B2T")&&this.checkxyz(pointData4,"point_C","双柱双拉结构","2B2T")&&this.checkxyz(pointData4,"point_D","双柱双拉结构","2B2T")&&this.checkxyz(pointData4,"point_E","双柱双拉结构","2B2T"))
					               this.pointAngle4(pointData4.point_A,pointData4.point_B,pointData4.point_C,pointData4.point_D,pointData4.point_E);
					               break;
					            default:
					                // 如果有需要，这里可以处理其他未预期的值
					                console.log('Unexpected item:', item);
					        }
					    });
					    this.pointend();
						// if(pointData2&pointData2.point_A)
						// {
						// CheckData2 =  [pointData2.point_A, pointData2.point_B, pointData2.point_C, pointData2.point_D, pointData2.point_E]
						//         .every(point => point.every(value => value !== null));	
						// }
						// if(pointData4&pointData2.point_A)
						// {
						// CheckData4 =  [pointData4.point_A, pointData4.point_B, pointData4.point_C, pointData4.point_D, pointData4.point_E]
						//         .every(point => point.every(value => value !== null));
						//  }
						//  console.log("checkdata4",CheckData4)
						// switch (this.activeTab){
						// 	case 2:
						// 	    this.pointAngle13(this.point_A, this.point_B, this.point_C, this.point_D, this.point_E);
						// 		if(CheckData4)
						// 		{
						// 		this.pointAngle22(pointData4.point_A, pointData4.point_B, pointData4.point_C, pointData4.point_D, pointData4.point_E);	
						// 		}
								
						// 		break;
						// 	case 4:
						// 	    this.pointAngle22(this.point_A, this.point_B, this.point_C, this.point_D, this.point_E);
						// 		if(CheckData2)
						// 		{
						// 		this.pointAngle13(pointData2.point_A, pointData2.point_B, pointData2.point_C, pointData2.point_D, pointData2.point_E);	
						// 		}
						// 		break;	
						// 	default:
						// 	    this.pointAngle22(this.point_A, this.point_B, this.point_C, this.point_D, this.point_E);
						// 		if(CheckData2)
						// 		{
						// 		this.pointAngle13(pointData2.point_A, pointData2.point_B, pointData2.point_C, pointData2.point_D, pointData2.point_E);	
						// 		}
						// 		break;
						// }
					}
			       
			        // 所有输入均已填写，继续其他操作
			      // this.$confirm('请确认数据是否输入完成', '确认', {
			      //   confirmButtonText: '确定',
			      //   cancelButtonText: '取消',
			      //   type: 'warning'
			      // }).then(() => {
			      //   // 用户点击了确认按钮
			      //   if (this.allPointsFilled()) {
			      //     const result = this.pointAngle(this.point_A, this.point_B, this.point_C, this.point_D, this.point_E);
			      //     // 处理计算结果
			      //     console.log(result);
			      //     // this.$router.push('/chanpin2'); // 跳转页面
			      //   }
			      // }).catch(() => {
			      //   // 用户点击了取消按钮或关闭了对话框
			      //   console.log('用户取消操作2');
			      // });
					},
					pointend() {
					    setTimeout(() => {
					        const langIsEn = localStorage.getItem('lang') === "en";
					        
					        const confirmMessage = langIsEn ? 'Geometric calculations completed and stored, proceed to condition input page?' : '几何计算完成并储存，是否进入工况输入页面';
					        const confirmTitle = langIsEn ? 'Confirm' : '确认';
					        const confirmButtonText = langIsEn ? 'Confirm' : '确定';
					        const cancelButtonText = langIsEn ? 'Cancel' : '取消';
					        
					        this.$confirm(confirmMessage, confirmTitle, {
					            confirmButtonText: confirmButtonText,
					            cancelButtonText: cancelButtonText,
					            type: 'warning'
					        }).then(() => {
					            this.$router.push('/chanpin2');
					        }).catch(() => {
					            console.log(langIsEn ? 'User cancelled the operation' : '用户取消操作');
					        });
					    }, 300);
					},
					checkxyz(obj,key,type,typeen) {
					    // 获取指定键的值（一个数组）
					    const array = obj[key];
						if(localStorage.getItem('lang')=="en")
						    {
							  type=typeen;
							}
					    // 如果数组不存在，返回提示
					    if (!array) {
							if(localStorage.getItem('lang')=="en")
							    {
								   this.$message.error(`${type} ${key.slice(-1)} No input！`);
								}
								else
								{
								    this.$message.error(`${type}点 ${key.slice(-1)} 无输入信息！`);	
								}
					       return false;
					    }
					    // 遍历数组检查每个元素
					    for (let i = 0; i < array.length; i++) {
					        if (array[i] === null || array[i] === "") {
					            // 根据索引输出不同的字符
								if(localStorage.getItem('lang')=="en")
							         {
										 switch (i) {
										     case 0:
										 	     this.$message.error(`There is no input information for the ${type} ${key.slice(-1)} X coordinate！`);
										         return false;
										     case 1:
										 		this.$message.error(`There is no input information for the ${type} ${key.slice(-1)} Y coordinate！`);
										 	    return false;
										     case 2:
										 	    this.$message.error(`There is no input information for the ${type} ${key.slice(-1)} Z coordinate！`);
										         return false;
										     default:
										         // 可以根据需要处理其他情况
										 }
									 }
									 else
									 {
										 switch (i) {
										     case 0:
										 	    this.$message.error(`${type}点 ${key.slice(-1)} 的X坐标无输入信息！`);
										         return false;
										     case 1:
										 	    this.$message.error(`${type}点 ${key.slice(-1)} 的Y坐标无输入信息！`);
										 	    return false;
										     case 2:
										 	    this.$message.error(`${type}点 ${key.slice(-1)} 的Z坐标无输入信息！`);
										         return false;
										     default:
										         // 可以根据需要处理其他情况
										 }
									 }
					           
					        }
					    }
					
					    // 所有元素都是非空的
					    return true;
					},
					pointAngle0(pointA, pointB)
					 {
						let length =  pointA[0] - pointB[0];
						let alfa = 0;
						let beta = 0;
						const result = {length, alfa, beta };
						localStorage.setItem('pointAngleResult0', JSON.stringify(result)); 
					 },
                    pointAngle1(pointA, pointB, pointC) {
                      // 点以[x, y, z]的形式给出
                      // 计算伸出长度
                      let length = pointC[0] - pointB[0]; // 使用索引0来访问x坐标
                      // 张角，示例中直接设置为0
                      let alfa = 0;
                      // 计算仰角
                      let deltaY = Math.abs(pointA[2] - pointB[2]); // 使用索引2来访问z坐标
                      let beta = Math.atan2(deltaY, length) * (180 / Math.PI); // 弧度转换为度
                      const result = {length, alfa, beta };
                      localStorage.setItem('pointAngleResult11', JSON.stringify(result));
                    },
					pointAngle2(point_A, point_B, point_C, point_D, point_E){
						console.log("pointAngle2");
						const length = point_E[0] - point_C[0];
						// 张角
						const alfa = Math.abs(Math.atan((point_C[1] - point_D[1]) / length) * 2 * (180 / Math.PI));
						// 仰角
						const beta = Math.abs(Math.atan((point_A[2] - point_C[2]) / length) * (180 / Math.PI));
						const result = {length, alfa, beta };
						localStorage.setItem('pointAngleResult13', JSON.stringify(result));
					},
					pointAngle3(pointA, pointB, pointC, pointD){
						let length = pointD[0] - pointC[0]; // 点的x坐标
						  // 张角
						let alfa = Math.abs(Math.atan2(pointC[1] - pointD[1], length) * (180 / Math.PI) * 2);
						  // 仰角
						let beta = Math.abs(Math.atan2(pointA[2] - pointC[2], length) * (180 / Math.PI));
						const result = {length, alfa, beta };
						localStorage.setItem('pointAngleResult21', JSON.stringify(result));
					},
					pointAngle4(point_A, point_B, point_C, point_D, point_E) {
						// 检查所有点的所有坐标是否都是1
					  						// 否则，执行原有的计算逻辑
					  						const length = Math.abs(point_E[0] - point_C[0]);
					  						const alfa = Math.abs(Math.atan2(point_C[1] - point_E[1], length) * 2 * 180 / Math.PI);
					  						const beta = Math.abs(Math.atan2(point_A[2] - point_C[2], length) * 180 / Math.PI);
                                            localStorage.getItem('lang') === "en" ? this.$message.info('The length is ' + length) : this.$message.info('长度为' + length);
                                            
                                            setTimeout(() => {
                                                localStorage.getItem('lang') === "en" ? this.$message.info('The opening angle is ' + alfa) : this.$message.info('张角为' + alfa);
                                            }, 500);
                                            
                                            setTimeout(() => {
                                                localStorage.getItem('lang') === "en" ? this.$message.info('The elevation angle is ' + alfa) : this.$message.info('张角为' + alfa);
                                                this.$message.info('仰角为' + beta);
                                            }, 1000);
					  						const result = { length, alfa, beta };
					  						console.log(result);
											localStorage.setItem('pointAngleResult22', JSON.stringify(result));
					  						localStorage.setItem('pointAngleResult', JSON.stringify(result));
					},
					
					// 示例用法：
					// const result = pointAngle([0, -500, -500], [0, 500, -500], [0, 500, 0], [0, -500, 0], [1000, 0, 0]);
					// console.log(result);
					 allPointsFilled() {
					      // 检查所有点是否都有完整的数据
						  console.log("allPointsFilled");
					      return [this.point_A, this.point_B, this.point_C, this.point_D, this.point_E]
					        .every(point => point.every(value => value !== null));
					    },
							
           loginout(){
			  localStorage.removeItem("userinfo");
              this.$router.push(`/`);
           },
           next(productNumber) {
                if (![1, 2, 3, 4,6].includes(productNumber)) {
                  throw new Error('参数必须是1、2、3或4');
                }

                 this.$router.push(`/chanpin${productNumber}`);
              },  
          changshow(val) {
                  // if(this.gksr==1)
                  //   {
                  //     this.gksr=0,
                  //     this.zbsr=0,
                  //     this.zbsr2=0
                  //   }
                  //   else
                  //   {
                  //     this.gksr=1,
                  //     this.zbsr=1,
                  //     this.zbsr2=1
                  //   }
          },
    },
  };
</script>

<style scoped lang="scss">
	table {
	    width: 100%;
	    border-collapse: collapse;
	    background-color: #fff7d9; /* Light Yellow */
	    border: 2px solid white;
	}
	
	th, td {
	    border: 2px solid white;
	    padding: 8px;
	    text-align: left;
	}
	th, td:nth-child(1) {
	    text-align: center; /* Center alignment for the header and the first column */
	}
	th {
	    background-color: #ffe066; /* Orange */
		border-top-left-radius: 1rem;  /* 5px radius for the top-left corner */
		border-top-right-radius:1rem; /* 5px radius for the top-right corner */
	}
	
	/* Widths */
	td:nth-child(1) {
	    width: 16.66%; /* 1 part */
	}
	
	td:nth-child(2),
	td:nth-child(3),
	td:nth-child(4) {
	    width: 33.33%; /* 2 parts */
	}
	.pos_777 {
		    position: absolute;
		    right: 31.13rem;
		    bottom: 40rem;
	}
 input {
     width: 7rem;
     padding-left:2rem;
     border: none;  /* Removes the border */
     background: transparent;  /* Makes the background transparent */
     outline: none;
     box-sizing: border-box;
 }
	.font_2 {
	  font-size: 0.88rem;
	  font-family: Adobe Heiti Std;
	  line-height: 0.88rem;
	  color: #a3a3a3;
	}
	.section_bg6 {
	  padding: 0.56rem 1.63rem 1.56rem;
	  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/6303769ee16d040011538d1b/16920317083267902563.png');
	  background-size: 100% 100%;
	  background-repeat: no-repeat;
	  width: 20.94rem;
	}
	.pos__bg10 {
	  position: absolute;
	  right: 7.94rem;
	  top: 0;
	}
	.group_bg11 {
	  margin-top: 1.13rem;
	  padding-left: 0.94rem;
	  padding-right: 0.5rem;
	}
	.space-x-bg36 > *:not(:first-child) {
	  margin-left: 2.25rem;
	}
	.text_bg11 {
	  text-transform: uppercase;
	  opacity: 0.34;
	}
	.text_bg12 {
	  line-height: 0.81rem;
	}
	.group_bg13 {
	  margin-top: 1.06rem;
	  padding-left: 1rem;
	  padding-right: 0.44rem;
	}
	.space-x-bg35 > *:not(:first-child) {
	  margin-left: 2.19rem;
	}
	.text_bg13 {
	  line-height: 0.81rem;
	}
	.group_bg14 {
	  margin-top: 1.06rem;
	}
	.space-x-bg18 > *:not(:first-child) {
	  margin-left: 1.13rem;
	}
	.text_bg17 {
	  margin-right: 0.44rem;
	  line-height: 0.81rem;
	}
	.group_bg15 {
	  margin-top: 1.06rem;
	  padding: 0 0.5rem;
	}
	.space-x-bg28 > *:not(:first-child) {
	  margin-left: 1.75rem;
	}
	.text_bg18 {
	  line-height: 0.81rem;
	}
	.group_bg16 {
	  margin-top: 1.13rem;
	  padding-left: 1.06rem;
	  padding-right: 0.44rem;
	}
	.space-x-bg34 > *:not(:first-child) {
	  margin-left: 2.13rem;
	}
	.text_bg20 {
	  line-height: 0.81rem;
	}
	.group_bg17 {
	  margin-top: 1.5rem;
	}
	.group_bg18 {
	  width: 6.94rem;
	}
	.image_6 {
	  width: 5.5rem;
	  height: 4.88rem;
	}
	.font_6 {
	  font-size: 0.5rem;
	  font-family: HiraginoSansGB;
	  line-height: 0.5rem;
	  color: #3d3d3d;
	}
	.text_bg21 {
	  text-transform: uppercase;
	  opacity: 0.73;
	}
	.pos__bg14 {
	  position: absolute;
	  left: 0;
	  top: 0.75rem;
	}
	.group_bg19 {
	  width: 11.94rem;
	}
	.space-y-31 > *:not(:first-child) {
	  margin-top: 1.94rem;
	}
	.pos__bg15 {
	  position: absolute;
	  right: 2.38rem;
	  top: 1.56rem;
	}
	.text_bg2 {
	  text-transform: uppercase;
	}
	.text_bg10 {
	  color: #535353;
	  font-size: 1rem;
	  font-family: Adobe Heiti Std;
	  line-height: 1rem;
	}
  .page {
    background-color: #f1f1f1;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    height: 100%;
    .header {
      padding-bottom: 1.63rem;
      height: 5.88rem;
      .section_2 {
        padding: 0.31rem 3.69rem 0.94rem;
        background-color: #df8d34;
        .text {
          margin-left: 88.75rem;
          color: #fcfcfc;
          font-size: 1.06rem;
          font-family: Adobe Heiti Std;
          line-height: 1rem;
          text-transform: uppercase;
          opacity: 0.93;
        }
        .image {
          width: 3rem;
          height: 3rem;
        }
      }
      .space-x-48 {
        & > *:not(:first-child) {
          margin-left: 3rem;
        }
      }
      .section {
        padding: 1.5rem 3.38rem 1.75rem;
        background-color: #164683;
        border-radius: 0px 2.5rem 0px 0px;
        width: 21.63rem;
        .image_2 {
          width: 3.19rem;
          height: 0.81rem;
        }
        .image_3 {
          width: 8.38rem;
          height: 1.63rem;
        }
      }
      .space-y-3 {
        & > *:not(:first-child) {
          margin-top: 0.19rem;
        }
      }
      .pos {
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    .group {
      overflow-y: auto;
      .section_3 {
        padding: 3rem 0 3.88rem 0.75rem;
        background-color: #164683;
        border-radius: 0px 0px 2.5rem 0px;
        width: 21.63rem;
        height: 61.63rem;
        .group_3 {
          padding: 0 3.75rem 2.44rem;
          border-bottom: solid 0.25rem #575b66;
          .image_4 {
            width: 1.5rem;
            height: 1.5rem;
          }
          .text_3 {
            margin-right: 1.31rem;
            color: #ffffff;
            font-size: 1.44rem;
            font-family: Adobe Heiti Std;
            line-height: 1.44rem;
            letter-spacing: 0.072rem;
          }
        }
        .space-x-24 {
          & > *:not(:first-child) {
            margin-left: 1.5rem;
          }
        }
        .group_4 {
          margin-top: 3.56rem;
          .section_6 {
            opacity: 0.95;
            background-color: #e9e9e9;
            border-radius: 0.94rem;
            width: 15.63rem;
            height: 2.94rem;
            border: solid 0.19rem #df8d34;
          }
          .pos_4 {
            position: absolute;
            left: 3.75rem;
            top: 50%;
            transform: translateY(-50%);
          }
		  .pos_44 {
		    position: absolute;
		    top: 50%;
		    transform: translateY(-50%);
		  }
        }
        .text_2 {
          text-transform: uppercase;
          opacity: 0.8;
		  font-size: 1.1rem;
        }
        .group_5 {
			font-size: 1.1rem;
          margin-top: 2.88rem;
          padding: 0 2.38rem;
          .text-wrapper_2 {
            margin-right: 0.5rem;
            padding: 1rem 0;
            background-color: #fcfcfc91;
            border-radius: 0.94rem;
            width: 15.63rem;
            .text_6 {
              line-height: 0.94rem;
              text-transform: uppercase;
              opacity: 0.84;
            }
          }
          .image-wrapper {
            margin-right: 0.5rem;
            padding: 1rem 0;
            background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882057507515801764.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            .image_7 {
              width: 11.5rem;
              height: 1rem;
            }
          }
          .button {
            margin-right: 0.5rem;
            padding: 1rem 0;
            background-color: #fcfcfc80;
            border-radius: 0.94rem;
            .image_8 {
              width: 10.5rem;
              height: 1rem;
            }
          }
          .button_3 {
            margin-right: 0.5rem;
            padding: 1rem 0;
            background-color: #fcfcfc80;
            border-radius: 0.94rem;
            width: 15.63rem;
            .text_13 {
              color: #434343;
            }
          }
        }
        .space-y-46 {
          & > *:not(:first-child) {
            margin-top: 2.88rem;
          }
        }
        .font_2 {
          font-size: 0.94rem;
          font-family: Adobe Heiti Std;
          line-height: 1rem;
          color: #2d2d2d;
        }
        .text_16 {
          margin-top: 19.81rem;
          color: #fcfcfc;
          font-size: 1.13rem;
          font-family: Adobe Heiti Std;
          line-height: 1.06rem;
          text-transform: uppercase;
          opacity: 0.79;
        }
      }
      .group_2 {
        padding-top: 28.44rem;
        .image-wrapper_2 {
          padding: 6rem 0 20rem;
          background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882057507331846491.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          .image_9 {
            margin-right: 43.88rem;
            width: 2rem;
            height: 5rem;
          }
        }
        .section_4 {
          padding: 2rem 3.19rem 2.81rem;
          background-color: #4949493d;
          border-radius: 0.5rem;
          width: 19.56rem;
          .image_5 {
            width: 9.25rem;
            height: 1.19rem;
          }
          .text-wrapper {
            margin-top: 1rem;
            padding: 0.5rem 0;
            background-color: #fcfcfc7d;
            border-radius: 0.5rem;
            width: 13.19rem;
            .font_1 {
              font-size: 0.81rem;
              font-family: Adobe Heiti Std;
              line-height: 1.13rem;
              color: #3d3d3d;
            }
            .text_4 {
              margin-left: 1.56rem;
              text-transform: uppercase;
              opacity: 0.34;
            }
            .text_5 {
              margin-left: 1.63rem;
            }
          }
          .view {
            margin-top: 0;
          }
          .group_6 {
            margin-top: 3.31rem;
            padding-left: 1.13rem;
            .group_7 {
              width: 8.38rem;
              .image_6 {
                width: 7.56rem;
                height: 6.69rem;
              }
              .pos_5 {
                position: absolute;
                left: 0;
                top: 0.63rem;
              }
            }
            .text_8 {
              margin-top: 2.25rem;
            }
          }
          .space-x-14 {
            & > *:not(:first-child) {
              margin-left: 0.88rem;
            }
          }
          .font_3 {
            font-size: 0.69rem;
            font-family: Adobe Heiti Std;
            line-height: 0.69rem;
            color: #3d3d3d;
          }
          .text_7 {
            text-transform: uppercase;
            opacity: 0.73;
          }
          .text_11 {
            margin-top: 0.38rem;
          }
          .button_2 {
            margin-top: 2.5rem;
            padding: 0.75rem 0;
            background-color: #df8d34;
            border-radius: 0.5rem;
            width: 10.81rem;
            .text_12 {
              color: #ffffff;
              font-size: 1.56rem;
              font-family: Adobe Heiti Std;
              line-height: 1.63rem;
            }
          }
        }
        .space-y-37 {
          & > *:not(:first-child) {
            margin-top: 2.31rem;
          }
        }
        .pos_2 {
          position: absolute;
          right: 6.13rem;
          top: 12rem;
        }
        .section_5 {
          padding: 15.25rem 4.56rem 8rem;
          background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882057513498483953.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          width: 61.88rem;
          .text-wrapper_3 {
            padding: 0.63rem 0 0.88rem;
            background-color: #f7f7f7;
            border-radius: 0.5rem;
            width: 13.19rem;
            border: solid 0.19rem #cb8234;
            .text_10 {
              color: #3b2a2a;
            }
          }
          .text-wrapper_4 {
            padding: 0.63rem 0 0.81rem;
            background-color: #f7f7f7;
            border-radius: 0.5rem;
            width: 13.19rem;
            border: solid 0.19rem #cb8234;
            .text_14 {
              color: #292e35;
            }
          }
        }
        .space-y-178 {
          & > *:not(:first-child) {
            margin-top: 11.13rem;
          }
        }
        .pos_3 {
          position: absolute;
          left: 16rem;
          top: 16rem;
        }
        .text-wrapper_5 {
          padding: 0.63rem 0 0.88rem;
          background-color: #f7f7f7;
          border-radius: 0.5rem;
          width: 13.13rem;
          border: solid 0.19rem #cb8234;
          .text_15 {
            color: #433232;
          }
        }
        .pos_6 {
          position: absolute;
          left: 27.19rem;
          top: 42.25rem;
        }
        .font_4 {
          font-size: 1.38rem;
          font-family: Adobe Heiti Std;
          line-height: 1.31rem;
        }
      }
      .text_9 {
        text-transform: uppercase;
      }
    }
  }
  input::placeholder {
    color: #000000;
    font-size: 1.2em;
  }
  .unit {
    display: inline-block;
  }

  .van-tabbar-item {
    /* Adjust these to change the style of the tabs */
    width: 150px;
    text-align: center;
    border-radius: 10px 10px 0 0;
    border: 2px solid orange;
    font-size: 1.2rem;
  }
  
  .van-tabbar-item.active {
    color: #164683;
    border-bottom: none;
    font-weight: 300;
  }
</style>
