export const Calculations22 = {
    readGeometry(extension_length, angle_bar, angle_tension_bar, diameters) {
        const tand = angle => Math.tan(angle * Math.PI / 180);
        const E = [extension_length, 0, 0];
        const C = [0, extension_length * tand(angle_bar / 2), 0];
        const D = [0, -extension_length * tand(angle_bar / 2), 0];
        const B = [0, extension_length * tand(angle_bar / 2), -extension_length * tand(angle_tension_bar)];
        const A = [0, -extension_length * tand(angle_bar / 2), -extension_length * tand(angle_tension_bar)];

        const Tension_D = diameters[0];
        const Bar_D = diameters.slice(1);

        return {
            B8: A[0], C8: A[1], D8: A[2],
            B9: B[0], C9: B[1], D9: B[2],
            B10: C[0], C10: C[1], D10: C[2],
            B11: D[0], C11: D[1], D11: D[2],
            B12: E[0], C12: E[1], D12: E[2],
            A6: Tension_D, B6: Bar_D[0], C6: Bar_D[1]
        };
    },

    calculateExcelfromAngle(extension_length, angle_bar, angle_tension_bar, diameters,load) {
        extension_length = Number(extension_length);
        angle_bar = Number(angle_bar);
        angle_tension_bar = Number(angle_tension_bar);
        
        if (isNaN(extension_length) || isNaN(angle_bar) || isNaN(angle_tension_bar)) {
            throw new Error('Numeric conversion error in single value parameters.');
        }
        
        diameters = diameters.map(Number);
        load = load.map(Number);
        
        if (diameters.some(isNaN) || load.some(isNaN)) {
            throw new Error('Numeric conversion error in array elements.');
        }

        const geometry = this.readGeometry(extension_length, angle_bar, angle_tension_bar, diameters);
        const material = { B14: 50, A14: 50 }; // Modulus of Elasticity in GPa
        return this.calculateExcel(geometry, material, load);
    },

    calculateExcel(geometry, material, load) {
        const { B8, C8, D8, B9, C9, D9, B10, C10, D10, B11, C11, D11, B12, C12, D12, A6, B6, C6 } = geometry;
        const [A16, B16, C16] = load;
        const { B14, A14 } = material;

        const pi = Math.PI, sqrt = Math.sqrt, pow = Math.pow;
        
        const midPoints = this.calculateMidpoints(B8, C8, D8, B9, C9, D9, B10, C10, D10, B11, C11, D11);
        const { F9, G9, H9, I9, J9, K9 } = midPoints;

        const G11 = this.calculateGValue(F9, G9, H9, I9, J9, K9, B12, C12, D12);
        const F11 = sqrt(1 - pow(G11, 2));
        const H11 = F11 / G11;

        const areas = this.calculateAreas(A6, B6, C6);
        const { F15, G15, H15 } = areas;

        const stresses = this.calculateStresses(A14, B14, F15, G15, H15, A16, B16, C16, F11, G11, H11);
        const { maxStress, maxForce, maxBending } = stresses;

        return {
            GFRP_maxstress: maxStress,
            GFRP_maxforce: maxForce,
            GFRP_maxbending: maxBending
        };
    },

    calculateMidpoints(B8, C8, D8, B9, C9, D9, B10, C10, D10, B11, C11, D11) {
        const F9 = (B8 + B9) / 2;
        const G9 = (C8 + C9) / 2;
        const H9 = (D8 + D9) / 2;
        const I9 = (B10 + B11) / 2;
        const J9 = (C10 + C11) / 2;
        const K9 = (D10 + D11) / 2;

        return { F9, G9, H9, I9, J9, K9 };
    },

    calculateGValue(F9, G9, H9, I9, J9, K9, B12, C12, D12) {
        const pow = Math.pow, sqrt = Math.sqrt;
        return (pow(F9 - B12, 2) + pow(G9 - C12, 2) + pow(H9 - D12, 2) + pow(I9 - B12, 2) + pow(J9 - C12, 2) + pow(K9 - D12, 2) - (pow(F9 - I9, 2) + pow(G9 - J9, 2) + pow(H9 - K9, 2))) / (2 * sqrt(pow(F9 - B12, 2) + pow(G9 - C12, 2) + pow(H9 - D12, 2)) * sqrt(pow(I9 - B12, 2) + pow(J9 - C12, 2) + pow(K9 - D12, 2)));
    },

    calculateAreas(A6, B6, C6) {
        const pi = Math.PI;
        const F15 = 0.25 * pi * A6 ** 2; // Tension rod area
        const G15 = 0.25 * pi * (B6 ** 2 - C6 ** 2); // Annular area of bars
        const H15 = pi * (B6 ** 4 - C6 ** 4) / 64; // Polar moment of inertia

        return { F15, G15, H15 };
    },

    calculateStresses(A14, B14, F15, G15, H15, A16, B16, C16, F11, G11, H11) {
        const maxStress = F11 * 1000; // Placeholder for actual stress calculation
        const maxForce = Math.max(Math.abs(A16), Math.abs(B16), Math.abs(C16)) * 1000; // Placeholder for force calculation
        const maxBending = G11 * 1000; // Placeholder for bending moment calculation

        return { maxStress, maxForce, maxBending };
    }
};
