<template>
  <div class="flex-col page">
    <div class="flex-col justify-start items-end relative header">
      <div class="flex-row items-center section_2 space-x-48">
        <span class="self-start text" style="margin-top:1.2rem;" @click="loginout">
          {{ userinfo.username }}，{{ lang == 'en' ? 'Hello (Click to logout)' : '你好（点击退出）' }}
        </span>
        <img
          class="shrink-0 image"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882057507383748530.png"
        />
      </div>
      <!-- <div class="flex-row justify-end section_2 space-x-43-reverse pos_2" style="top:50px;">
           <van-tabbar v-model="activeTab" style="top:4.3rem;width:98.4rem;left:21.6rem;position:absolute;">
               <van-tabbar-item v-for="(tab, index) in tabs" :key="index" :class="activeTab === index ? 'active' : ''">
               <input type="checkbox" :value="index"> {{ tab }}
               </van-tabbar-item>
             </van-tabbar>
      </div> -->

      <div class="flex-col items-start section space-y-3 pos">
        <img
          class="image_2"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882057507649290089.png"
        />
        <img
          class="image_3"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/638b18425a7e3f03100a62af/649947d8726ab50011f379b8/50306348c9b05809c4d214e0868b960e.png"
        />
      </div>
    </div>
    <div class="flex-row flex-auto group" style="overflow-x:hidden">
      <div class="flex-col shrink-0 section_3">
        <div class="flex-row items-center group_3 space-x-24">
          <img
            class="shrink-0 image_4"
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882057507488602865.png"
          />
          <span class="text_2 text_3" :style='lang === "en"?"font-size:1.2rem;":""' >{{ lang == 'en' ? 'Design workflow' : '设计要素流程' }}</span>
        </div>

        <div class="flex-col group_5 space-y-46">
          <div class="flex-col justify-start items-center text-wrapper_2">
            <div class="section_6"></div>
            <span class="font_2 text_6" style="font-size:1rem;" @click="next(1)">A1 {{ lang == 'en' ? 'Geom' : '结构-几何输入' }}</span>
          </div>
          <div class="flex-col justify-start items-center relative group_4">
            <div class="section_6" style="background-color: rgba(252, 252, 252, 0.568627451);border:none"></div>
            <span class="font_2 text_2 pos_4" @click="next(2)" style="font-size:1rem;">B1 {{ lang == 'en' ? 'Loads' : '工况输入' }}</span>
          </div>
          <div class="flex-col justify-start items-center relative group_4">
            <div class="section_6" style="background-color: rgba(252, 252, 252, 0.568627451);border:none"></div>
            <span class="font_2 text_2 pos_44" style="font-size:1rem;" @click="next(6)">B2 {{ lang == 'en' ? 'Comp&Results' : '选项计算及结果' }}</span>
          </div>
          <div class="flex-col justify-start items-center image-wrapper">
            <div class="section_6"></div>
            <span class="font_2 text_2 pos_4" @click="next(3)" style="font-size:1rem;">C1 {{ lang == 'en' ? 'Visualization' : '结构设计性能的可视化' }}</span>
          </div>
          <div class="flex-col justify-start items-center group_4">
            <div class="section_6"></div>
            <span class="font_2 text_2 pos_4444" @click="next(4)" style="font-size:1rem;">C2 {{ lang == 'en' ? 'Summary' : '结构设计性能的总结' }}</span>
          </div>
        </div>
        
        <span class="self-center text_16"></span>
      </div>
      <div class="flex-col section_33 space-y-82">
        <div class="flex-col space-y-8">
          <div class="flex-row items-start">
            <div class="flex-col group_4">
              <span class="self-start text_2 text_7">{{ lang == 'en' ? 'Summary' : '结构设计性能的总结' }}</span>
              <div class="flex-col group_6 space-y-14">
                <div class="flex-row items-center space-x-23">
                  <img
                    class="image_4"
                    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882779348272205202.png"
                  />
                  <span class="font_3 text_2">
                    {{ lang == 'en' ? 'Diameter' : '管材规格' }}：{{ gcgg }}
                    <br />
                  </span>
                </div>
                <div class="flex-row items-center space-x-22">
                  <img
                    class="image_3"
                    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882686267794579177.png"
                  />
                  <span class="font_3 text_2">
                    {{ lang == 'en' ? 'Type' : '结构类型' }}：{{ jglx }}
                    <br />
                  </span>
                </div>
                <div class="flex-row items-center space-x-22">
                  <img
                    class="image_6"
                    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882779348342215589.png"
                  />
                  <span class="font_3 text_2">
                    {{ lang == 'en' ? 'Parameters' : '连接参数' }}：{{ ljcs }}
                    <br />
                  </span>
                </div>
              </div>
              <div class="divider"></div>
              <span class="self-start text_2 text_11">{{ lang == 'en' ? 'Property summary' : '校核结果' }}</span>
              <div class="flex-col group_9 space-y-14">
                <div class="flex-row items-center group_10 space-x-23">
                  <img
                    class="image_7"
                    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882686272253650946.png"
                  />
                  <span class="font_3 text_2">
                    {{ lang == 'en' ? 'Max stress cross bar' : '管件最大应力' }}：{{ zdyl }}
                    <br />
                  </span>
                </div>
                <div class="flex-row items-center group_10 space-x-23">
                  <img
                    class="image_8"
                    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882686272437802980.png"
                  />
                  <span class="font_3 text_2">
                    {{ lang == 'en' ? 'Max stress tension bar' : '拉杆最大应力' }}：{{ zdfh }}
                    <br />
                  </span>
                </div>
                <div class="flex-row items-center group_10 space-x-24">
                  <img
                    class="image_9"
                    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882779348493413403.png"
                  />
                  <span class="font_3 text_2">
                    {{ lang == 'en' ? 'Allowable strength' : '材料许用强度' }}：{{ xyqd }}
                    <br />
                  </span>
                </div>
              </div>
            </div>
            <div class="flex-col group_4">
              <div class="flex-col section_3_4 pos_3_2" style="mleft:-100px;border: 2px solid rgb(254, 197, 109);">
                <span class="self-center font_3_2 text_3_2" style="color:#f59e40;font-weight:800;font-size:0.8rem;margin-top:1rem;">{{ lang == 'en' ? 'Select the results for export' : '选择结构导出选型结果' }}</span>
                <div class="flex-col group_3_5 space-y-3-27">
                  <div class="flex-col space-y-3-12" style="margin-left:3rem;margin-top:1rem;">
                    <div class="flex-row justify-center relative group_3_7">
                      <img
                        class="image_3_3 pos_3_4" @click="echange(1)" v-if="eshow1"
                        src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/613ebd28630da20011c142a5/649ab291726ab50011f3c93e/235bf747b3cad9f88ab6bf2baf42adb8.png"
                      />
                      <div class="section_3_5 pos_3_8" @click="change(1)" v-if="show1"></div>
                      <span class="self-center font_3_2 text_3_2">{{ lang == 'en' ? '1B1T' : '单柱单拉' }}</span>
                    </div>
                    <div class="flex-row justify-center items-center relative group_3_7">
                      <img
                        class="image_3_3 pos_3_4" @click="echange(2)" v-if="eshow2"
                        src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/613ebd28630da20011c142a5/649ab291726ab50011f3c93e/235bf747b3cad9f88ab6bf2baf42adb8.png"
                      />
                      <div class="section_3_5 pos_3_8" @click="change(2)" v-if="show2"></div>
                      <span class="font_3_2 text_3_2">{{ lang == 'en' ? '1B2T' : '单柱双拉' }}</span>
                    </div>
                    <div class="flex-row justify-center items-center relative group_3_7">
                      <img
                        class="image_3_3 pos_3_4" @click="echange(3)" v-if="eshow3"
                        src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/613ebd28630da20011c142a5/649ab291726ab50011f3c93e/235bf747b3cad9f88ab6bf2baf42adb8.png"
                      />
                      <div class="section_3_5 pos_3_8" @click="change(3)" v-if="show3"></div>
                      <span class="font_3_2 text_3_2">{{ lang == 'en' ? '1B3T' : '单柱三拉' }}</span>
                    </div>
                    <div class="flex-row justify-center items-center relative group_3_7">
                      <img
                        class="image_3_3 pos_3_4" @click="echange(4)" v-if="eshow4"
                        src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/613ebd28630da20011c142a5/649ab291726ab50011f3c93e/235bf747b3cad9f88ab6bf2baf42adb8.png"
                      />
                      <div class="section_3_5 pos_3_8" @click="change(4)" v-if="show4"></div>
                      <span class="font_3_2 text_3_2">{{ lang == 'en' ? '2B1T' : '双柱单拉' }}</span>
                    </div>
                    <div class="flex-row justify-center items-center relative group_3_7">
                      <img
                        class="image_3_3 pos_3_4" @click="echange(5)" v-if="eshow5"
                        src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/613ebd28630da20011c142a5/649ab291726ab50011f3c93e/235bf747b3cad9f88ab6bf2baf42adb8.png"
                      />
                      <div class="section_3_5 pos_3_8" @click="change(5)" v-if="show5"></div>
                      <span class="font_3_2 text_3_2">{{ lang == 'en' ? '2B2T' : '双柱双拉' }}</span>
                    </div>
                  </div>
                </div>
                <div class="flex-col justify-start items-center button" @click="downloadCSVData" style="margin-left:3rem;">
                  <span class="text_3_2 text_3_8">{{ lang == 'en' ? 'Export' : '导出' }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-row justify-between">
            <div class="flex-row items-center self-center space-x-24">
              <img
                class="shrink-0 image_10"
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882779348547567612.png"
              />
              <span class="font_3 text_2">
                {{ lang == 'en' ? 'Used stability' : '失稳使用率' }}：{{ swzh }}
                <br />
              </span>
            </div>
          </div>
        </div>
        <div class="flex-row justify-center items-center self-start">
          <div class="flex-row items-center self-center text-wrapper_4" style="align-items: center;align-self: center;width:14rem;">
            <span class="text_2 text_15" @click="exportExcel" :style='lang === "en"?"font-size:1.2rem;margin-left:0.4rem;":"margin-left:0.5rem;"'>{{ lang == 'en' ? 'Power fitting validation' : '开展金具校核' }}</span>
          </div>
          <div class="flex-row items-center self-center text-wrapper_4" style="margin-left:20px;width:14rem;">
            <span class="text_2 text_15" @click="downloadCSVData" :style='lang === "en"?"font-size:1.2rem;margin-left:2.8rem;":"margin-left:2rem;"'>{{ lang == 'en' ? 'Export Results' : '导出结果' }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import * as XLSX from 'xlsx';
  import * as echarts from 'echarts'
  import { saveAs } from 'file-saver'
  
  export default {
    components: {},
    mounted() {
		//已知localStorage中可能有resultitem1，resultitem2，resultitem3，resultitem4。
		//checkedItems是，隔开的字符串，其代表选择的参数，例如，1在checkedItems分割后的数组里，就要读出localStorage的resultitem1的值，
		//如果checkedItems有多个值,就读出所有对应的值,并选出其中total_volume最小的.
		if(JSON.parse(localStorage.getItem(`userinfo`)))
		  {
			  this.userinfo=JSON.parse(localStorage.getItem(`userinfo`));
			  console.info(this.userinfo);
		  }
		  else
		  {
			  this.$router.push('/');
		  }
		let checkResults = this.processData(localStorage.getItem('checkedItems'));
		const dafeng = JSON.parse(localStorage.getItem('大风'));
		const duanxian = JSON.parse(localStorage.getItem('断线'));
		const sgyw = JSON.parse(localStorage.getItem('施工/运维'));
		const fdc = JSON.parse(localStorage.getItem('防串倒'));
		if(checkResults)
		  {
			  console.log("checkResults",checkResults);
			  const RScom = Number(checkResults.maxRScom);
			  const RSbending = Number(checkResults.maxRSbending);
			  this.checkResults=checkResults;
			  const strengthMap = {
			      18: 472,
			      24: 354,
			      28: 390,
			      30: 425,
			      34: 463,
			      40: 438
			    };
			  if(localStorage.getItem('lang') == 'en')
			  {
			  	this.gcgg= checkResults.mand_int_dia+"-"+checkResults.mand_ext_dia+"(mandrel)"+checkResults.rod_dia+"（rod)";
				this.xyqd=strengthMap[checkResults.rod_dia]+"(rod)/590(mandrel)";
			  }
			  else
			  {
				this.gcgg= checkResults.mand_int_dia+"-"+checkResults.mand_ext_dia+"(芯棒)"+checkResults.rod_dia+"（拉杆)";
				this.xyqd=strengthMap[checkResults.rod_dia]+"(拉杆)/590(芯棒)";  
			  }
			  this.ljcs= Number(JSON.parse(localStorage.getItem('胶装比')));
			  this.zdyl=Math.abs(checkResults.mand_max_s11).toFixed(2)+"MPa";
			  this.zdfh=Math.abs(checkResults.rod_max_s11).toFixed(2)+"MPa";
			 
			  
			  this.swzh=Number(checkResults.Trial).toFixed(2);
			  this.barDiameter.outer=checkResults.mand_int_dia;
			  this.barDiameter.inner=checkResults.mand_ext_dia;
			  this.tensionDiameter=checkResults.rod_dia;
			  this.loadNumber=4;
			  let loadValues=[...dafeng,...duanxian,...sgyw,...fdc];
			  this.loadValues=loadValues.map(item => Number(item) * 1000);
			  console.log("loadValues",this.loadValues);
		  }
     },
    data() {
      return {
		        lang: localStorage.getItem('lang') || 'cn',
		        userinfo: null,
		        checkResults:null,
		        extensionLength: 3900,
		        includedAngle: 13,
		        angleOfElevation: 26,
		        barDiameter: { outer: 75, inner: 59 },
		        tensionDiameter: 20,
		        loadNumber: 2,
		        loadValues: [4400, -49800, 25200, 8800, -49800, 25200],
		  tableData: [
		          { name: '管材规格', type: 1, jg: '单' },
		          { name: '结构类型', type: 2, jg: '单' },
		          { name: '连接参数', type: 3, jg: '双' },
		        ],
		  gcgg:"(空心管)（棒材)",
		  jglx:"",
		  ljcs:"",
		  zdyl:"",
		  zdfh:"",
		  xyqd:"",
		  swzh:"",
		  eshow1:0,
		  eshow2:0,
		  eshow3:0,
		  eshow4:0,
		  eshow5:0,
		  show1:1,
		  show2:1,
		  show3:1,
		  show4:1,
		  show5:1,
	  };
    },

    methods: {
		processData(checkedItems) {
		    const items = checkedItems.split(',').map(item => item.trim());  // Split the string and trim whitespace
		    let minVolumeData = null;
		    let minVolumeItem = null; 
		    items.forEach((item) => {
		        const key = `resultitem${item}`;
		        const dataString = localStorage.getItem(key);
		        if (dataString) {
		            const data = JSON.parse(dataString);
		            console.log(`Data for ${key}:`, data);
		            
		            // Initialize minVolumeData or update if current item's volume is smaller
		            if (minVolumeData === null || (data.total_volume < minVolumeData.total_volume)) {
		                minVolumeData = data;
						minVolumeItem = item; 
		            }
		        } else {
		            console.log(`No data found for ${key}`);
		        }
		    });
		   
		    if (minVolumeData !== null) {
				 const nameMapping = {
				            '1': '单柱单拉',
				            '2': '单柱三拉',
				            '3': '双柱单拉',
				            '4': '双柱双拉'
				        };
				const nameMappingen = {
				           '1': '1B1T',
				           '2': '1B3T',
				           '3': '2B1T',
				           '4': '2B2T'
				       };		
				this.jglx = localStorage.getItem('lang') == 'en'?nameMappingen[minVolumeItem]:nameMapping[minVolumeItem];
				return minVolumeData;
		        console.log("The data with the smallest total_volume:", minVolumeData);
		    } else {
		        console.log("No valid data was found in the checked items.");
		    }
		},
	exportToCSV() {
	    // 从localStorage获取'checkedItems'，它是一个逗号隔开的字符串
	    const checkedItemsStr = localStorage.getItem('checkedItems');
	    if (!checkedItemsStr) {
	        console.error('No checked items found in localStorage');
	        return '';
	    }
	    const checkedItems = checkedItemsStr.split(',');
	    console.log('Checked Items:', checkedItems);
	
	    let checkResults = {};
	    checkedItems.forEach(item => {
	        const resultDataKey = 'resultitem' + item;
	        const dataStr = localStorage.getItem(resultDataKey);
	        if (dataStr) {
	            checkResults[item] = JSON.parse(dataStr);
	        } else {
	            console.warn('No data found for:', item);
	        }
	    });
	    console.log('Check Results:', checkResults);
	
	    const headers = [
	        { key: "id", title: "ID" },
	        { key: "rod_dia", title: "Tension bar diameter" },
	        { key: "mand_ext_dia", title: "Cross bar outer diameter" },
	        { key: "mand_int_dia", title: "Cross bar inner diameter" },
	        { key: "pro_len", title: "Extension length" },
	        { key: "clamp_ang", title: "Cross bar angle" },
	        { key: "elev_ang", title: "Tension-cross bar angle" },
	        { key: "op_param1", title: "Load-axial" },
	        { key: "op_param2", title: "Load-transverse" },
	        { key: "op_param3", title: "Load-vertical" },
	        { key: "rod_max_s11", title: "Tension bar max S11" },
	        { key: "mand_max_s11", title: "Cross bar max S11" },
	        { key: "rod_max_s22", title: "Tension bar max S22" },
	        { key: "mand_max_s22", title: "Cross bar max S22" },
	        { key: "Trial", title: "Tension bar ratio" },
	        { key: "tube_util", title: "Cross bar ratio" },
	        { key: "total_volume", title: "Volume" }
	    ];
	
	    // 初始化CSV内容
	    let csvContent = headers.map(header => header.title).join(",") + "\n";
	
	    // 遍历每个检查项，并为其子属性（gka, gkb, gkc, gkd）生成CSV行
		//gka, gkb, gkc, gkdy已經不存在，現在遍歷case1-case10，注意一定注意對應的不存在的問題
	    checkedItems.forEach(itemKey => {
	        if (checkResults[itemKey] && typeof checkResults[itemKey] === 'object') {
	            ['case1', 'case2', 'case3', 'case4', 'case5', 'case6', 'case7', 'case8', 'case9', 'case10'].forEach(subKey => {
	                if (checkResults[itemKey][subKey] && checkResults[itemKey][subKey][0] && typeof checkResults[itemKey][subKey][0] === 'object') {
	                    let row = headers.map(header => {
	                        // 确保所有字段都存在，不存在则置为空
	                        return '"' + (checkResults[itemKey][subKey][0][header.key] ?? "") + '"';
	                    }).join(",");
	                    csvContent += row + "\n";
	                } else {
	                    console.log(`Data missing or incorrect format for ${itemKey} -> ${subKey}`);
	                }
	            });
	        }
	    });
	
	    return csvContent;
	},

		    downloadCSV(csvData, filename = "data.csv") {
		      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
		      const url = URL.createObjectURL(blob);
		      const link = document.createElement("a");
		      link.href = url;
		      link.download = filename;
		      document.body.appendChild(link);
		      link.click();
		      document.body.removeChild(link);
		      URL.revokeObjectURL(url);
		    },
		    downloadCSVData() {
		      const csvData = this.exportToCSV();
		      this.downloadCSV(csvData, "exportedData.csv");
		    },
		 exportExcel() {
			 const pointAngleResult = JSON.parse(localStorage.getItem('pointAngleResult'));
			 if (pointAngleResult) {
			   this.extensionLength = pointAngleResult.length;
			   this.includedAngle = pointAngleResult.alfa;
			   this.angleOfElevation = pointAngleResult.beta;
			 }
		      // const wb = XLSX.utils.book_new()
		      // const ws = XLSX.utils.json_to_sheet(this.tableData)
		      // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
		      // const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' })
		      // try {
		      //   saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'output.xlsx')
		      // } catch (e) { if (typeof console !== 'undefined') console.log(e, wbout) }
			  
			        // 准备文本内容
			        let content = `
			  ***********
			  Extension length (mm)
			  ${this.extensionLength}
			  Included  angle(°）
			  ${this.includedAngle}
			  Angle of elevation (°）
			  ${this.angleOfElevation}
			  Bar diameter(outer,inner) (mm)
			  ${this.barDiameter.outer},${this.barDiameter.inner}
			  Tension diameter (mm)
			  ${this.tensionDiameter}
			  ************
			  Load number 
			  ${this.loadNumber}
			  Load (transverse longitudinal vertical) (N)
			  ${this.loadValues.join(',')}
			  ************`;
			  
			        // 创建 Blob
			        const blob = new Blob([content], { type: 'text/plain' });
			        const fileDownloadUrl = URL.createObjectURL(blob);
			  
			        // 创建一个链接并模拟点击以下载文件
			        const link = document.createElement('a');
			        link.href = fileDownloadUrl;
			        link.setAttribute('download', 'data.txt'); // 文件名
			        document.body.appendChild(link);
			        link.click();
			        document.body.removeChild(link);
			  
			        // 释放 URL 对象
			        URL.revokeObjectURL(fileDownloadUrl);
		    },
		echange(val) {
		 //    switch(val) {
		 //      case 1:
		 //        this.eshow1 = 0;
		 //        this.show1 = 1;
		 //        break;
		 //      case 2:
		 //        this.eshow2 = 0;
		 //        this.show2 = 1;
		 //        break;
		 //      case 3:
		 //        this.eshow3 = 0;
		 //        this.show3 = 1;
		 //        break;
			//   case 4:
			//     this.eshow4 = 0;
			//     this.show4 = 1;
			//     break;	
			// case 5:
			//   this.eshow5 = 0;
			//   this.show5 = 1;
			//   break;		
		 //      default:
		 //        // 无效的参数
		 //        console.log('无效的参数');
		 //    }
		  this["eshow"+val]=0;
		  this["show"+val]=1;
		  },
		change(val) {
		 //   switch(val) {
		 //     case 1:
		 //       this.eshow1 = 1;
		 //       this.eshow2 = 0;
		 //       this.eshow3 = 0;
			//    this.eshow4 = 0;
			//    this.eshow5 = 0;
		 //       this.show1 = 0;
		 //       this.show2 = 1;
		 //       this.show3 = 1;
			//    this.show4 = 1;
			//    this.show5 = 1;
		 //       break;
		 //     case 2:
		 //     this.eshow1 = 0;
		 //     this.eshow2 = 1;
		 //     this.eshow3 = 0;
		 //     this.eshow4 = 0;
		 //     this.eshow5 = 0;
		 //     this.show1 = 1;
		 //     this.show2 = 0;
		 //     this.show3 = 1;
		 //     this.show4 = 1;
		 //     this.show5 = 1;
		 //       break;
		 //     case 3:
		 //    this.eshow1 = 0;
		 //    this.eshow2 = 0;
		 //    this.eshow3 = 1;
		 //    this.eshow4 = 0;
		 //    this.eshow5 = 0;
		 //    this.show1 = 1;
		 //    this.show2 = 1;
		 //    this.show3 = 0;
		 //    this.show4 = 1;
		 //    this.show5 = 1;
		 //       break;
			// case 4:
			// this.eshow1 = 0;
			// this.eshow2 = 0;
			// this.eshow3 = 0;
			// this.eshow4 = 1;
			// this.eshow5 = 0;
			// this.show1 = 1;
			// this.show2 = 1;
			// this.show3 = 1;
			// this.show4 = 0;
			// this.show5 = 1;
			//    break;
			// case 5:
			// this.eshow1 = 0;
			// this.eshow2 = 0;
			// this.eshow3 = 0;
			// this.eshow4 = 0;
			// this.eshow5 = 1;
			// this.show1 = 1;
			// this.show2 = 1;
			// this.show3 = 1;
			// this.show4 = 1;
			// this.show5 = 0;
			//    break;	  
		 //     default:
		 //       // 无效的参数
		 //       console.log('无效的参数');
		 //   }
		 this["show"+val]=0;
		 this["eshow"+val]=1;
		 },
     loginout(){
     	localStorage.removeItem("userinfo");
        this.$router.push(`/`);
     },
      next(productNumber) {
           if (![1, 2, 3, 4,6].includes(productNumber)) {
             throw new Error('参数必须是1、2、3或4');
           }
            this.$router.push(`/chanpin${productNumber}`);
         },
      daochu(){
      }
    },
  };
</script>

<style scoped lang="scss">
	.section_3_4 {
	  padding: 0;
	  background-color: #fff0db;
	  border-radius: 0.5rem;
	  width: 14.13rem;
	
	}
	.pos_3_2 {
	  position: absolute;
	  right: 15rem;
	  top: 20rem;
	}
	.group_3_5 {
	  padding: 0.94rem 0 1.94rem;
	}
	.space-y-3-27 > *:not(:first-child) {
	  margin-top: 1.69rem;
	}
	.font_3_2 {
	  font-size: 0.88rem;
	  font-family: FZLanTingHei-DB-GBK;
	  line-height: 0.88rem;
	  color: #a3a3a3;
	}
	.text_3_2 {
	  text-transform: uppercase;
	}
	.space-y-3-12 > *:not(:first-child) {
	  margin-top: 0.75rem;
	}
	.group_3_7 {
	  padding: 0.38rem 0;
	}
	.image_3_3 {
	  border-radius: 0.5rem;
	  width: 1.63rem;
	  height: 1.56rem;
	}
	.pos_3_4 {
	  position: absolute;
	  left: 0;
	  top: 50%;
	  transform: translateY(-50%);
	}
	.section_3_5 {
	  background-color: #2222227d;
	  border-radius: 0.5rem;
	  width: 1.63rem;
	  height: 1.56rem;
	}
	.pos_3_6 {
	  position: absolute;
	  left: 0;
	  top: 50%;
	  transform: translateY(-50%);
	}
	.pos_3_8 {
	  position: absolute;
	  left: 0;
	  top: 50%;
	  transform: translateY(-50%);
	}
	.button {
	  margin: 0 0.88rem;
	  padding: 0.25rem 0;
	  background-color: #cb8234;
	  border-radius: 0.5rem;
	  width: 7.88rem;
	  height: 2.13rem;
	  border: solid 0.19rem #cb8234;
	}
	.text_3_8 {
	  color: #2f2e2f;
	  font-size: 1.13rem;
	  font-family: FZLanTingHei-DB-GBK;
	  line-height: 1.19rem;
	}
	.section_3_4 {
	  background-color: #fff0db;
	  border-radius: 0.5rem;
	  width: 14.13rem;
	  padding-bottom: 1rem;
	
	}
	.group_c_2 {
	  margin-top: 4.75rem;
	  padding: 0 3.75rem 2.44rem;
	  border-bottom: solid 0.25rem #575b66;
	  .text_6 {
	    margin-right: 1.19rem;
	     color: #000000;
	    font-size: 1.44rem;
	    font-family: FZLTZHK;
	    line-height: 1.56rem;
	    letter-spacing: 0.072rem;
	  }
	}
	table {
	    width: 100%;
	    border-collapse: collapse;
	    background-color: #fff7d9; /* Light Yellow */
	    border: 2px solid white;
	}
	
	th, td {
	    border: 2px solid white;
	    padding: 8px;
	    text-align: left;
	}
	th, td:nth-child(1) {
	    text-align: center; /* Center alignment for the header and the first column */
	}
	th {
	    background-color: #ffe066; /* Orange */
		border-top-left-radius: 1rem;  /* 5px radius for the top-left corner */
		border-top-right-radius:1rem; /* 5px radius for the top-right corner */
	}
	
	/* Widths */
	td:nth-child(1) {
	    width: 16.66%; /* 1 part */
	}
	
	td:nth-child(2),
	td:nth-child(3),
	td:nth-child(4) {
	    width: 33.33%; /* 2 parts */
	}
	.pos_777 {
		    position: absolute;
		    right: 31.13rem;
		    bottom: 40rem;
	}
 input {
     width: 7rem;
     padding-left:2rem;
     border: none;  /* Removes the border */
     background: transparent;  /* Makes the background transparent */
     outline: none;
     box-sizing: border-box;
 }
	.font_2 {
	  font-size: 0.88rem;
	  font-family: Adobe Heiti Std;
	  line-height: 0.88rem;
	  color: #a3a3a3;
	}
	.section_bg6 {
	  padding: 0.56rem 1.63rem 1.56rem;
	  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/6303769ee16d040011538d1b/16920317083267902563.png');
	  background-size: 100% 100%;
	  background-repeat: no-repeat;
	  width: 20.94rem;
	}
	.pos__bg10 {
	  position: absolute;
	  right: 7.94rem;
	  top: 0;
	}
	.group_bg11 {
	  margin-top: 1.13rem;
	  padding-left: 0.94rem;
	  padding-right: 0.5rem;
	}
	.space-x-bg36 > *:not(:first-child) {
	  margin-left: 2.25rem;
	}
	.text_bg11 {
	  text-transform: uppercase;
	  opacity: 0.34;
	}
	.text_bg12 {
	  line-height: 0.81rem;
	}
	.group_bg13 {
	  margin-top: 1.06rem;
	  padding-left: 1rem;
	  padding-right: 0.44rem;
	}
	.space-x-bg35 > *:not(:first-child) {
	  margin-left: 2.19rem;
	}
	.text_bg13 {
	  line-height: 0.81rem;
	}
	.group_bg14 {
	  margin-top: 1.06rem;
	}
	.space-x-bg18 > *:not(:first-child) {
	  margin-left: 1.13rem;
	}
	.text_bg17 {
	  margin-right: 0.44rem;
	  line-height: 0.81rem;
	}
	.group_bg15 {
	  margin-top: 1.06rem;
	  padding: 0 0.5rem;
	}
	.space-x-bg28 > *:not(:first-child) {
	  margin-left: 1.75rem;
	}
	.text_bg18 {
	  line-height: 0.81rem;
	}
	.group_bg16 {
	  margin-top: 1.13rem;
	  padding-left: 1.06rem;
	  padding-right: 0.44rem;
	}
	.space-x-bg34 > *:not(:first-child) {
	  margin-left: 2.13rem;
	}
	.text_bg20 {
	  line-height: 0.81rem;
	}
	.group_bg17 {
	  margin-top: 1.5rem;
	}
	.group_bg18 {
	  width: 6.94rem;
	}
	.image_6 {
	  width: 5.5rem;
	  height: 4.88rem;
	}
	.font_6 {
	  font-size: 0.5rem;
	  font-family: HiraginoSansGB;
	  line-height: 0.5rem;
	  color: #3d3d3d;
	}
	.text_bg21 {
	  text-transform: uppercase;
	  opacity: 0.73;
	}
	.pos__bg14 {
	  position: absolute;
	  left: 0;
	  top: 0.75rem;
	}
	.group_bg19 {
	  width: 11.94rem;
	}
	.space-y-31 > *:not(:first-child) {
	  margin-top: 1.94rem;
	}
	.pos__bg15 {
	  position: absolute;
	  right: 2.38rem;
	  top: 1.56rem;
	}
	.text_bg2 {
	  text-transform: uppercase;
	}
	.text_bg10 {
	  color: #535353;
	  font-size: 1rem;
	  font-family: Adobe Heiti Std;
	  line-height: 1rem;
	}
  .page {
    background-color: #f1f1f1;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    height: 100%;
	.section_33 {
	  margin-left: 2.44rem;
	  padding: 9.13rem 12.38rem 8.69rem 28.75rem;
	  background-size: 100% 100%;
	  background-repeat: no-repeat;
	  .space-y-8 {
	    & > *:not(:first-child) {
	      margin-top: 0.5rem;
	    }
	    .group_4 {
	      margin-top: 1.44rem;
	      width: 30rem;
	  .section_3 {
	    padding: 0 3.13rem 2.38rem;
	    background-color: #494949;
	    border-radius: 0.5rem;
	    width: 19.69rem;
	    .group_4 {
	      padding: 1.31rem 0 2.75rem;
	      .font_2 {
	        font-size: 1.13rem;
	        font-family: FZLTZHK;
	        line-height: 1.19rem;
	        color: #a3a3a3;
	      }
	      .text_6 {
	        line-height: 1.13rem;
	      }
	      .space-y-16 {
	        & > *:not(:first-child) {
	          margin-top: 1rem;
	        }
	        .group_6 {
	          padding: 0.31rem 0 0.75rem;
	          .image_5 {
	            border-radius: 0.5rem;
	            width: 2.31rem;
	            height: 2.25rem;
	          }
	          .pos_5 {
	            position: absolute;
	            left: 0;
	            top: 50%;
	            transform: translateY(-50%);
	          }
	          .text_7 {
	            line-height: 1.13rem;
	          }
	        }
	        .group_8 {
	          padding: 0.5rem 0;
	          .section_4 {
	            background-color: #2222227d;
	            border-radius: 0.5rem;
	            width: 2.31rem;
	            height: 2.19rem;
	          }
	          .pos_7 {
	            position: absolute;
	            left: 0;
	            top: 50%;
	            transform: translateY(-50%);
	          }
	          .text_8 {
	            line-height: 1.13rem;
	          }
	          .pos_9 {
	            position: absolute;
	            left: 0;
	            top: 50%;
	            transform: translateY(-50%);
	          }
	        }
	      }
	    }
	    .space-y-37 {
	      & > *:not(:first-child) {
	        margin-top: 2.31rem;
	      }
	    }
	    .button {
	      margin: 0 1.25rem;
	      padding: 0.5rem 0;
	      background-color: #cb8234;
	      border-radius: 0.5rem;
	      width: 10.81rem;
	      border: solid 0.19rem #cb8234;
	      .text_11 {
	        color: #2f2e2f;
	        font-size: 1.56rem;
	        font-family: FZLTZHK;
	        line-height: 1.63rem;
	      }
	    }
	  }
	  
	  
	      .text_7 {
	        color: #000000;
	        font-size: 1.81rem;
	        font-family: FZLTZHK;
	        line-height: 1.69rem;
	      }
	      .group_6 {
	        margin-top: 4.5rem;
	        .space-x-22 {
	          & > *:not(:first-child) {
	            margin-left: 1.38rem;
	          }
	          .image_6 {
	            width: 1.31rem;
	            height: 1.31rem;
	          }
	        }
	      }
	      .space-y-14 {
	        & > *:not(:first-child) {
	          margin-top: 0.88rem;
	        }
	      }
	      .divider {
	        margin-top: 2.63rem;
	        background-color: #e0e0e061;
	        border-radius: 0.063rem;
	        height: 0.19rem;
	      }
	      .text_11 {
	        margin-top: 4.25rem;
	        color: #000000;
	        font-size: 1.75rem;
	        font-family: FZLTZHK;
	        line-height: 1.75rem;
	      }
	      .group_9 {
	        margin-top: 3.38rem;
	        .group_10 {
	          padding: 0 0.13rem;
	          .image_7 {
	            width: 1.44rem;
	            height: 1.5rem;
	          }
	          .image_8 {
	            width: 1.44rem;
	            height: 1.31rem;
	          }
	          .image_9 {
	            width: 1.31rem;
	            height: 1.19rem;
	          }
	        }
	      }
	      .space-x-23 {
	        & > *:not(:first-child) {
	          margin-left: 1.44rem;
	        }
	        .image_4 {
	          width: 1.38rem;
	          height: 1.38rem;
	        }
	      }
	    }
	    .group_8 {
	      margin-left: 7.06rem;
	      margin-top: 9.5rem;
	      .text_8 {
	        line-height: 1.25rem;
	      }
	    }
	    .space-y-236 {
	      & > *:not(:first-child) {
	        margin-top: 14.75rem;
	      }
	    }
	    .group_3 {
	      margin-left: 3.38rem;
	      width: 27.75rem;
	      .image-wrapper {
	        padding: 6.75rem 0 3.19rem;
	        background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882779348592060112.png');
	        background-size: 100% 100%;
	        background-repeat: no-repeat;
	        .image_5 {
	          width: 23.5rem;
	          height: 21.75rem;
	        }
	      }
	    }
	    .space-y-20 {
	      & > *:not(:first-child) {
	        margin-top: 1.25rem;
	      }
	    }
	    .group_7 {
	      margin-left: 1.38rem;
	      margin-top: 9.13rem;
	      .text_12 {
	        line-height: 1.25rem;
	      }
	    }
	    .space-y-245 {
	      & > *:not(:first-child) {
	        margin-top: 15.31rem;
	      }
	    }
	    .space-x-24 {
	      & > *:not(:first-child) {
	        margin-left: 1.5rem;
	      }
	      .image_10 {
	        width: 1.31rem;
	        height: 1.06rem;
	      }
	    }
	    .font_3 {
	      font-size: 1.38rem;
	      font-family: FZLTZHK;
	      line-height: 1.94rem;
	      color: #000000;
	    }
	    .text_14 {
	      margin-right: 17rem;
	      line-height: 1.25rem;
	    }
	  }
	  .text-wrapper_4 {
		  margin-top: 2rem;
	    margin-left:0rem;
	    padding: 0.69rem 0;
	    background-color: #f59e40;
	    border-radius: 0.5rem;
	    width: 10.75rem;
	    .text_15 {
	      color: #000000;
	      font-size: 1.56rem;
	      font-family: FZLTZHK;
	      line-height: 1.63rem;
	    }
	  }
	}
	
    .header {
      padding-bottom: 1.63rem;
      height: 5.88rem;
      .section_2 {
        padding: 0.31rem 3.69rem 0.94rem;
        background-color: #df8d34;
        .text {
          margin-left: 88.75rem;
          color: #fcfcfc;
          font-size: 1.06rem;
          font-family: Adobe Heiti Std;
          line-height: 1rem;
          text-transform: uppercase;
          opacity: 0.93;
        }
        .image {
          width: 3rem;
          height: 3rem;
        }
      }
      .space-x-48 {
        & > *:not(:first-child) {
          margin-left: 3rem;
        }
      }
      .section {
        padding: 1.5rem 3.38rem 1.75rem;
        background-color: #164683;
        border-radius: 0px 2.5rem 0px 0px;
        width: 21.63rem;
        .image_2 {
          width: 3.19rem;
          height: 0.81rem;
        }
        .image_3 {
          width: 8.38rem;
          height: 1.63rem;
        }
      }
      .space-y-3 {
        & > *:not(:first-child) {
          margin-top: 0.19rem;
        }
      }
      .pos {
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    .group {
      overflow-y: auto;
	  .section_c_3 {
	    padding:2rem 0.1rem 3rem 8rem;
	    .space-y-8 {
	      & > *:not(:first-child) {
	        margin-top: 0.5rem;
	      }
	      .group_4 {
	        margin-top: 1.44rem;
	        width: 30rem;
	    .section_3 {
	      padding: 0 3.13rem 2.38rem;
	      background-color: #494949;
	      border-radius: 0.5rem;
	      width: 19.69rem;
	      .group_4 {
	        padding: 1.31rem 0 2.75rem;
	        .font_2 {
	          font-size: 1.13rem;
	          font-family: FZLTZHK;
	          line-height: 1.19rem;
	          color: #a3a3a3;
	        }
	        .text_6 {
	          line-height: 1.13rem;
	        }
	        .space-y-16 {
	          & > *:not(:first-child) {
	            margin-top: 1rem;
	          }
	          .group_6 {
	            padding: 0.31rem 0 0.75rem;
	            .image_5 {
	              border-radius: 0.5rem;
	              width: 2.31rem;
	              height: 2.25rem;
	            }
	            .pos_5 {
	              position: absolute;
	              left: 0;
	              top: 50%;
	              transform: translateY(-50%);
	            }
	            .text_7 {
	              line-height: 1.13rem;
	            }
	          }
	          .group_8 {
	            padding: 0.5rem 0;
	            .section_4 {
	              background-color: #2222227d;
	              border-radius: 0.5rem;
	              width: 2.31rem;
	              height: 2.19rem;
	            }
	            .pos_7 {
	              position: absolute;
	              left: 0;
	              top: 50%;
	              transform: translateY(-50%);
	            }
	            .text_8 {
	              line-height: 1.13rem;
	            }
	            .pos_9 {
	              position: absolute;
	              left: 0;
	              top: 50%;
	              transform: translateY(-50%);
	            }
	          }
	        }
	      }
	      .space-y-37 {
	        & > *:not(:first-child) {
	          margin-top: 2.31rem;
	        }
	      }
	      .button {
	        margin: 0 1.25rem;
	        padding: 0.5rem 0;
	        background-color: #cb8234;
	        border-radius: 0.5rem;
	        width: 10.81rem;
	        border: solid 0.19rem #cb8234;
	        .text_11 {
	          color: #2f2e2f;
	          font-size: 1.56rem;
	          font-family: FZLTZHK;
	          line-height: 1.63rem;
	        }
	      }
	    }
	    
	    
	        .text_7 {
	          color: #000000;
	          font-size: 1.81rem;
	          font-family: FZLTZHK;
	          line-height: 1.69rem;
	        }
	        .group_6 {
	          margin-top: 4.5rem;
	          .space-x-22 {
	            & > *:not(:first-child) {
	              margin-left: 1.38rem;
	            }
	            .image_6 {
	              width: 1.31rem;
	              height: 1.31rem;
	            }
	          }
	        }
	        .space-y-14 {
	          & > *:not(:first-child) {
	            margin-top: 0.88rem;
	          }
	        }
	        .divider {
	          margin-top: 2.63rem;
	          background-color: #e0e0e061;
	          border-radius: 0.063rem;
	          height: 0.19rem;
	        }
	        .text_11 {
	          margin-top: 4.25rem;
	          color: #000000;
	          font-size: 1.75rem;
	          font-family: FZLTZHK;
	          line-height: 1.75rem;
	        }
	        .group_9 {
	          margin-top: 3.38rem;
	          .group_10 {
	            padding: 0 0.13rem;
	            .image_7 {
	              width: 1.44rem;
	              height: 1.5rem;
	            }
	            .image_8 {
	              width: 1.44rem;
	              height: 1.31rem;
	            }
	            .image_9 {
	              width: 1.31rem;
	              height: 1.19rem;
	            }
	          }
	        }
	        .space-x-23 {
	          & > *:not(:first-child) {
	            margin-left: 1.44rem;
	          }
	          .image_4 {
	            width: 1.38rem;
	            height: 1.38rem;
	          }
	        }
	      }
	      .group_8 {
	        margin-left: 7.06rem;
	        margin-top: 9.5rem;
	        .text_8 {
	          line-height: 1.25rem;
	        }
	      }
	      .space-y-236 {
	        & > *:not(:first-child) {
	          margin-top: 14.75rem;
	        }
	      }
	      .group_3 {
	        margin-left: 3.38rem;
	        width: 27.75rem;
	        .image-wrapper {
	          padding: 6.75rem 0 3.19rem;
	          background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882779348592060112.png');
	          background-size: 100% 100%;
	          background-repeat: no-repeat;
	          .image_5 {
	            width: 23.5rem;
	            height: 21.75rem;
	          }
	        }
	      }
	      .space-y-20 {
	        & > *:not(:first-child) {
	          margin-top: 1.25rem;
	        }
	      }
	      .group_7 {
	        margin-left: 1.38rem;
	        margin-top: 9.13rem;
	        .text_12 {
	          line-height: 1.25rem;
	        }
	      }
	      .space-y-245 {
	        & > *:not(:first-child) {
	          margin-top: 15.31rem;
	        }
	      }
	      .space-x-24 {
	        & > *:not(:first-child) {
	          margin-left: 1.5rem;
	        }
	        .image_10 {
	          width: 1.31rem;
	          height: 1.06rem;
	        }
	      }
	      .font_3 {
	        font-size: 1.38rem;
	        font-family: FZLTZHK;
	        line-height: 1.94rem;
	        color: #000000;
	      }
	      .text_14 {
	        margin-right: 17rem;
	        line-height: 1.25rem;
	      }
	    }
	    .text-wrapper_4 {
	      margin-left: 30rem;
	      padding: 0.69rem 0;
	      background-color: #f59e40;
	      border-radius: 0.5rem;
	      width: 10.75rem;
	      .text_15 {
	        color: #000000;
	        font-size: 1.56rem;
	        font-family: FZLTZHK;
	        line-height: 1.63rem;
	      }
	    }
	  }
	  
      .section_3 {
        padding: 3rem 0 3.88rem 0.75rem;
        background-color: #164683;
        border-radius: 0px 0px 2.5rem 0px;
        width: 21.63rem;
        height: 61.63rem;
        .group_3 {
          padding: 0 3.75rem 2.44rem;
          border-bottom: solid 0.25rem #575b66;
          .image_4 {
            width: 1.5rem;
            height: 1.5rem;
          }
          .text_3 {
            margin-right: 1.31rem;
            color: #ffffff;
            font-size: 1.44rem;
            font-family: Adobe Heiti Std;
            line-height: 1.44rem;
            letter-spacing: 0.072rem;
          }
        }
        .space-x-24 {
          & > *:not(:first-child) {
            margin-left: 1.5rem;
          }
        }
        .group_4 {
          margin-top: 3.56rem;
          .section_6 {
            opacity: 0.95;
            background-color: #e9e9e9;
            border-radius: 0.94rem;
            width: 15.63rem;
            height: 2.94rem;
            border: solid 0.19rem #df8d34;
          }
          .pos_4 {
            position: absolute;
            left: 3.75rem;
            top: 50%;
            transform: translateY(-50%);
          }
		  .pos_44 {
		    position: absolute;
		    top: 50%;
		    transform: translateY(-50%);
		  }
		  .pos_4444 {
		  //  position: absolute;
		   // top: 70%;
		   margin-top:-1.4rem;
		    transform: translateY(-50%);
		  }
        }
        .text_2 {
          text-transform: uppercase;
          opacity: 0.8;
		  font-size: 1.1rem;
        }
        .group_5 {
			font-size: 1.1rem;
          margin-top: 2.88rem;
          padding: 0 2.38rem;
          .text-wrapper_2 {
            margin-right: 0.5rem;
            padding: 1rem 0;
            background-color: #fcfcfc91;
            border-radius: 0.94rem;
            width: 15.63rem;
            .text_6 {
              line-height: 0.94rem;
              text-transform: uppercase;
              opacity: 0.84;
            }
          }
          .image-wrapper {
            margin-right: 0.5rem;
            padding: 1rem 0;
            background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882057507515801764.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            .image_7 {
              width: 11.5rem;
              height: 1rem;
            }
          }
          .button {
            margin-right: 0.5rem;
            padding: 1rem 0;
            background-color: #fcfcfc80;
            border-radius: 0.94rem;
            .image_8 {
              width: 10.5rem;
              height: 1rem;
            }
          }
          .button_3 {
            margin-right: 0.5rem;
            padding: 1rem 0;
            background-color: #fcfcfc80;
            border-radius: 0.94rem;
            width: 15.63rem;
            .text_13 {
              color: #434343;
            }
          }
        }
        .space-y-46 {
          & > *:not(:first-child) {
            margin-top: 2.88rem;
          }
        }
        .font_2 {
          font-size: 0.94rem;
          font-family: Adobe Heiti Std;
          line-height: 1rem;
          color: #2d2d2d;
        }
        .text_16 {
          margin-top: 19.81rem;
          color: #fcfcfc;
          font-size: 1.13rem;
          font-family: Adobe Heiti Std;
          line-height: 1.06rem;
          text-transform: uppercase;
          opacity: 0.79;
        }
      }
      .group_2 {
        padding-top: 28.44rem;
        .image-wrapper_2 {
          padding: 6rem 0 20rem;
          background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882057507331846491.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          .image_9 {
            margin-right: 43.88rem;
            width: 2rem;
            height: 5rem;
          }
        }
        .section_4 {
          padding: 2rem 3.19rem 2.81rem;
          background-color: #4949493d;
          border-radius: 0.5rem;
          width: 19.56rem;
          .image_5 {
            width: 9.25rem;
            height: 1.19rem;
          }
          .text-wrapper {
            margin-top: 1rem;
            padding: 0.5rem 0;
            background-color: #fcfcfc7d;
            border-radius: 0.5rem;
            width: 13.19rem;
            .font_1 {
              font-size: 0.81rem;
              font-family: Adobe Heiti Std;
              line-height: 1.13rem;
              color: #3d3d3d;
            }
            .text_4 {
              margin-left: 1.56rem;
              text-transform: uppercase;
              opacity: 0.34;
            }
            .text_5 {
              margin-left: 1.63rem;
            }
          }
          .view {
            margin-top: 0;
          }
          .group_6 {
            margin-top: 3.31rem;
            padding-left: 1.13rem;
            .group_7 {
              width: 8.38rem;
              .image_6 {
                width: 7.56rem;
                height: 6.69rem;
              }
              .pos_5 {
                position: absolute;
                left: 0;
                top: 0.63rem;
              }
            }
            .text_8 {
              margin-top: 2.25rem;
            }
          }
          .space-x-14 {
            & > *:not(:first-child) {
              margin-left: 0.88rem;
            }
          }
          .font_3 {
            font-size: 0.69rem;
            font-family: Adobe Heiti Std;
            line-height: 0.69rem;
            color: #3d3d3d;
          }
          .text_7 {
            text-transform: uppercase;
            opacity: 0.73;
          }
          .text_11 {
            margin-top: 0.38rem;
          }
          .button_2 {
            margin-top: 2.5rem;
            padding: 0.75rem 0;
            background-color: #df8d34;
            border-radius: 0.5rem;
            width: 10.81rem;
            .text_12 {
              color: #ffffff;
              font-size: 1.56rem;
              font-family: Adobe Heiti Std;
              line-height: 1.63rem;
            }
          }
        }
        .space-y-37 {
          & > *:not(:first-child) {
            margin-top: 2.31rem;
          }
        }
        .pos_2 {
          position: absolute;
          right: 6.13rem;
          top: 12rem;
        }
        .section_5 {
          padding: 15.25rem 4.56rem 8rem;
          background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882057513498483953.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          width: 61.88rem;
          .text-wrapper_3 {
            padding: 0.63rem 0 0.88rem;
            background-color: #f7f7f7;
            border-radius: 0.5rem;
            width: 13.19rem;
            border: solid 0.19rem #cb8234;
            .text_10 {
              color: #3b2a2a;
            }
          }
          .text-wrapper_4 {
            padding: 0.63rem 0 0.81rem;
            background-color: #f7f7f7;
            border-radius: 0.5rem;
            width: 13.19rem;
            border: solid 0.19rem #cb8234;
            .text_14 {
              color: #292e35;
            }
          }
        }
        .space-y-178 {
          & > *:not(:first-child) {
            margin-top: 11.13rem;
          }
        }
        .pos_3 {
          position: absolute;
          left: 16rem;
          top: 16rem;
        }
        .text-wrapper_5 {
          padding: 0.63rem 0 0.88rem;
          background-color: #f7f7f7;
          border-radius: 0.5rem;
          width: 13.13rem;
          border: solid 0.19rem #cb8234;
          .text_15 {
            color: #433232;
          }
        }
        .pos_6 {
          position: absolute;
          left: 27.19rem;
          top: 42.25rem;
        }
        .font_4 {
          font-size: 1.38rem;
          font-family: Adobe Heiti Std;
          line-height: 1.31rem;
        }
      }
      .text_9 {
        text-transform: uppercase;
      }
    }
  }
  input::placeholder {
    color: #000000;
    font-size: 1.2em;
  }
  .unit {
    display: inline-block;
  }

  .van-tabbar-item {
    /* Adjust these to change the style of the tabs */
    width: 150px;
    text-align: center;
    border-radius: 10px 10px 0 0;
    border: 2px solid orange;
    font-size: 1.2rem;
  }
  
  .van-tabbar-item.active {
    color: #164683;
    border-bottom: none;
    font-weight: 300;
  }
</style>