<template>
	<div class="flex-col page">
		<div class="flex-col justify-start items-end relative header">
			<div class="flex-row items-center section_2 space-x-48">
				<span class="self-start text" style="margin-top:1.2rem;"
					@click="loginout">{{userinfo?userinfo.nickname:''}}，你好 {{ lang === 'en' ? 'Hello (Click to logout)' : '你好（点击退出）' }}</span>
				<img class="shrink-0 image"
					src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882057507383748530.png" />
			</div>
			<div class="flex-row justify-end" style="top:50px;overflow: hidden;">
				<van-tabbar v-model="activeTab" style="top:4.5rem;width:80.6vw;left:18.2vw;position: absolute;">
					<van-tabbar-item v-for="(tab, index) in tabs" :key="index"
						:class="activeTab === index ? 'active' : ''">
						{{ tab }}
					</van-tabbar-item>
				</van-tabbar>
			</div>
			<!-- <div class="flex-row justify-end section_2 space-x-43-reverse pos_2" style="top:50px;">
	       <van-tabbar v-model="activeTab" style="top:4.3rem;width:98.4rem;left:21.6rem;position:absolute;">
	           <van-tabbar-item v-for="(tab, index) in tabs" :key="index" :class="activeTab === index ? 'active' : ''">
	           <input type="checkbox" :value="index"> {{ tab }}
	           </van-tabbar-item>
	         </van-tabbar>
	  </div> -->

			<div class="flex-col items-start section space-y-3 pos">
				<img class="image_2"
					src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882057507649290089.png" />
				<img class="image_3"
					src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/638b18425a7e3f03100a62af/649947d8726ab50011f379b8/50306348c9b05809c4d214e0868b960e.png" />
			</div>
		</div>
		<div class="flex-row flex-auto group" style="overflow-x:hidden">
			<div class="flex-col shrink-0 section_3">
				<div class="flex-row items-center group_3 space-x-24">
					<img class="shrink-0 image_4"
						src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882057507488602865.png" />
					<span class="text_2 text_3"  :style='lang === "en"?"font-size:1.2rem;":""' >{{ lang === 'en' ? 'Design workflow' : '设计要素流程' }}</span>
				</div>

				<div class="flex-col group_5 space-y-46">
					<div class="flex-col justify-start items-center text-wrapper_2">
						<div class="section_6"></div>
						<span class="font_2 text_6" style="font-size:1rem;" @click="next(1)">{{ lang === 'en' ? 'A1 Geom' : 'A1 结构-几何输入' }}</span>
					</div>
					<div class="flex-col justify-start items-center relative group_4">
						<div class="section_6" style="background-color: rgba(252, 252, 252, 0.568627451);border:none">
						</div>
						<span class="font_2 text_2 pos_4" @click="next(2)" style="font-size:1rem;">{{ lang === 'en' ? 'B1 Loads' : 'B1 工况输入' }}</span>
					</div>
					<div class="flex-col justify-start items-center relative group_4">
						<div class="section_6" style="background-color: rgba(252, 252, 252, 0.568627451);border:none">
						</div>
						<span class="font_2 text_2 pos_44" style="font-size:1rem;" @click="next(6)">{{ lang === 'en' ? 'B2 Comp&Results' : 'B2 选项计算及结果' }}</span>
					</div>
					<div class="flex-col justify-start items-center group_4">
						<div class="section_6"></div>
						<span class="font_2 text_2 pos_444" @click="next(3)" style="font-size:1rem;">{{ lang === 'en' ? 'C1 Visualization' : 'C1 结构设计性能的可视化' }}</span>
					</div>
					<div class="flex-col justify-start items-center button">
						<div class="section_6"></div>
						<span class="font_2 text_2 pos_4" @click="next(4)" style="font-size:1rem;">{{ lang === 'en' ? 'C2 Summary' : 'C2 结构设计性能的总结' }}</span>
					</div>
					<!-- <div class="flex-col justify-start items-center button_3">
            <span class="font_2 text_9 text_13" style="font-size:1.1rem;">完成流程</span>
          </div> -->
				</div>

				<span class="self-center text_16"></span>
			</div>
			<div class="flex-col justify-start flex-auto relative group_c_2">

				<div class="flex-col group_7 space-y-45" style="margin-left:20%;margin-top:-1%;">
					<div id="radar" ref="radar" style="width:40vw;height:40vw;"></div>
					<div style="position: relative;top:-1rem;left:2rem;">{{ lang === 'en' ? 'The displayed result is the most critical outcome among all operating conditions for the corresponding structure' : '所显示为对应结构所有工况中最危险结果' }}</div>
				</div>
			</div>
		</div>
	</div>
	</div>
</template>

<script>
	import * as echarts from 'echarts';
import { Switch } from 'vant';
	export default {
		mounted() {
			if (JSON.parse(localStorage.getItem(`userinfo`))) {
				this.userinfo = JSON.parse(localStorage.getItem(`userinfo`));
				console.info(this.userinfo);
			} else {
				this.$router.push('/');
			}
			this.setcharts(0);
		},
		components: {},
		//将下面的vue data设置中的中文，都按照localStorage.getItem('lang')的值设置中英文显示，其中
		//  单柱对应 1B,单柱单拉对应1B1T,单柱三拉对应1B3T,双柱单拉对应2B1T,双柱双拉对应2B2T，一定要保持原有代码结构不变，
        data() {
          return {
            lang: localStorage.getItem('lang') || 'cn',
            userinfo: null,
            checkResults: null,
            Resultsdata: null,
            resultitem5: null,
            resultitem4: null,
            resultitem3: null,
            resultitem2: null,
            resultitem1: null,
            staticForce: 0,
            stability: 0,
            fatigue: 0,
            creep: 0,
            connection: 0,
            volume: 1,
            activeTab: 0,
            showtab0: 1,
            showtab1: 0,
            showtab2: 0,
            showtab3: 0,
            showtab4: 0,
            showtab5: 1,
            imgstyle: [{
                "mt": 30,
                "w": 48,
                "h": 9.36
              },
              {
                "mt": 15,
                "w": 38,
                "h": 33.31
              },
              {
                "mt": 18,
                "w": 48,
                "h": 29.25
              },
              {
                "mt": 18,
                "w": 48,
                "h": 25.47
              },
              {
                "mt": 18,
                "w": 48,
                "h": 31.70
              }
            ],
            tabs: [
              localStorage.getItem('lang') == 'en' ? 'Comp' : '综合',
              localStorage.getItem('lang') == 'en' ? '1B' : '单柱',
              localStorage.getItem('lang') == 'en' ? '1B1T' : '单柱单拉',
              localStorage.getItem('lang') == 'en' ? '1B3T' : '单柱三拉',
              localStorage.getItem('lang') == 'en' ? '2B1T' : '双柱单拉',
              localStorage.getItem('lang') == 'en' ? '2B2T' : '双柱双拉'
            ],
            option: {
              title: {
                text: localStorage.getItem('lang') == 'en' ? 'Work Condition Data' : '工况数据',
                x: '1',
                y: '-2',
                textStyle: {
                  color: '#000000',
                  fontFamily: 'Microsoft YaHei',
                  fontSize: '2rem'
                }
              },
              color: [],
              tooltip: {
                trigger: 'item'
              },
              grid: {
                top: '30%'
              },
              legend: {
                show: true,
                padding: [3, 5],
                right: '0',
                y: '45',
                x: '55',
				left:20,
                bottom: 50,
                center: 0,
                itemWidth: 14,
                itemHeight: 14,
                itemGap: 3,
                orient: "horizontal",
                data: [
                  localStorage.getItem('lang') == 'en' ? '1B1T' : '单柱单拉',
                  localStorage.getItem('lang') == 'en' ? '1B3T' : '单柱三拉',
                  localStorage.getItem('lang') == 'en' ? '2B1T' : '双柱单拉',
                  localStorage.getItem('lang') == 'en' ? '2B2T' : '双柱双拉'
                ],
                textStyle: {
                  fontSize: '1.1rem',
                },
              },
              radar: {
                name: {
                  textStyle: {
                    color: '#000000',
                    borderRadius: 13,
                    padding: [2, 2],
                    fontSize: '1.3rem',
                  }
                },
                radius: '70%',
                center: ['50%', '55%'],
                splitLine: {
                  lineStyle: {
                    color: '#113865',
                    width: 1,
                    opacity: 0.5
                  }
                },
                indicator: [{
                    name: localStorage.getItem('lang') == 'en' ? 'Static Force' : '静力',
                    max: 100
                  },
                  {
                    name: localStorage.getItem('lang') == 'en' ? 'Stability' : '稳定性',
                    max: 100
                  },
                  {
                    name: localStorage.getItem('lang') == 'en' ? 'Fatigue' : '疲劳',
                    max: 100
                  },
                  {
                    name: localStorage.getItem('lang') == 'en' ? 'Creep' : '蠕变',
                    max: 100
                  },
                  {
                    name: localStorage.getItem('lang') == 'en' ? 'Connection' : '连接',
                    max: 100
                  },
                  {
                    name: localStorage.getItem('lang') == 'en' ? 'Volume' : '体积',
                    max: 100
                  }
                ],
              },
              series: [{
                name: '',
                type: 'radar',
                data: []
              }]
            },
            optiondata: {
              value: [5, 70, 90, 5, 64, 74],
              name: localStorage.getItem('lang') == 'en' ? '2B2T' : '双柱双拉',
              lineStyle: {
                normal: {
                  color: '#3A86FF',
                  width: 4
                }
              },
              areaStyle: {
                normal: {
                  color: '#3A86FF',
                  opacity: 0.5,
                },
              },
            },
            option1: {
              title: {
                text: localStorage.getItem('lang') == 'en' ? '1B Structure Data' : '单柱结构数据',
                // subtext: localStorage.getItem('lang') == 'en' ? 'Visual Display' : '可视化显示',
                x: '0',
                y: '0',
                textStyle: {
                  color: '#000000',
                  fontFamily: 'Microsoft YaHei',
                  fontSize: '2.5rem'
                }
              },
              color: ['#46B9B2', '#D372B2'],
              tooltip: {
                trigger: 'item'
              },
              grid: {
                top: '23%'
              },
              legend: {
                show: true,
                padding: [3, 5],
                right: '0',
                y: '45',
                x: '65',
                bottom: 40,
                center: 0,
                itemWidth: 14,
                itemHeight: 14,
                itemGap: 3,
                orient: "horizontal",
                data: [],
                textStyle: {
                  fontSize: '1.1rem',
                },
              },
              radar: {
                name: {
                  textStyle: {
                    color: '#000000',
                    borderRadius: 13,
                    padding: [5, 5],
                    fontSize: '1.3rem',
                  }
                },
                radius: '70%',
                center: ['50%', '55%'],
                splitLine: {
                  lineStyle: {
                    color: '#113865',
                    width: 1,
                    opacity: 0.5
                  }
                },
                indicator: [{
                    name: localStorage.getItem('lang') == 'en' ? 'Static Force' : '静力',
                    max: 110
                  },
                  {
                    name: localStorage.getItem('lang') == 'en' ? 'Stability' : '稳定性',
                    max: 110
                  },
                  {
                    name: localStorage.getItem('lang') == 'en' ? 'Fatigue' : '疲劳',
                    max: 110
                  },
                  {
                    name: localStorage.getItem('lang') == 'en' ? 'Creep' : '蠕变',
                    max: 110
                  },
                  {
                    name: localStorage.getItem('lang') == 'en' ? 'Connection' : '连接',
                    max: 110
                  },
                  {
                    name: localStorage.getItem('lang') == 'en' ? 'Volume' : '体积',
                    max: 100
                  }
                ],
              },
              series: [{
                name: '',
                type: 'radar',
                data: []
              }]
            },
			option2: {
			  title: {
			    text: localStorage.getItem('lang') == 'en' ? '1B1T Structure Data' : '单柱单拉结构数据',
			    // subtext: localStorage.getItem('lang') == 'en' ? 'Visual Display' : '可视化显示',
			    x: '0',
			    y: '0',
			    textStyle: {
			      color: '#000000',
			      fontFamily: 'Microsoft YaHei',
			      fontSize: '2.5rem'
			    }
			  },
			  color: ['#46B9B2', '#D372B2'],
			  tooltip: {
			    trigger: 'item'
			  },
			  grid: {
			    top: '23%'
			  },
			  legend: {
			    show: true,
			    padding: [3, 5],
			    right: '0',
			    y: '45',
			    x: '65',
			    bottom: 40,
			    center: 0,
			    itemWidth: 14,
			    itemHeight: 14,
			    itemGap: 3,
			    orient: "horizontal",
			    data: [],
			    textStyle: {
			      fontSize: '1.1rem',
			    },
			  },
			  radar: {
			    name: {
			      textStyle: {
			        color: '#000000',
			        borderRadius: 13,
			        padding: [5, 5],
			        fontSize: '1.3rem',
			      }
			    },
			    radius: '70%',
			    center: ['50%', '55%'],
			    splitLine: {
			      lineStyle: {
			        color: '#113865',
			        width: 1,
			        opacity: 0.5
			      }
			    },
			    indicator: [{
			        name: localStorage.getItem('lang') == 'en' ? 'Static Force' : '静力',
			        max: 110
			      },
			      {
			        name: localStorage.getItem('lang') == 'en' ? 'Stability' : '稳定性',
			        max: 110
			      },
			      {
			        name: localStorage.getItem('lang') == 'en' ? 'Fatigue' : '疲劳',
			        max: 110
			      },
			      {
			        name: localStorage.getItem('lang') == 'en' ? 'Creep' : '蠕变',
			        max: 110
			      },
			      {
			        name: localStorage.getItem('lang') == 'en' ? 'Connection' : '连接',
			        max: 110
			      },
			      {
			        name: localStorage.getItem('lang') == 'en' ? 'Volume' : '体积',
			        max: 100
			      }
			    ],
			  },
			  series: [{
			    name: '',
			    type: 'radar',
			    data: []
			  }]
			},
			option3: {
			  title: {
			    text: localStorage.getItem('lang') == 'en' ? '1B3T Structure Data' : '单柱三拉结构数据',
			    // subtext: localStorage.getItem('lang') == 'en' ? 'Visual Display' : '可视化显示',
			    x: '0',
			    y: '0',
			    textStyle: {
			      color: '#000000',
			      fontFamily: 'Microsoft YaHei',
			      fontSize: '2.5rem'
			    }
			  },
			  color: ['#46B9B2', '#D372B2'],
			  tooltip: {
			    trigger: 'item'
			  },
			  grid: {
			    top: '23%'
			  },
			  legend: {
			    show: true,
			    padding: [3, 5],
			    right: '0',
			    y: '45',
			    x: '65',
				top:'6%',
				left:'13%',
			    bottom: 40,
			    center: 0,
			    itemWidth: 14,
			    itemHeight: 14,
			    itemGap: 3,
			    orient: "horizontal",
			    data: [],
			    textStyle: {
			      fontSize: '1.1rem',
			    },
			  },
			  radar: {
			    name: {
			      textStyle: {
			        color: '#000000',
			        borderRadius: 13,
			        padding: [5, 5],
			        fontSize: '1.3rem',
			      }
			    },
			    radius: '70%',
			    center: ['50%', '55%'],
			    splitLine: {
			      lineStyle: {
			        color: '#113865',
			        width: 1,
			        opacity: 0.5
			      }
			    },
			    indicator: [{
			        name: localStorage.getItem('lang') == 'en' ? 'Static Force' : '静力',
			        max: 110
			      },
			      {
			        name: localStorage.getItem('lang') == 'en' ? 'Stability' : '稳定性',
			        max: 110
			      },
			      {
			        name: localStorage.getItem('lang') == 'en' ? 'Fatigue' : '疲劳',
			        max: 110
			      },
			      {
			        name: localStorage.getItem('lang') == 'en' ? 'Creep' : '蠕变',
			        max: 110
			      },
			      {
			        name: localStorage.getItem('lang') == 'en' ? 'Connection' : '连接',
			        max: 110
			      },
			      {
			        name: localStorage.getItem('lang') == 'en' ? 'Volume' : '体积',
			        max: 100
			      }
			    ],
			  },
			  series: [{
			    name: '',
			    type: 'radar',
			    data: []
			  }]
			},
			option4: {
			  title: {
			    text: localStorage.getItem('lang') == 'en' ? '2B2T Structure Data' : '双柱单拉结构数据',
			    // subtext: localStorage.getItem('lang') == 'en' ? 'Visual Display' : '可视化显示',
			    x: '0',
			    y: '0',
			    textStyle: {
			      color: '#000000',
			      fontFamily: 'Microsoft YaHei',
			      fontSize: '2.5rem'
			    }
			  },
			  color: ['#46B9B2', '#D372B2'],
			  tooltip: {
			    trigger: 'item'
			  },
			  grid: {
			    top: '23%'
			  },
			  legend: {
			    show: true,
			    padding: [3, 5],
			    right: '0',
				top:'6%',
				left:'13%',
			    y: '45',
			    x: '65',
			    bottom: 40,
			    center: 0,
			    itemWidth: 14,
			    itemHeight: 14,
			    itemGap: 3,
			    orient: "horizontal",
			    data: [],
			    textStyle: {
			      fontSize: '1.1rem',
			    },
			  },
			  radar: {
			    name: {
			      textStyle: {
			        color: '#000000',
			        borderRadius: 13,
			        padding: [5, 5],
			        fontSize: '1.3rem',
			      }
			    },
			    radius: '70%',
			    center: ['50%', '55%'],
			    splitLine: {
			      lineStyle: {
			        color: '#113865',
			        width: 1,
			        opacity: 0.5
			      }
			    },
			    indicator: [{
			        name: localStorage.getItem('lang') == 'en' ? 'Static Force' : '静力',
			        max: 110
			      },
			      {
			        name: localStorage.getItem('lang') == 'en' ? 'Stability' : '稳定性',
			        max: 110
			      },
			      {
			        name: localStorage.getItem('lang') == 'en' ? 'Fatigue' : '疲劳',
			        max: 110
			      },
			      {
			        name: localStorage.getItem('lang') == 'en' ? 'Creep' : '蠕变',
			        max: 110
			      },
			      {
			        name: localStorage.getItem('lang') == 'en' ? 'Connection' : '连接',
			        max: 110
			      },
			      {
			        name: localStorage.getItem('lang') == 'en' ? 'Volume' : '体积',
			        max: 100
			      }
			    ],
			  },
			  series: [{
			    name: '',
			    type: 'radar',
			    data: []
			  }]
			},
			option5: {
			  title: {
			    text: localStorage.getItem('lang') == 'en' ? '2B2T Structure Data' : '双柱双拉结构数据',
			    // subtext: localStorage.getItem('lang') == 'en' ? 'Visual Display' : '可视化显示',
			    x: '0',
			    y: '0',
			    textStyle: {
			      color: '#000000',
			      fontFamily: 'Microsoft YaHei',
			      fontSize: '2.5rem'
			    }
			  },
			  color: ['#46B9B2', '#D372B2'],
			  tooltip: {
			    trigger: 'item'
			  },
			  grid: {
			    top: '23%'
			  },
			  legend: {
			    show: true,
			    padding: [3, 5],
				top:'6%',
				left:'13%',
			    right: '0',
			    y: '45',
			    x: '65',
			    bottom: 40,
			    center: 0,
			    itemWidth: 14,
			    itemHeight: 14,
			    itemGap: 3,
			    orient: "horizontal",
			    data: [],
			    textStyle: {
			      fontSize: '1.1rem',
			    },
			  },
			  radar: {
			    name: {
			      textStyle: {
			        color: '#000000',
			        borderRadius: 13,
			        padding: [5, 5],
			        fontSize: '1.3rem',
			      }
			    },
			    radius: '70%',
			    center: ['50%', '55%'],
			    splitLine: {
			      lineStyle: {
			        color: '#113865',
			        width: 1,
			        opacity: 0.5
			      }
			    },
			    indicator: [{
			        name: localStorage.getItem('lang') == 'en' ? 'Static Force' : '静力',
			        max: 110
			      },
			      {
			        name: localStorage.getItem('lang') == 'en' ? 'Stability' : '稳定性',
			        max: 110
			      },
			      {
			        name: localStorage.getItem('lang') == 'en' ? 'Fatigue' : '疲劳',
			        max: 110
			      },
			      {
			        name: localStorage.getItem('lang') == 'en' ? 'Creep' : '蠕变',
			        max: 110
			      },
			      {
			        name: localStorage.getItem('lang') == 'en' ? 'Connection' : '连接',
			        max: 110
			      },
			      {
			        name: localStorage.getItem('lang') == 'en' ? 'Volume' : '体积',
			        max: 100
			      }
			    ],
			  },
			  series: [{
			    name: '',
			    type: 'radar',
			    data: []
			  }]
			},
			
            optiondata1: {
              value: [89, 87, 45, 45, 78, 82],
              name: localStorage.getItem('lang') == 'en' ? '2B1T' : '单柱单拉',
              lineStyle: {
                normal: {
                  color: '#FF006E',
                  width: 4
                }
              },
              areaStyle: {
                normal: {
                  color: '#FF006E',
                  opacity: 0.5,
                },
              },
            },
            optiondata2: {
              value: [98, 98, 45, 45, 78, 100],
              name: localStorage.getItem('lang') == 'en' ? '1B3T' : '单柱三拉',
              lineStyle: {
                normal: {
                  color: '#FFBE0B',
                  width: 4
                }
              },
              areaStyle: {
                normal: {
                  color: '#FFBE0B',
                  opacity: 0.5,
                },
              },
            },
            optiondata3: {
              value: [5, 70, 90, 5, 64, 74],
              name: localStorage.getItem('lang') == 'en' ? '2B2T' : '双柱双拉',
              lineStyle: {
                normal: {
                  color: '#8338EC',
                  width: 4
                }
              },
              areaStyle: {
                normal: {
                  color: '#8338EC',
                  opacity: 0.5,
                },
              },
            },
            optiondata4: {
              value: [0, 0, 0, 0, 0, 0],
              name: localStorage.getItem('lang') == 'en' ? '1B' : '单柱',
              lineStyle: {
                normal: {
                  color: '#8338EC',
                  width: 4
                }
              },
              areaStyle: {
                normal: {
                  color: '#8338EC',
                  opacity: 0.5,
                },
              },
            },
	
	      		      
			
          };
        },
		watch: {
			activeTab(newTab) {
				const checkedItems = localStorage.getItem('checkedItems');
				console.log(checkedItems);
				if (checkedItems.includes(newTab - 1) || newTab == 0) {
					this.$nextTick(() => {
						this.setcharts(newTab);
					});
				} else {
					localStorage.getItem('lang') === "en" ? this.$message.info('This structure is not selected') : this.$message.info('未点选此结构');
				}
			},
			videoUrl() {
				this.$nextTick(() => {
					this.$refs.videoPlayer.load();
					this.$refs.videoPlayer.play();
				});
			},

		},
		methods: {
			calculateValues(subData) {
				let resultno = this.activeTab*1-1;
				let resultdata = 'resultitem'+ resultno;
				console.log(resultdata);
				const rod_max_s11 = Number(subData[0].rod_max_s11);
				const mand_max_s11 = Number(subData[0].mand_max_s11);
				const Trial = Number(subData[0].Trial);
				const pl = Number(this[resultdata].pl);
				const RS = Number(this[resultdata].Sr);
				const RScom = Number(this[resultdata].maxRScom);
				const RSbending = Number(this[resultdata].maxRSbending);
				const strengthMap = {
					18: 472,
					24: 354,
					28: 390,
					30: 425,
					34: 463,
					40: 438
				};
				let strength = strengthMap[subData[0].rod_dia];
				return [
					this.toPercentage(Math.abs(1 - Math.min(rod_max_s11 / (strength / 1.3), mand_max_s11 / (590 /
						1.45)))),
					(1 - Trial) * 100,
					this.toPercentage(pl),
					RS * 100,
					100 - Math.abs(Math.max(RScom * 100, RSbending * 100)),
					100 // 体积的值，已经是百分比并且始终为100%
				];
			},
			findMaxTrialKey(data) {
			    // 初始化变量以跟踪最大的 Trial 和相应的键
			    let maxTrial = -Infinity;  // 设置为极小值，以确保任何 Trial 都比它大
			    let keyWithMaxTrial = '';
			
			    // 遍历对象的键
			    for (const key in data) {
			        if (data[key][0] && data[key][0].Trial > maxTrial) {
			            maxTrial = data[key][0].Trial;
			            keyWithMaxTrial = key;
			        }
			    }
			
			    return keyWithMaxTrial;
			},
			calculateValues0(subData) {
				//已知subData中有gka  gkb gkc gkd四个子属性，而其都是数组，写一段js，比较其中第一个值的Trial属性，返回Trial最大的属性对应的是gka  gkb gkc gkd中的哪一个
				let gkname = this.findMaxTrialKey(subData);
				console.log("gkname",gkname);
				const rod_max_s11 = Number(subData[gkname][0].rod_max_s11);
				const mand_max_s11 = Number(subData[gkname][0].mand_max_s11);
				const Trial = Number(subData[gkname][0].Trial);
				const pl = Number(subData.pl);
				const RS = Number(subData.Sr);
				const RScom = Number(subData.maxRScom);
				const RSbending = Number(subData.maxRSbending);
				const strengthMap = {
					18: 472,
					24: 354,
					28: 390,
					30: 425,
					34: 463,
					40: 438
				};
				let strength = strengthMap[subData.rod_dia];
				return [
					this.toPercentage(Math.abs(1 - Math.min(rod_max_s11 / (strength / 1.3), mand_max_s11 / (590 /
						1.45)))),
					(1 - Trial) * 100,
					this.toPercentage(pl),
					RS * 100,
					100 - Math.abs(Math.max(RScom * 100, RSbending * 100)),
					100 // 体积的值，已经是百分比并且始终为100%
				];
			},
			processCheckResults() {
				if(this.activeTab==0)
				  {
					console.log("activeTab 是0");
					var checkedItems = localStorage.getItem('checkedItems').split(',').map(Number);
                    console.log("checkedItems",checkedItems);
					let resultdata = '';
					this.option.series[0].data=[];
					let option=this.option;
                    checkedItems.forEach((Item, index) => {
                        console.log(Item);
                        const resultdata = 'resultitem' + Item;
                        const data = JSON.parse(localStorage.getItem(resultdata));
						console.log("读到的data",data);
                        if (data && typeof data === 'object') {
                            const optiondata = {};  // 创建一个新的对象副本
                            optiondata.value = this.calculateValues0(data);
							
                            switch (Item) {
                                case 1:
                                    optiondata.name = localStorage.getItem('lang') == 'en' ? '1B1T': '单柱单拉';
                                    optiondata.areaStyle = {normal: {color: '#FF006E', opacity: 0.5}};
									optiondata.lineStyle = {normal: {color: '#FF006E', opacity: 1}};
									option.color.push('#FF006E');
                                    break;
                                case 2:
                                    optiondata.name = localStorage.getItem('lang') == 'en' ? '1B3T': '单柱三拉';
                                    optiondata.areaStyle = {normal: {color: '#00FF00', opacity: 0.5}};
									optiondata.lineStyle = {normal: {color: '#00FF00', opacity: 1}};
									option.color.push('#00FF00');
                                    break;
                                case 3:
                                    optiondata.name = localStorage.getItem('lang') == 'en' ? '2B1T': '双柱单拉';
                                    optiondata.areaStyle = {normal: {color:  '#FFFF00', opacity: 0.5}};
									optiondata.lineStyle = {normal: {color:  '#FFFF00', opacity: 1}};
									option.color.push('#FFFF00');
                                    break;
                                case 4:
                                    optiondata.name = localStorage.getItem('lang') == 'en' ? '2B2T': '双柱双拉';
                                    optiondata.areaStyle = {normal: {color: '#3A86FF', opacity: 0.5}};
									optiondata.lineStyle = {normal: {color: '#3A86FF', opacity: 1}};
									option.color.push('#3A86FF');
                                    break;
                                default:
                                    break;
                            }
                            console.log(optiondata.name);
                            option.series[0].data.push(optiondata);  // 推入新的对象副本
                            console.log("option--------------------------------------", option.series[0].data);
                        } else {
                            // 如果子数据不存在，可以选择将对应的series数据设置为空数组或采取其他操作
                        } 
                    });

					  this.option=option;
				  }
				  else
				  {
					  //已知下列函数到const values = this.calculateValues(this[resultdata][group]);都是可以正确运行的，如何在
					  let groups = []; // 用来存储包含的 case 名称
					  
					  // 检查 case1 到 case10
					  
					  console.log(groups);
					  let resultno = this.activeTab*1-1;
					  let resultdata = 'resultitem'+ resultno;
					  console.log("resultdata",resultdata);
					  for (let i = 1; i <= 10; i++) {
					      let caseName = 'case' + i;
					      if (this[resultdata].hasOwnProperty(caseName)) {
					          groups.push(caseName); // 如果存在该属性，添加到 groups 数组
					      }
					  }
					  this['option'+this.activeTab].color=[];
					  groups.forEach((group, index) => {
					  		if (this[resultdata][group] && typeof this[resultdata][group] === 'object') {
					  			const values = this.calculateValues(this[resultdata][group]);
								console.log("values",this.activeTab);
								const optiondata = {};
								optiondata.value=values;
								switch (group) {
								    case 'case1':
								        optiondata.name = localStorage.getItem('lang') == 'en' ? 'case1': '工况1';
								        optiondata.areaStyle = {normal: {color: '#FF0000', opacity: 0.5}};
								        optiondata.lineStyle = {normal: {color: '#FF0000', opacity: 1}};
								        this['option'+this.activeTab].color.push('#FF0000');
								        break;
								    case 'case2':
								        optiondata.name = localStorage.getItem('lang') == 'en' ?'case2': '工况2';
								        optiondata.areaStyle = {normal: {color:  '#00FF00', opacity: 0.5}};
								        optiondata.lineStyle = {normal: {color:  '#00FF00', opacity: 1}};
								        this['option'+this.activeTab].color.push('#00FF00');
								        break;
								    case 'case3':
								        optiondata.name = localStorage.getItem('lang') == 'en' ? 'case3': '工况3';
								        optiondata.areaStyle = {normal: {color:  '#0000FF', opacity: 0.5}};
								        optiondata.lineStyle = {normal: {color:  '#0000FF', opacity: 1}};
								        this['option'+this.activeTab].color.push('#0000FF');
								        break;
								    case 'case4':
								        optiondata.name = localStorage.getItem('lang') == 'en' ? 'case4': '工况4';
								        optiondata.areaStyle = {normal: {color: '#FFFF00', opacity: 0.5}};
								        optiondata.lineStyle = {normal: {color: '#FFFF00', opacity: 1}};
								        this['option'+this.activeTab].color.push('#FFFF00');
								        break;
								    case 'case5':
								        optiondata.name = localStorage.getItem('lang') == 'en' ? 'case5': '工况5';
								        optiondata.areaStyle = {normal: {color: '#FF00FF', opacity: 0.5}};
								        optiondata.lineStyle = {normal: {color: '#FF00FF', opacity: 1}};
								        this['option'+this.activeTab].color.push('#FF00FF');
								        break;
								    case 'case6':
								        optiondata.name = localStorage.getItem('lang') == 'en' ? 'case6': '工况6';
								        optiondata.areaStyle = {normal: {color: '#00FFFF', opacity: 0.5}};
								        optiondata.lineStyle = {normal: {color: '#00FFFF', opacity: 1}};
								        this['option'+this.activeTab].color.push('#00FFFF');
								        break;
								    case 'case7':
								        optiondata.name = localStorage.getItem('lang') == 'en' ? 'case7': '工况7';
								        optiondata.areaStyle = {normal: {color:  '#800000', opacity: 0.5}};
								        optiondata.lineStyle = {normal: {color:  '#800000', opacity: 1}};
								        this['option'+this.activeTab].color.push('#800000');
								        break;
								    case 'case8':
								        optiondata.name = localStorage.getItem('lang') == 'en' ? 'case8': '工况8';
								        optiondata.areaStyle = {normal: {color: '#008000', opacity: 0.5}};
								        optiondata.lineStyle = {normal: {color: '#008000', opacity: 1}};
								        this['option'+this.activeTab].color.push('#008000');
								        break;	
								    case 'case9':
								        optiondata.name = localStorage.getItem('lang') == 'en' ?'case9': '工况9';
								        optiondata.areaStyle = {normal: {color: '#000080', opacity: 0.5}};
								        optiondata.lineStyle = {normal: {color: '#000080', opacity: 1}};
								        this['option'+this.activeTab].color.push('#000080');
								        break;
								    case 'case10':
								        optiondata.name = localStorage.getItem('lang') == 'en' ? 'case10': '工况10';
								        optiondata.areaStyle = {normal: {color: '#808000', opacity: 0.5}};
								        optiondata.lineStyle = {normal: {color: '#808000', opacity: 1}};
								        this['option'+this.activeTab].color.push('#808000');
								        break;		
								    default:
								        break;
								}

								this['option'+this.activeTab].legend.data.push(optiondata.name);
					  			this['option'+this.activeTab].series[0].data.push(optiondata);
					  		} else {
					  			// 如果子数据不存在，可以选择将对应的series数据设置为空数组或采取其他操作
					  			this['option'+this.activeTab].series[0].data[index].value = [];
					  		} 
					  });
				  }
			
			},
			toPercentage(value) {
				return Math.min(Math.max(value * 100, 0), 100);
			},
			isTabShown(index) {
				// 动态构造变量名并检查其值
				return this[`showtab${index}`];
			},
			setcharts(newTab) {
				let option = "option0";
				let datanme = "Resultsdata";
				console.log("setcharts已运行");
				switch (newTab) {
					case 0:
						option = "option";
						datanme = "Resultsdata";
						this.Resultsdata = JSON.parse(localStorage.getItem('Resultsdata'));
						console.log("this.Resultsdata",this.Resultsdata)
						break;
					case 1:
						option = "option1";
						datanme = "resultitem0";
						this.resultitem0 = JSON.parse(localStorage.getItem('resultitem0'));
						console.log("this.resultitem0",this.resultitem0)
						break;
					case 2:
						option = "option2";
						datanme = "resultitem1";
						this.resultitem1 = JSON.parse(localStorage.getItem('resultitem1'));
						console.log("this.resultitem1",this.resultitem1)
						break;
					case 3:
						option = "option3";
						datanme = "resultitem2";
						this.resultitem2 = JSON.parse(localStorage.getItem('resultitem2'));
						break;
					case 4:
						option = "option4";
						datanme = "resultitem3";
						this.resultitem3 = JSON.parse(localStorage.getItem('resultitem3'));
						break;
					case 5:
						option = "option5";
						datanme = "resultitem4";
						this.resultitem4 = JSON.parse(localStorage.getItem('resultitem4'));
						break;
					default:
						// 无效的参数
						console.log('无效的参数');
				}
				console.log(option);
				var vm = this;
				console.log(vm[option]);
				var vmoption = vm[option];
				console.log(vmoption);
				this.processCheckResults();
				let myChart = echarts.init(this.$refs.radar);
				myChart.setOption(vm[option]);
				
			},
			checkboxClicked(index) {
				console.log(this.checkboxes)
				console.log(index);
				const checkedItems = JSON.parse(localStorage.getItem('checkedItems'));
				console.log(checkedItems);
				let myChart = echarts.init(this.$refs.radar);
				let tabarr = [];
				tabarr = localStorage.getItem('lang') == 'en' ? ['Comp', '1B', '1B1T', '1B3T', '2B1T', '2B2T'] : ['综合', '单柱单拉', '单柱双拉', '单柱三拉', '双柱单拉', '双柱双拉'];
				if (!this.checkboxes[index]) {
					myChart.dispatchAction({
						type: 'legendSelect',
						name: tabarr[index]
					});
				} else {
					myChart.dispatchAction({
						type: 'legendUnSelect',
						// 图例名称
						name: tabarr[index]
					})
				}

			},
			loginout() {
				localStorage.removeItem("userinfo");
				this.$router.push(`/`);
			},
			next(productNumber) {
				if (![1, 2, 3, 4, 6].includes(productNumber)) {
					throw new Error('参数必须是1、2、3或4');
				}

				this.$router.push(`/chanpin${productNumber}`);
			},
		},
	};
</script>

<style scoped lang="scss">
	.group_c_2 {
		margin-top: 4.75rem;
		padding: 0 3.75rem 2.44rem;
		border-bottom: solid 0.25rem #575b66;

		.text_6 {
			margin-right: 1.19rem;
			color: #000000;
			font-size: 1.44rem;
			font-family: FZLTZHK;
			line-height: 1.56rem;
			letter-spacing: 0.072rem;
		}
	}

	table {
		width: 100%;
		border-collapse: collapse;
		background-color: #fff7d9;
		/* Light Yellow */
		border: 2px solid white;
	}

	th,
	td {
		border: 2px solid white;
		padding: 8px;
		text-align: left;
	}

	th,
	td:nth-child(1) {
		text-align: center;
		/* Center alignment for the header and the first column */
	}

	th {
		background-color: #ffe066;
		/* Orange */
		border-top-left-radius: 1rem;
		/* 5px radius for the top-left corner */
		border-top-right-radius: 1rem;
		/* 5px radius for the top-right corner */
	}

	/* Widths */
	td:nth-child(1) {
		width: 16.66%;
		/* 1 part */
	}

	td:nth-child(2),
	td:nth-child(3),
	td:nth-child(4) {
		width: 33.33%;
		/* 2 parts */
	}

	.pos_777 {
		position: absolute;
		right: 31.13rem;
		bottom: 40rem;
	}

	input {
		width: 7rem;
		padding-left: 2rem;
		border: none;
		/* Removes the border */
		background: transparent;
		/* Makes the background transparent */
		outline: none;
		box-sizing: border-box;
	}

	.font_2 {
		font-size: 0.88rem;
		font-family: Adobe Heiti Std;
		line-height: 0.88rem;
		color: #a3a3a3;
	}

	.section_bg6 {
		padding: 0.56rem 1.63rem 1.56rem;
		background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/6303769ee16d040011538d1b/16920317083267902563.png');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		width: 20.94rem;
	}

	.pos__bg10 {
		position: absolute;
		right: 7.94rem;
		top: 0;
	}

	.group_bg11 {
		margin-top: 1.13rem;
		padding-left: 0.94rem;
		padding-right: 0.5rem;
	}

	.space-x-bg36>*:not(:first-child) {
		margin-left: 2.25rem;
	}

	.text_bg11 {
		text-transform: uppercase;
		opacity: 0.34;
	}

	.text_bg12 {
		line-height: 0.81rem;
	}

	.group_bg13 {
		margin-top: 1.06rem;
		padding-left: 1rem;
		padding-right: 0.44rem;
	}

	.space-x-bg35>*:not(:first-child) {
		margin-left: 2.19rem;
	}

	.text_bg13 {
		line-height: 0.81rem;
	}

	.group_bg14 {
		margin-top: 1.06rem;
	}

	.space-x-bg18>*:not(:first-child) {
		margin-left: 1.13rem;
	}

	.text_bg17 {
		margin-right: 0.44rem;
		line-height: 0.81rem;
	}

	.group_bg15 {
		margin-top: 1.06rem;
		padding: 0 0.5rem;
	}

	.space-x-bg28>*:not(:first-child) {
		margin-left: 1.75rem;
	}

	.text_bg18 {
		line-height: 0.81rem;
	}

	.group_bg16 {
		margin-top: 1.13rem;
		padding-left: 1.06rem;
		padding-right: 0.44rem;
	}

	.space-x-bg34>*:not(:first-child) {
		margin-left: 2.13rem;
	}

	.text_bg20 {
		line-height: 0.81rem;
	}

	.group_bg17 {
		margin-top: 1.5rem;
	}

	.group_bg18 {
		width: 6.94rem;
	}

	.image_6 {
		width: 5.5rem;
		height: 4.88rem;
	}

	.font_6 {
		font-size: 0.5rem;
		font-family: HiraginoSansGB;
		line-height: 0.5rem;
		color: #3d3d3d;
	}

	.text_bg21 {
		text-transform: uppercase;
		opacity: 0.73;
	}

	.pos__bg14 {
		position: absolute;
		left: 0;
		top: 0.75rem;
	}

	.group_bg19 {
		width: 11.94rem;
	}

	.space-y-31>*:not(:first-child) {
		margin-top: 1.94rem;
	}

	.pos__bg15 {
		position: absolute;
		right: 2.38rem;
		top: 1.56rem;
	}

	.text_bg2 {
		text-transform: uppercase;
	}

	.text_bg10 {
		color: #535353;
		font-size: 1rem;
		font-family: Adobe Heiti Std;
		line-height: 1rem;
	}

	.page {
		background-color: #f1f1f1;
		width: 100%;
		overflow-y: hidden;
		overflow-x: hidden;
		height: 100%;

		.header {
			padding-bottom: 1.63rem;
			height: 5.88rem;

			.section_2 {
				padding: 0.31rem 3.69rem 0.94rem;
				background-color: #df8d34;

				.text {
					margin-left: 88.75rem;
					color: #fcfcfc;
					font-size: 1.06rem;
					font-family: Adobe Heiti Std;
					line-height: 1rem;
					text-transform: uppercase;
					opacity: 0.93;
				}

				.image {
					width: 3rem;
					height: 3rem;
				}
			}

			.space-x-48 {
				&>*:not(:first-child) {
					margin-left: 3rem;
				}
			}

			.section {
				padding: 1.5rem 3.38rem 1.75rem;
				background-color: #164683;
				border-radius: 0px 2.5rem 0px 0px;
				width: 21.63rem;

				.image_2 {
					width: 3.19rem;
					height: 0.81rem;
				}

				.image_3 {
					width: 8.38rem;
					height: 1.63rem;
				}
			}

			.space-y-3 {
				&>*:not(:first-child) {
					margin-top: 0.19rem;
				}
			}

			.pos {
				position: absolute;
				left: 0;
				top: 0;
			}
		}

		.group {
			overflow-y: auto;

			.section_c_3 {
				padding: 2rem 0.1rem 3rem 8rem;

				.space-y-8 {
					&>*:not(:first-child) {
						margin-top: 0.5rem;
					}

					.group_4 {
						margin-top: 1.44rem;
						width: 30rem;

						.section_3 {
							padding: 0 3.13rem 2.38rem;
							background-color: #494949;
							border-radius: 0.5rem;
							width: 19.69rem;

							.group_4 {
								padding: 1.31rem 0 2.75rem;

								.font_2 {
									font-size: 1.13rem;
									font-family: FZLTZHK;
									line-height: 1.19rem;
									color: #a3a3a3;
								}

								.text_6 {
									line-height: 1.13rem;
								}

								.space-y-16 {
									&>*:not(:first-child) {
										margin-top: 1rem;
									}

									.group_6 {
										padding: 0.31rem 0 0.75rem;

										.image_5 {
											border-radius: 0.5rem;
											width: 2.31rem;
											height: 2.25rem;
										}

										.pos_5 {
											position: absolute;
											left: 0;
											top: 50%;
											transform: translateY(-50%);
										}

										.text_7 {
											line-height: 1.13rem;
										}
									}

									.group_8 {
										padding: 0.5rem 0;

										.section_4 {
											background-color: #2222227d;
											border-radius: 0.5rem;
											width: 2.31rem;
											height: 2.19rem;
										}

										.pos_7 {
											position: absolute;
											left: 0;
											top: 50%;
											transform: translateY(-50%);
										}

										.text_8 {
											line-height: 1.13rem;
										}

										.pos_9 {
											position: absolute;
											left: 0;
											top: 50%;
											transform: translateY(-50%);
										}
									}
								}
							}

							.space-y-37 {
								&>*:not(:first-child) {
									margin-top: 2.31rem;
								}
							}

							.button {
								margin: 0 1.25rem;
								padding: 0.5rem 0;
								background-color: #cb8234;
								border-radius: 0.5rem;
								width: 10.81rem;
								border: solid 0.19rem #cb8234;

								.text_11 {
									color: #2f2e2f;
									font-size: 1.56rem;
									font-family: FZLTZHK;
									line-height: 1.63rem;
								}
							}
						}


						.text_7 {
							color: #000000;
							font-size: 1.81rem;
							font-family: FZLTZHK;
							line-height: 1.69rem;
						}

						.group_6 {
							margin-top: 4.5rem;

							.space-x-22 {
								&>*:not(:first-child) {
									margin-left: 1.38rem;
								}

								.image_6 {
									width: 1.31rem;
									height: 1.31rem;
								}
							}
						}

						.space-y-14 {
							&>*:not(:first-child) {
								margin-top: 0.88rem;
							}
						}

						.divider {
							margin-top: 2.63rem;
							background-color: #e0e0e061;
							border-radius: 0.063rem;
							height: 0.19rem;
						}

						.text_11 {
							margin-top: 4.25rem;
							color: #000000;
							font-size: 1.75rem;
							font-family: FZLTZHK;
							line-height: 1.75rem;
						}

						.group_9 {
							margin-top: 3.38rem;

							.group_10 {
								padding: 0 0.13rem;

								.image_7 {
									width: 1.44rem;
									height: 1.5rem;
								}

								.image_8 {
									width: 1.44rem;
									height: 1.31rem;
								}

								.image_9 {
									width: 1.31rem;
									height: 1.19rem;
								}
							}
						}

						.space-x-23 {
							&>*:not(:first-child) {
								margin-left: 1.44rem;
							}

							.image_4 {
								width: 1.38rem;
								height: 1.38rem;
							}
						}
					}

					.group_8 {
						margin-left: 7.06rem;
						margin-top: 9.5rem;

						.text_8 {
							line-height: 1.25rem;
						}
					}

					.space-y-236 {
						&>*:not(:first-child) {
							margin-top: 14.75rem;
						}
					}

					.group_3 {
						margin-left: 3.38rem;
						width: 27.75rem;

						.image-wrapper {
							padding: 6.75rem 0 3.19rem;
							background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882779348592060112.png');
							background-size: 100% 100%;
							background-repeat: no-repeat;

							.image_5 {
								width: 23.5rem;
								height: 21.75rem;
							}
						}
					}

					.space-y-20 {
						&>*:not(:first-child) {
							margin-top: 1.25rem;
						}
					}

					.group_7 {
						margin-left: 1.38rem;
						margin-top: 9.13rem;

						.text_12 {
							line-height: 1.25rem;
						}
					}

					.space-y-245 {
						&>*:not(:first-child) {
							margin-top: 15.31rem;
						}
					}

					.space-x-24 {
						&>*:not(:first-child) {
							margin-left: 1.5rem;
						}

						.image_10 {
							width: 1.31rem;
							height: 1.06rem;
						}
					}

					.font_3 {
						font-size: 1.38rem;
						font-family: FZLTZHK;
						line-height: 1.94rem;
						color: #000000;
					}

					.text_14 {
						margin-right: 17rem;
						line-height: 1.25rem;
					}
				}

				.text-wrapper_4 {
					margin-left: 30rem;
					padding: 0.69rem 0;
					background-color: #f59e40;
					border-radius: 0.5rem;
					width: 10.75rem;

					.text_15 {
						color: #000000;
						font-size: 1.56rem;
						font-family: FZLTZHK;
						line-height: 1.63rem;
					}
				}
			}

			.section_3 {
				padding: 3rem 0 3.88rem 0.75rem;
				background-color: #164683;
				border-radius: 0px 0px 2.5rem 0px;
				width: 21.63rem;
				height: 61.63rem;

				.group_3 {
					padding: 0 3.75rem 2.44rem;
					border-bottom: solid 0.25rem #575b66;

					.image_4 {
						width: 1.5rem;
						height: 1.5rem;
					}

					.text_3 {
						margin-right: 1.31rem;
						color: #ffffff;
						font-size: 1.44rem;
						font-family: Adobe Heiti Std;
						line-height: 1.44rem;
						letter-spacing: 0.072rem;
					}
				}

				.space-x-24 {
					&>*:not(:first-child) {
						margin-left: 1.5rem;
					}
				}

				.group_4 {
					margin-top: 3.56rem;

					.section_6 {
						opacity: 0.95;
						background-color: #e9e9e9;
						border-radius: 0.94rem;
						width: 15.63rem;
						height: 2.94rem;
						border: solid 0.19rem #df8d34;
					}

					.pos_4 {
						position: absolute;
						left: 3.75rem;
						top: 50%;
						transform: translateY(-50%);
					}

					.pos_44 {
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
					}

					.pos_444 {
						// position: absolute;
						// top: 60%;
						margin-top: -1.4rem;
						transform: translateY(-50%);
					}
				}

				.text_2 {
					text-transform: uppercase;
					opacity: 0.8;
					font-size: 1.1rem;
				}

				.group_5 {
					font-size: 1.1rem;
					margin-top: 2.88rem;
					padding: 0 2.38rem;

					.text-wrapper_2 {
						margin-right: 0.5rem;
						padding: 1rem 0;
						background-color: #fcfcfc91;
						border-radius: 0.94rem;
						width: 15.63rem;

						.text_6 {
							line-height: 0.94rem;
							text-transform: uppercase;
							opacity: 0.84;
						}
					}

					.image-wrapper {
						margin-right: 0.5rem;
						padding: 1rem 0;
						background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882057507515801764.png');
						background-size: 100% 100%;
						background-repeat: no-repeat;

						.image_7 {
							width: 11.5rem;
							height: 1rem;
						}
					}

					.button {
						margin-right: 0.5rem;
						padding: 1rem 0;
						background-color: #fcfcfc80;
						border-radius: 0.94rem;

						.image_8 {
							width: 10.5rem;
							height: 1rem;
						}
					}

					.button_3 {
						margin-right: 0.5rem;
						padding: 1rem 0;
						background-color: #fcfcfc80;
						border-radius: 0.94rem;
						width: 15.63rem;

						.text_13 {
							color: #434343;
						}
					}
				}

				.space-y-46 {
					&>*:not(:first-child) {
						margin-top: 2.88rem;
					}
				}

				.font_2 {
					font-size: 0.94rem;
					font-family: Adobe Heiti Std;
					line-height: 1rem;
					color: #2d2d2d;
				}

				.text_16 {
					margin-top: 19.81rem;
					color: #fcfcfc;
					font-size: 1.13rem;
					font-family: Adobe Heiti Std;
					line-height: 1.06rem;
					text-transform: uppercase;
					opacity: 0.79;
				}
			}

			.group_2 {
				padding-top: 28.44rem;

				.image-wrapper_2 {
					padding: 6rem 0 20rem;
					background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882057507331846491.png');
					background-size: 100% 100%;
					background-repeat: no-repeat;

					.image_9 {
						margin-right: 43.88rem;
						width: 2rem;
						height: 5rem;
					}
				}

				.section_4 {
					padding: 2rem 3.19rem 2.81rem;
					background-color: #4949493d;
					border-radius: 0.5rem;
					width: 19.56rem;

					.image_5 {
						width: 9.25rem;
						height: 1.19rem;
					}

					.text-wrapper {
						margin-top: 1rem;
						padding: 0.5rem 0;
						background-color: #fcfcfc7d;
						border-radius: 0.5rem;
						width: 13.19rem;

						.font_1 {
							font-size: 0.81rem;
							font-family: Adobe Heiti Std;
							line-height: 1.13rem;
							color: #3d3d3d;
						}

						.text_4 {
							margin-left: 1.56rem;
							text-transform: uppercase;
							opacity: 0.34;
						}

						.text_5 {
							margin-left: 1.63rem;
						}
					}

					.view {
						margin-top: 0;
					}

					.group_6 {
						margin-top: 3.31rem;
						padding-left: 1.13rem;

						.group_7 {
							width: 8.38rem;

							.image_6 {
								width: 7.56rem;
								height: 6.69rem;
							}

							.pos_5 {
								position: absolute;
								left: 0;
								top: 0.63rem;
							}
						}

						.text_8 {
							margin-top: 2.25rem;
						}
					}

					.space-x-14 {
						&>*:not(:first-child) {
							margin-left: 0.88rem;
						}
					}

					.font_3 {
						font-size: 0.69rem;
						font-family: Adobe Heiti Std;
						line-height: 0.69rem;
						color: #3d3d3d;
					}

					.text_7 {
						text-transform: uppercase;
						opacity: 0.73;
					}

					.text_11 {
						margin-top: 0.38rem;
					}

					.button_2 {
						margin-top: 2.5rem;
						padding: 0.75rem 0;
						background-color: #df8d34;
						border-radius: 0.5rem;
						width: 10.81rem;

						.text_12 {
							color: #ffffff;
							font-size: 1.56rem;
							font-family: Adobe Heiti Std;
							line-height: 1.63rem;
						}
					}
				}

				.space-y-37 {
					&>*:not(:first-child) {
						margin-top: 2.31rem;
					}
				}

				.pos_2 {
					position: absolute;
					right: 6.13rem;
					top: 12rem;
				}

				.section_5 {
					padding: 15.25rem 4.56rem 8rem;
					background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882057513498483953.png');
					background-size: 100% 100%;
					background-repeat: no-repeat;
					width: 61.88rem;

					.text-wrapper_3 {
						padding: 0.63rem 0 0.88rem;
						background-color: #f7f7f7;
						border-radius: 0.5rem;
						width: 13.19rem;
						border: solid 0.19rem #cb8234;

						.text_10 {
							color: #3b2a2a;
						}
					}

					.text-wrapper_4 {
						padding: 0.63rem 0 0.81rem;
						background-color: #f7f7f7;
						border-radius: 0.5rem;
						width: 13.19rem;
						border: solid 0.19rem #cb8234;

						.text_14 {
							color: #292e35;
						}
					}
				}

				.space-y-178 {
					&>*:not(:first-child) {
						margin-top: 11.13rem;
					}
				}

				.pos_3 {
					position: absolute;
					left: 16rem;
					top: 16rem;
				}

				.text-wrapper_5 {
					padding: 0.63rem 0 0.88rem;
					background-color: #f7f7f7;
					border-radius: 0.5rem;
					width: 13.13rem;
					border: solid 0.19rem #cb8234;

					.text_15 {
						color: #433232;
					}
				}

				.pos_6 {
					position: absolute;
					left: 27.19rem;
					top: 42.25rem;
				}

				.font_4 {
					font-size: 1.38rem;
					font-family: Adobe Heiti Std;
					line-height: 1.31rem;
				}
			}

			.text_9 {
				text-transform: uppercase;
			}
		}
	}

	input::placeholder {
		color: #000000;
		font-size: 1.2em;
	}

	.unit {
		display: inline-block;
	}

	.van-tabbar-item {
		/* Adjust these to change the style of the tabs */
		width: 150px;
		text-align: center;
		border-radius: 10px 10px 0 0;
		border: 2px solid orange;
		font-size: 1.2rem;
	}

	.van-tabbar-item.active {
		color: #164683;
		border-bottom: none;
		font-weight: 300;
	}
</style>