<template>
  <div class="flex-col page">
    <div class="flex-col justify-start items-end relative header">
      <div class="flex-row items-center section_2 space-x-48">
         <span class="self-start text" style="margin-top:1.2rem;text-transform: none;" @click="loginout">{{userinfo.username}},{{ lang === 'en' ? 'Hello (Click to logout)' : '你好（点击退出）' }}</span>
        <img
          class="shrink-0 image"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882057507383748530.png"
        />
      </div>
	 <!-- <div class="flex-row justify-end section_2 space-x-43-reverse pos_2" style="top:50px;">
	       <van-tabbar v-model="activeTab" style="top:4.3rem;width:98.4rem;left:21.6rem;position:absolute;">
	           <van-tabbar-item v-for="(tab, index) in tabs" :key="index" :class="activeTab === index ? 'active' : ''">
	           <input type="checkbox" :value="index"> {{ tab }}
	           </van-tabbar-item>
	         </van-tabbar>
	  </div> -->
      <div class="flex-col items-start section space-y-3 pos">
        <img
          class="image_2"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882057507649290089.png"
        />
        <img
          class="image_3"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/638b18425a7e3f03100a62af/649947d8726ab50011f379b8/50306348c9b05809c4d214e0868b960e.png"
        />
      </div>
    </div>
    <div class="flex-row items-end flex-auto group" style="overflow-x:hidden">
      <div class="flex-col shrink-0 section_3">
        <div class="flex-row items-center group_3 space-x-24">
          <img
            class="shrink-0 image_4"
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882057507488602865.png"
          />
          <span class="text_2 text_3"  :style='lang === "en"?"font-size:1.2rem;":""'>{{ lang === 'en' ? 'Design workflow' : '设计要素流程' }}</span>
        </div>

        <div class="flex-col group_5 space-y-46">
          <div class="flex-col justify-start items-center text-wrapper_2">
            <span class="font_2 text_6" @click="next(1)" style="font-size:1rem;">{{ lang === 'en' ? 'A1 Geom' : 'A1 结构-几何输入' }}</span>
          </div>
          <div class="flex-col justify-start items-center relative group_4">
            <div class="section_6"></div>
            <span class="font_2 text_2 pos_4" style="font-size:1rem;">{{ lang === 'en' ? 'B1 Loads' : 'B1 工况输入' }}</span>
          </div>
		  <div class="flex-col justify-start items-center relative group_4" >
		    <div class="section_6" style="background-color: rgba(252, 252, 252, 0.568627451);border:none"></div>
		    <span class="font_2 text_2 pos_44" style="font-size:1rem;" @click="next(6)">{{ lang === 'en' ? 'B2 Comp&Results' : 'B2 选项计算及结果' }}</span>
		  </div>
          <div class="flex-col justify-start items-center image-wrapper">
           <div class="section_6"></div>
           <span class="font_2 text_2 pos_4" @click="next(3)" style="font-size:1rem;line-height: 0.88rem;">{{ lang === 'en' ? 'C1 Visualization' : 'C1 结构设计性能的可视化' }}</span>
          </div>
          <div class="flex-col justify-start items-center button">
           <div class="section_6"></div>
           <span class="font_2 text_2 pos_4" @click="next(4)" style="font-size:1rem;line-height: 0.88rem;">{{ lang === 'en' ? 'C2 Summary' : 'C2 结构设计性能的总结' }}</span>
          </div>
          <!-- <div class="flex-col justify-start items-center button_3">
            <span class="font_2 text_9 text_13" style="font-size:1.1rem;">完成流程</span>
          </div> -->
        </div>
		
        <span class="self-center text_16"></span>
      </div>
      <div class="flex-col justify-start flex-auto relative group_2">
        <div class="flex-col justify-start items-end image-wrapper_2">
          
        </div>
		<div class="flex-col section_4 space-y-37 pos_2" style="background:none;top:21rem;left:50rem">
		    <div class="flex-col section_bg666 pos__bg10" style="height:7rem;">
		      <span class="self-center text_bg2 text_bg10">{{ lang === 'en' ? 'Glue ratio' : '输入胶装比' }}</span>
		      <div class="flex-row justify-between items-center group_bg11">
		        <div class="flex-row self-stretch space-x-bg36">
		          <span class="font_2 text_bg2" :style='lang === "en"?"font-size:1rem;":"font-weight:500;color: #000;width:5rem;"'>{{ lang === 'en' ? 'Glue ratio' : '胶装比' }}</span>
				  <input class="font_2 text_bg11" style="width:4rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="jzb" :placeholder='lang === "en" ? "Glue ratio" : "输入胶装比"'>
		        </div>
		      </div>

		      <div class="flex-col justify-start items-center relative group_bg17">
		        <div class="flex-col justify-start items-center relative group_bg18">

		        </div>
		        <div class="flex-col group_bg19 space-y-31 pos__bg15">
		    
		        </div>
		      </div>
		    </div>
		  
		</div>
		
		<div class="flex-col section_4 space-y-37 pos_2" style="background:none;top:13rem;left:50rem">
		    <div class="flex-col section_bg66 pos__bg10" style="height:7rem;">
		      <span class="self-center text_bg2 text_bg10">{{lang === 'en' ? 'Safety factor' : '输入安全系数'}}</span>
		      <div class="flex-row justify-between items-center group_bg11">
		        <div class="flex-row self-stretch space-x-bg36">
		          <span class="font_2 text_bg2" :style='lang === "en"?"font-size:1rem;":"font-weight:500;color: #000;width:5rem;"'>{{lang === 'en' ? 'Safety factor' : '安全系数'}}</span>
				  <input class="font_2 text_bg11" style="background:transparent;border:none;" @blur="basehandleBlur"  v-model="aqxs"  placeholder="系数">
		        </div>
		      </div>
		
		      <div class="flex-col justify-start items-center relative group_bg17">
		        <div class="flex-col justify-start items-center relative group_bg18">
		
		        </div>
		        <div class="flex-col group_bg19 space-y-31 pos__bg15">
		    
		        </div>
		      </div>
		    </div>
		  
		</div>
		
		
		
		
 <!--       <div class="flex-col section_4 space-y-37 pos_2" style="background:none;top:13rem">
            <div class="flex-col section_bg6 pos__bg10">
              <span class="self-center text_bg2 text_bg10">{{lang === 'en' ? 'Work loads(KN)' : '输入工况(KN)'}}</span>
              <div class="flex-row justify-between items-center group_bg11">
                <div class="flex-row self-stretch space-x-bg36">
                  <span class="font_2 text_bg2" style="font-weight:500;color:#000;margin-top:0.5rem;">{{lang === 'en' ? 'Case1' : '工况1'}}</span>
				  <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk1[0]" placeholder="FX">
                </div>
                <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk1[1]" placeholder="FY">
                <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk1[2]"  placeholder="FZ">
              </div>
               <div class="flex-row justify-between items-center group_bg11">
                 <div class="flex-row self-stretch space-x-bg36">
                   <span class="font_2 text_bg2" style="font-weight:500;color:#000;margin-top:0.5rem;">{{lang === 'en' ? 'Case2' : '工况2'}}</span>
               				  <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk2[0]" placeholder="FX">
                 </div>
                 <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk2[1]" placeholder="FY">
                 <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk2[2]"  placeholder="FZ">
               </div>
			   <div class="flex-row justify-between items-center group_bg11">
			     <div class="flex-row self-stretch space-x-bg36">
			       <span class="font_2 text_bg2" style="font-weight:500;color:#000;margin-top:0.5rem;">{{lang === 'en' ? 'Case3' : '工况3'}}</span>
			   				  <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk3[0]" placeholder="FX">
			     </div>
			     <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk3[1]" placeholder="FY">
			     <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk3[2]"  placeholder="FZ">
			   </div>
			   <div class="flex-row justify-between items-center group_bg11">
			     <div class="flex-row self-stretch space-x-bg36">
			       <span class="font_2 text_bg2" style="font-weight:500;color:#000;margin-top:0.5rem;">{{lang === 'en' ? 'Case4' : '工况4'}}</span>
			   				  <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk4[0]" placeholder="FX">
			     </div>
			     <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk4[1]" placeholder="FY">
			     <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk4[2]"  placeholder="FZ">
			   </div>
              <div class="flex-col justify-start items-center relative group_bg17">
                <div class="flex-col justify-start items-center relative group_bg18">
                  <img
                    class="image_6"
                    src="xyz2.png"
                  />
                  <span class="font_6 text_bg21 pos__bg14" @blur="basehandleBlur" v-model="gk5[0]" :style='"transform:scale("+transform+");"'></span>
                </div>
                <div class="flex-col group_bg19 space-y-31 pos__bg15">
                  <span class="self-end font_6 text_bg21"   @blur="basehandleBlur" v-model="gk5[1]" :style='" transform:scale("+transform+");"'></span>
                  <span class="self-start font_6 text_bg21" @blur="basehandleBlur" v-model="gk5[2]" :style='" transform:scale("+transform+");"'></span>
                </div>
              </div>
            </div>
        </div> -->
		        
		
		         
				 
                <div class="flex-col section_4 space-y-37 pos_2" style="background:none;top:13rem">
                  <div class="flex-col section_bg6 pos__bg10">
                    <span style="width:100%;text-align: center" class="self-center text_bg2 text_bg10">{{lang === 'en' ? 'Work loads(KN)' : '输入工况(KN)'}}			                
					<button 
			                 @click="reset" style="border-radius:5px;line-height:1; padding-top:4px;
			                               outline: none; box-shadow: none; border: 1px solid transparent;float:right;font-size:0.8rem;margin: top 10px;">
			                        {{ lang === 'en' ? 'Reset' : '重置' }}
			                    </button></span>
					<div class="custom-scrollbar" style="height:12rem;overflow-y:scroll;padding-top:0.5rem;">
                    <div class="flex-row justify-between items-center group_bg11">
                      <div class="flex-row self-stretch space-x-bg36">
                        <span class="font_2 text_bg2" style="font-weight:500;color:#000;margin-top:0.5rem;">{{lang === 'en' ? 'Case1' : '工况1'}}</span>
                        <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk1[0]" placeholder="FX">
                      </div>
                      <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk1[1]" placeholder="FY">
                      <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk1[2]" placeholder="FZ">
                    </div>
                    <div class="flex-row justify-between items-center group_bg11">
                      <div class="flex-row self-stretch space-x-bg36">
                        <span class="font_2 text_bg2" style="font-weight:500;color:#000;margin-top:0.5rem;">{{lang === 'en' ? 'Case2' : '工况2'}}</span>
                        <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk2[0]" placeholder="FX">
                      </div>
                      <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk2[1]" placeholder="FY">
                      <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk2[2]" placeholder="FZ">
                    </div>
                    <div class="flex-row justify-between items-center group_bg11">
                      <div class="flex-row self-stretch space-x-bg36">
                        <span class="font_2 text_bg2" style="font-weight:500;color:#000;margin-top:0.5rem;">{{lang === 'en' ? 'Case3' : '工况3'}}</span>
                        <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk3[0]" placeholder="FX">
                      </div>
                      <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk3[1]" placeholder="FY">
                      <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk3[2]" placeholder="FZ">
                    </div>
                    <div class="flex-row justify-between items-center group_bg11">
                      <div class="flex-row self-stretch space-x-bg36">
                        <span class="font_2 text_bg2" style="font-weight:500;color:#000;margin-top:0.5rem;">{{lang === 'en' ? 'Case4' : '工况4'}}</span>
                        <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk4[0]" placeholder="FX">
                      </div>
                      <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk4[1]" placeholder="FY">
                      <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk4[2]" placeholder="FZ">
                    </div>
                    <div v-show="showMoreCases >= 1" class="flex-row justify-between items-center group_bg11">
                      <div class="flex-row self-stretch space-x-bg36">
                        <span class="font_2 text_bg2" style="font-weight:500;color:#000;margin-top:0.5rem;">{{lang === 'en' ? 'Case5' : '工况5'}}</span>
                        <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk5[0]" placeholder="FX">
                      </div>
                      <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk5[1]" placeholder="FY">
                      <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk5[2]" placeholder="FZ">
                    </div>
                    <div class="overflow-y-auto" :style="{ maxHeight: showMoreCases > 4 ? '200px' : 'none' }">
                      <div v-show="showMoreCases >= 2" class="flex-row justify-between items-center group_bg11">
                        <div class="flex-row self-stretch space-x-bg36">
                          <span class="font_2 text_bg2" style="font-weight:500;color:#000;margin-top:0.5rem;">{{lang === 'en' ? 'Case6' : '工况6'}}</span>
                          <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk6[0]" placeholder="FX">
                        </div>
                        <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk6[1]" placeholder="FY">
                        <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk6[2]" placeholder="FZ">
                      </div>
                      <div v-show="showMoreCases >= 3" class="flex-row justify-between items-center group_bg11">
                        <div class="flex-row self-stretch space-x-bg36">
                          <span class="font_2 text_bg2" style="font-weight:500;color:#000;margin-top:0.5rem;">{{lang === 'en' ? 'Case7' : '工况7'}}</span>
                          <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk7[0]" placeholder="FX">
                        </div>
                        <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk7[1]" placeholder="FY">
                        <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk7[2]" placeholder="FZ">
                      </div>
                      <div v-show="showMoreCases >= 4" class="flex-row justify-between items-center group_bg11">
                        <div class="flex-row self-stretch space-x-bg36">
                          <span class="font_2 text_bg2" style="font-weight:500;color:#000;margin-top:0.5rem;">{{lang === 'en' ? 'Case8' : '工况8'}}</span>
                          <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk8[0]" placeholder="FX">
                        </div>
                        <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk8[1]" placeholder="FY">
                        <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk8[2]" placeholder="FZ">
                      </div>
                      <div v-show="showMoreCases >= 5" class="flex-row justify-between items-center group_bg11">
                        <div class="flex-row self-stretch space-x-bg36">
                          <span class="font_2 text_bg2" style="font-weight:500;color:#000;margin-top:0.5rem;">{{lang === 'en' ? 'Case9' : '工况9'}}</span>
                          <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk9[0]" placeholder="FX">
                        </div>
                        <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk9[1]" placeholder="FY">
                        <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk9[2]" placeholder="FZ">
                      </div>
                      <div v-show="showMoreCases >= 6" class="flex-row justify-between items-center group_bg11">
                        <div class="flex-row self-stretch space-x-bg36">
                          <span class="font_2 text_bg2" style="font-weight:500;color:#000;margin-top:0.5rem;">{{lang === 'en' ? 'Case10' : '工况10'}}</span>
                          <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk10[0]" placeholder="FX">
                        </div>
                        <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk10[1]" placeholder="FY">
                        <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="gk10[2]" placeholder="FZ">
                      </div>
                    </div>
					</div>
					<div class="flex-row items-center self-center text-wrapper_4" style="margin-left:20px;width:14rem;">
					  <span class="text_2 text_15"  @click="showMoreCases = Math.min(showMoreCases + 1, 6)" :style='lang === "en"?"font-size:1.2rem;margin-left:2.8rem;":"margin-left:2rem;"'>{{ lang == 'en' ? 'More loads' : '输入更多工况参数' }}</span>
					</div>
                  </div>
                </div>
				<div class="flex-col section_4 space-y-37 pos_2" style="background:none;top:13rem;left:92rem;">
				    <div class="flex-col section_bg6 pos__bg10 plbg">
				      <span class="self-center text_bg2 text_bg10">{{lang === 'en' ? 'Fatigue load (KN)' : '输入疲劳工况'}}</span>
				      <div class="flex-row justify-between items-center group_bg11">
				        <div class="flex-row self-stretch space-x-bg36">
				          <span class="font_2 text_bg2" style="font-weight:500;color:#000;margin-top:0.5rem;">{{lang === 'en' ? 'MAX' : '最大力'}}</span>
				  <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="pl1[0]" placeholder="FX">
				        </div>
				        <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="pl1[1]" placeholder="FY">
				        <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="pl1[2]"  placeholder="FZ">
				      </div>
<!-- 				      <div class="flex-row justify-between group_bg13">
				        <div class="flex-row space-x-bg35">
				          <span class="font_2 text_bg2" style="font-weight:500;color: #000;">最小力</span>
				          <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="pl2[0]" placeholder="FX">
				        </div>
				       <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="pl2[1]" placeholder="FY">
				       <input class="font_2 text_bg11" style="width:2.5rem;background:transparent;border:none;" @blur="basehandleBlur" v-model="pl2[2]" placeholder="FZ">
				      </div> -->
					  <div class="flex-row justify-between items-center group_bg14" :style='"margin-top:"+margintop+";"'>
					    <div class="flex-row items-center self-stretch space-x-bg18" style="padding-left:0.94rem;">
					      <span class="font_2 text_bg2" style="font-weight:500;color: #000;width:5rem;text-overflow:clip; white-space: nowrap;">{{lang === 'en' ? 'R' : 'R值'}}</span>
					      <input class="font_2 text_bg11" @blur="handleBlur" :style='"background:transparent;border:none;margin-left:"+marginleft+";"'v-model="R" placeholder="R">
					    </div>
					  				
					  </div>
				      <div class="flex-row justify-between items-center group_bg14" :style='"margin-top:"+margintop+";"'>
				        <div class="flex-row items-center self-stretch space-x-bg18" style="padding-left:0.94rem;">
				          <span class="font_2 text_bg2" style="font-weight:500;color: #000;width:5rem;text-overflow:clip; white-space: nowrap;">{{lang === 'en' ? 'Cycles' : '周期数'}}</span>
				          <input class="font_2 text_bg11" @blur="handleBlur" :style='"background:transparent;border:none;margin-left:"+marginleft+";"'v-model="zq" placeholder="Cycles">
				        </div>
				
				      </div>
				    </div>
				  
				</div>

       
		<div style="position: relative;top:-9rem;left:30rem;margin-top:1rem;">{{lang === 'en' ? 'This diagram is only used to determine the relative coordinate system for load input' : '本图仅用于确定载荷输入的相对坐标系'}}</div>
        <div class="flex-col section_5 space-y-178 pos_3" :style='"margin-top:4rem;pointer-events: none;width:48rem;height:31.7rem;background-image: url("+jgtype+"-zb.png); background-repeat: no-repeat;background-position: center;"'>
         
        </div>
       
      </div>
    </div>
  </div>
</template>

<script>
  import { Picker } from 'vant';
  import { Select, Option } from 'element-ui';
  export default {
      components: {
        [Picker.name]: Picker,
		 'el-select': Select,
		 'el-option': Option,
      },
    data() {
      return {
		    showMoreCases: 0,
		    lang: localStorage.getItem('lang') || 'cn',
			gk10: [null, null, null],
			gk9: [null, null, null],
			gk8: [null, null, null],
			gk7: [null, null, null],
			gk6: [null, null, null],
			gk5: [null, null, null],
			gk4: [null, null, null],
			gk3: [null, null, null],
			gk2: [null, null, null],
			gk1: [null, null, null],
			pl1: [null, null, null],
			pl2: [null, null, null],
			R:0,
			zq:0,
			jzb:0,
			aqxs:0,
			userinfo:null,
		    transform:'1',
		  	activeTab: 0,
			margintop:'0.2rem',
			marginleft:'0rem',
		    options: ['工况输入：-大风工况', '工况输入：-断线工况', '工况输入：-施工/运维工况', '工况输入：仿串倒工况', '工况输入：-夹线工况'],
		    selectedOption: '工况输入：-大风工况',
            jgtype:4,
		    imgstyle:[
		   		  {"mt":30, "w": 48, "h": 9.36}, 
		   		  {"mt":15,"w":38, "h": 33.31}, 
		   		  {"mt":18,"w": 48, "h": 29.25}, 
		   		  {"mt":18,"w": 48, "h": 25.47}, 
		   		  {"mt":18,"w": 48, "h": 31.70}
		   		],
		   tabs: [localStorage.getItem('lang')=='en'?'1B':'单柱','单柱单拉', '单柱三拉', '双柱单拉', '双柱双拉'],
      };
    },
     mounted() {
		 if(JSON.parse(localStorage.getItem(`userinfo`)))
		   {
		 	  this.userinfo=JSON.parse(localStorage.getItem(`userinfo`));
		 	  console.info(this.userinfo);
		   }
		   else
		   {
		 	  this.$router.push('/');
		   }
		 this.updateDimensions();
		  this.checkLocalStorageAndUpdate();
		 window.addEventListener('resize', this.updateDimensions);
        console.log('Component is mounted.');
		if(localStorage.getItem('jgtype'))
		  {
			this.jgtype= 4;  
		  }
		  else
		  {
			
			this.jgtype= 4;  
		  }
		
      },
	   beforeDestroy() {
	      window.removeEventListener('resize', this.updateDimensions);
	    },
	  watch: {
		  checkedItems(){},
	     activeTab(newTab) {
	       console.log('Tab changed to: ', newTab);
	  		  console.log(this.activeTab);
	  		  localStorage.setItem('jgtype',this.activeTab);
	  		  let value = localStorage.getItem('jgtype');
			  this.jgtype=this.activeTab;
	  		  console.log(value);
	     },
	   },
	   //根基localStorage.getItem('lang') === "en"为条件，切换下面代码中所有 this.$message.info为中英文显示
    methods: {
		    reset(){
                this.gk1 = [null, null, null];
		    	this.gk2 = [null, null, null];
		    	this.gk3 = [null, null, null];
		    	this.gk4 = [null, null, null];
		    	this.gk5 = [null, null, null];
		    	this.gk6 = [null, null, null];
				this.gk7 = [null, null, null];
				this.gk8 = [null, null, null];
				this.gk9 = [null, null, null];
				this.gk10 = [null, null, null];
		    },
			basehandleBlur(){
			    const inputs = [
			      { variable: 'gk1', name: '工况1' },
			      { variable: 'gk2', name: '工况2' },
			      { variable: 'gk3', name: '工况3' },
			      { variable: 'gk4', name: '工况4' },
				  { variable: 'gk5', name: '工况5' },
				  { variable: 'gk6', name: '工况6' },
				  { variable: 'gk7', name: '工况7' },
				  { variable: 'gk8', name: '工况8' },
				  { variable: 'gk9', name: '工况9' },
				  { variable: 'gk10', name: '工况10' },
			      { variable: 'jz', name: '胶装比' },
			      { variable: 'aqxs', name: '安全系数' },
			      { variable: 'pl1', name: '疲劳工况max' },
			      { variable: 'R', name: 'R值' },
			      { variable: 'zq', name: '疲劳周期' },
			    ];
			    		
			    inputs.forEach(({ variable, name }) => {
			            localStorage.setItem(name, JSON.stringify(this[variable]));
			    });
			},
		  checkLocalStorageAndUpdate() {
		      const inputs = [
				  { variable: 'gk1', name: '工况1' },
				  { variable: 'gk2', name: '工况2' },
				  { variable: 'gk3', name: '工况3' },
				  { variable: 'gk4', name: '工况4' },
				  { variable: 'gk5', name: '工况5' },
				  { variable: 'gk6', name: '工况6' },
				  { variable: 'gk7', name: '工况7' },
				  { variable: 'gk8', name: '工况8' },
				  { variable: 'gk9', name: '工况9' },
				  { variable: 'gk10', name: '工况10' },
				  { variable: 'jzb', name: '胶装比' },
				  { variable: 'aqxs', name: '安全系数' },
				  { variable: 'pl1', name: '疲劳工况max' },
				  { variable: 'R', name: 'R值' },
				  { variable: 'zq', name: '疲劳周期' },
		      ];
		
		      inputs.forEach(({ variable, name }) => {
		        const storedValue = JSON.parse(localStorage.getItem(name));
		        if (storedValue !== null) {
		          this[variable] = storedValue;
		        }
		      });
		    },
	     handleBlur() {
        const langIsEn = localStorage.getItem('lang') === "en";
        const messages = {
            confirm: langIsEn ? "Check if the data is complete" : "检查数据是否输入完成",
            confirmButton: langIsEn ? "Confirm" : "确定",
            cancelButton: langIsEn ? "Cancel" : "取消",
            incompleteGk: langIsEn ? "Please ensure that all conditions are entered completely." : "请确保输入的工况完整输入。",
            incompleteOneGk: langIsEn ? "Please ensure at least one condition is entered completely." : "请确保至少有一个工况完整输入。",
            incompleteOther: name => langIsEn ? `${name} is not entered completely.` : `${name} 没有输入完整。`,
            allComplete: langIsEn ? "All inputs are complete and saved." : "所有输入都已完成并保存。",
            cancelAction: langIsEn ? "User canceled the action" : "用户取消操作"
        };

        this.$confirm(messages.confirm, "确认", {
            confirmButtonText: messages.confirmButton,
            cancelButtonText: messages.cancelButton,
            type: "warning"
        }).then(() => {
            const gkInputs = [
                { value: this.gk1, name: '工况1' },
                { value: this.gk2, name: '工况2' },
                { value: this.gk3, name: '工况3' },
                { value: this.gk4, name: '工况4' },
                { value: this.gk5, name: '工况5' },
                { value: this.gk6, name: '工况6' },
                { value: this.gk7, name: '工况7' },
                { value: this.gk8, name: '工况8' },
                { value: this.gk9, name: '工况9' },
                { value: this.gk10, name: '工况10' }
            ];
      
            const otherInputs = [
                { value: this.jzb, name: '胶装比' },
                { value: this.aqxs, name: '安全系数' },
                { value: this.pl1, name: '疲劳工况max' },
                { value: this.R, name: 'R值' },
                { value: this.zq, name: '疲劳周期' },
            ];
      
            let completeGkFound = false;
            let incompleteGkCount = 0;
      
            for (let { value } of gkInputs) {
                if (this.isPartial(value)) {
                    incompleteGkCount++;
                }
            }
      
            if (!completeGkFound) {
                for (let { value } of gkInputs) {
                    if (this.isComplete(value)) {
                        completeGkFound = true;
                        break;
                    }
                }
            }
      
            if (!completeGkFound) {
                this.$message.info(messages.incompleteOneGk);
                return;
            }
      
            for (let { value, name } of otherInputs) {
                if (!this.isComplete(value)) {
                    this.$message.info(messages.incompleteOther(name));
                    return;
                }
            }
      
            [...gkInputs, ...otherInputs].forEach(({ value, name }) => {
                this.$set(this, name, value);
                localStorage.setItem(name, JSON.stringify(value));
            });
      
            this.$message.info(messages.allComplete);
            this.$router.push('/chanpin6'); // 用户点击“确定”，跳转页面
        }).catch(() => {
            console.log(messages.cancelAction);
        });
    },
	     isComplete(value) {
	       if (Array.isArray(value)) {
	         return value.every(v => v !== null && v !== '' && !isNaN(Number(v)));
	       } else {
	         return value !== null && value !== '' && !isNaN(Number(value));
	       }
	     },
	     isPartial(value) {
	       if (Array.isArray(value)) {
	         return value.some(v => v !== null && v !== '' && !isNaN(Number(v))) && !this.isComplete(value);
	       } else {
	         return (value !== null && value !== '' && isNaN(Number(value))) || (value === null || value === '');
	       }
	     }
        ,

		updateDimensions() {
			  console.log("浏览器发生变化",window.innerWidth);
			  if(window.innerWidth<1281)
			     {
					 this.marginleft='0.5rem';
					 this.margintop='0.2rem';
					 this.transform='0.8';
				 }
				 else
				 {
					 this.marginleft='0rem'; 
					 this.margintop='0.4rem';
					 this.transform='1';
				 }
		    },
      loginout(){
      	  localStorage.removeItem("userinfo");
         this.$router.push(`/`);
      },
      next(productNumber) {
           if (![1, 2, 3, 4,6].includes(productNumber)) {
             throw new Error('参数必须是1、2、3或4');
           }

            this.$router.push(`/chanpin${productNumber}`);
         },
    },
  };
</script>

<style scoped lang="scss">
	input::placeholder {
	    font-family: 'Arial', sans-serif;
	    font-size: 0.5rem;
	    color: #a3a3a3;
	}
	.font_2 {
	  font-size: 0.88rem;
	  font-family: Adobe Heiti Std;
	  color: #a3a3a3;
	}
	.section_bg6 {
	  padding: 0.56rem 1.63rem 1.56rem;
	  background-image: url('~@/public/tabbg.png');
	  background-size: 100% 100%;
	  background-repeat: no-repeat;
	  width: 20.94rem;
	}
	.section_bg66 {
	  padding: 0.56rem 1.63rem 1.56rem;
	  background-image: url('~@/public/anquanbg.png');
	  background-size: 100% 100%;
	  background-repeat: no-repeat;
	  width: 20.94rem;
	}
	.plbg{
	background-image: url('~@/public/plbg.png');	
	}
	.custom-scrollbar {
	  overflow-y: auto;
	}
	
	.custom-scrollbar::-webkit-scrollbar {
	  width: 6px; /* 滚动条宽度 */
	}
	
	.custom-scrollbar::-webkit-scrollbar-thumb {
	  background-color: #DF8D34; /* 滚动条颜色 */
	  border-radius: 10px; /* 滚动条圆角 */
	}
	
	.custom-scrollbar::-webkit-scrollbar-thumb:hover {
	  background-color: #DF8D34; /* 滚动条悬停时颜色 */
	}
	
	.custom-scrollbar::-webkit-scrollbar-track {
	  background: #f1f1f1; /* 滚动条轨道颜色 */
	}

	.section_bg666 {
	  padding: 0.56rem 1.63rem 1.56rem;
	  background-image: url('~@/public/jiaozhuangbi.png');
	  background-size: 100% 100%;
	  background-repeat: no-repeat;
	  width: 20.94rem;
	}
	.pos__bg10 {
	  position: absolute;
	  right: 15rem;
	  top: 0;
	}
	.group_bg11 {
	  margin-top: 0.8rem;
	  margin-bottom:0.3rem;
	  padding-left: 0.94rem;
	  padding-right: 0.5rem;
	}
	.space-x-bg36 > *:not(:first-child) {
	  margin-left: 2.25rem;
	}
	.text_bg11 {
	  text-transform: uppercase;
	  opacity: 0.89;
	}
	.text_bg12 {
	  line-height: 1.5rem;
	}
	.group_bg13 {
		margin-bottom:0.3rem;
	  padding-left: 1rem;
	  padding-right: 0.44rem;
	}
	.space-x-bg35 > *:not(:first-child) {
	  margin-left: 2.19rem;
	}
	.text_bg13 {
		
	  line-height: 0.81rem;
	}
	.group_bg14 {
		margin-bottom:0.3rem;
 line-height: 1.5rem;
	}
	.space-x-bg18 > *:not(:first-child) {
	  margin-left:0.5vw;
	}
	.text_bg17 {
 line-height: 1.5rem;
	  line-height: 0.81rem;
	}
	.group_bg15 {
		margin-bottom:0.3rem;
 line-height: 1.5rem;
	  padding: 0 0.5rem;
	}
	.space-x-bg28 > *:not(:first-child) {
	  margin-left: 1.75rem;
	}
	.text_bg18 {
		 
	  line-height: 0.81rem;
	}
	.group_bg16 {
		margin-bottom:0.3rem;
	  line-height: 1.5rem;
	   margin-bottom:0.8rem;
	  padding-left: 1.06rem;
	  padding-right: 0.44rem;
	}
	.space-x-bg34 > *:not(:first-child) {
	  margin-left: 2.13rem;
	}
	.text_bg20 {
	  line-height: 0.81rem;
	}
	.group_bg17 {
	   margin-bottom:0.8rem;
	}
	.group_bg18 {
	  width: 6.94rem;
	}
	.image_6 {
	  width: 5.5rem;
	  height: 4.88rem;
	}
	.font_6 {
	  font-size: 0.5rem;
	  font-family: HiraginoSansGB;
	  line-height: 0.5rem;
	  color: #3d3d3d;
	}
	.text_bg21 {
	  text-transform: uppercase;
	  opacity: 0.73;
	}
	.pos__bg14 {
	  position: absolute;
	  left: 0;
	  top: 0.75rem;
	}
	.group_bg19 {
	  width: 11.94rem;
	}
	.space-y-31 > *:not(:first-child) {
	  margin-top: 1.94rem;
	}
	.pos__bg15 {
	  position: absolute;
	  right: 2.38rem;
	  top: 1.56rem;
	}
	.text_bg2 {
	  text-transform: uppercase;
	}
	.text_bg10 {
	  color: #535353;
	  font-size: 1rem;
	  font-family: Adobe Heiti Std;
	  line-height: 1rem;
	}
  .page {
    background-color: #f1f1f1;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    height: 100%;
    .header {
      padding-bottom: 1.63rem;
      height: 5.88rem;
      .section_2 {
        padding: 0.31rem 3.69rem 0.94rem;
        background-color: #df8d34;
        .text {
          margin-left: 88.75rem;
          color: #fcfcfc;
          font-size: 1.06rem;
          font-family: Adobe Heiti Std;
          line-height: 1rem;
          text-transform: uppercase;
          opacity: 0.93;
        }
        .image {
          width: 3rem;
          height: 3rem;
        }
      }
      .space-x-48 {
        & > *:not(:first-child) {
          margin-left: 3rem;
        }
      }
      .section {
        padding: 1.5rem 3.38rem 1.75rem;
        background-color: #164683;
        border-radius: 0px 2.5rem 0px 0px;
        width: 21.63rem;
        .image_2 {
          width: 3.19rem;
          height: 0.81rem;
        }
        .image_3 {
          width: 8.38rem;
          height: 1.63rem;
        }
      }
      .space-y-3 {
        & > *:not(:first-child) {
          margin-top: 0.19rem;
        }
      }
      .pos {
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    .group {
      overflow-y: auto;
      .section_3 {
        padding: 3rem 0 3.88rem 0.75rem;
        background-color: #164683;
        border-radius: 0px 0px 2.5rem 0px;
        width: 21.63rem;
        height: 61.63rem;
        .group_3 {
          padding: 0 3.75rem 2.44rem;
          border-bottom: solid 0.25rem #575b66;
          .image_4 {
            width: 1.5rem;
            height: 1.5rem;
          }
          .text_3 {
            margin-right: 1.31rem;
            color: #ffffff;
            font-size: 1.44rem;
            font-family: Adobe Heiti Std;
            line-height: 1.44rem;
            letter-spacing: 0.072rem;
          }
        }
        .space-x-24 {
          & > *:not(:first-child) {
            margin-left: 1.5rem;
          }
        }
        .group_4 {
          margin-top: 3.56rem;
          .section_6 {
            opacity: 0.95;
            background-color: #e9e9e9;
            border-radius: 0.94rem;
            width: 15.63rem;
            height: 2.94rem;
            border: solid 0.19rem #df8d34;
          }
          .pos_4 {
            position: absolute;
            left: 3.75rem;
            top: 50%;
            transform: translateY(-50%);
          }
		  .pos_44 {
		    position: absolute;
		    top: 50%;
		    transform: translateY(-50%);
		  }
        }
        .text_2 {
          text-transform: uppercase;
          opacity: 0.8;
		  font-size: 1.1rem;
        }
        .group_5 {
			font-size: 1.1rem;
          margin-top: 2.88rem;
          padding: 0 2.38rem;
          .text-wrapper_2 {
            margin-right: 0.5rem;
            padding: 1rem 0;
            background-color: #fcfcfc91;
            border-radius: 0.94rem;
            width: 15.63rem;
            .text_6 {
              line-height: 0.94rem;
              text-transform: uppercase;
              opacity: 0.84;
            }
          }
          .image-wrapper {
            margin-right: 0.5rem;
            padding: 1rem 0;
            background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882057507515801764.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            .image_7 {
              width: 11.5rem;
              height: 1rem;
            }
          }
          .button {
            margin-right: 0.5rem;
            padding: 1rem 0;
            background-color: #fcfcfc80;
            border-radius: 0.94rem;
            .image_8 {
              width: 10.5rem;
              height: 1rem;
            }
          }
          .button_3 {
            margin-right: 0.5rem;
            padding: 1rem 0;
            background-color: #fcfcfc80;
            border-radius: 0.94rem;
            width: 15.63rem;
            .text_13 {
              color: #434343;
            }
          }
        }
        .space-y-46 {
          & > *:not(:first-child) {
            margin-top: 2.88rem;
          }
        }
        .font_2 {
          font-size: 0.94rem;
          font-family: Adobe Heiti Std;
          color: #2d2d2d;
        }
        .text_16 {
          margin-top: 19.81rem;
          color: #fcfcfc;
          font-size: 1.13rem;
          font-family: Adobe Heiti Std;
          line-height: 1.06rem;
          text-transform: uppercase;
          opacity: 0.79;
        }
      }
      .group_2 {
        padding-top: 32rem;
        .image-wrapper_2 {
          padding: 6rem 0 20rem;
          background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882057507331846491.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          .image_9 {
            margin-right: 43.88rem;
            width: 2rem;
            height: 5rem;
          }
        }
        .section_4 {
          padding: 2rem 3.19rem 2.81rem;
          background-color: #4949493d;
          border-radius: 0.5rem;
          width: 19.56rem;
          .image_5 {
            width: 9.25rem;
            height: 1.19rem;
          }
          .text-wrapper {
            margin-top: 1rem;
            padding: 0.5rem 0;
            background-color: #fcfcfc7d;
            border-radius: 0.5rem;
            width: 13.19rem;
            .font_1 {
              font-size: 0.81rem;
              font-family: Adobe Heiti Std;
              line-height: 1.13rem;
              color: #3d3d3d;
            }
            .text_4 {
              margin-left: 1.56rem;
              text-transform: uppercase;
              opacity: 0.34;
            }
            .text_5 {
              margin-left: 1.63rem;
            }
          }
          .view {
            margin-top: 0;
          }
          .group_6 {
            margin-top: 3.31rem;
            padding-left: 1.13rem;
            .group_7 {
              width: 8.38rem;
              .image_6 {
                width: 7.56rem;
                height: 6.69rem;
              }
              .pos_5 {
                position: absolute;
                left: 0;
                top: 0.63rem;
              }
            }
            .text_8 {
              margin-top: 2.25rem;
            }
          }
          .space-x-14 {
            & > *:not(:first-child) {
              margin-left: 0.88rem;
            }
          }
          .font_3 {
            font-size: 0.69rem;
            font-family: Adobe Heiti Std;
            line-height: 0.69rem;
            color: #3d3d3d;
          }
          .text_7 {
            text-transform: uppercase;
            opacity: 0.73;
          }
          .text_11 {
            margin-top: 0.38rem;
          }
          .button_2 {
            margin-top: 2.5rem;
            padding: 0.75rem 0;
            background-color: #df8d34;
            border-radius: 0.5rem;
            width: 10.81rem;
            .text_12 {
              color: #ffffff;
              font-size: 1.56rem;
              font-family: Adobe Heiti Std;
              line-height: 1.63rem;
            }
          }
        }
        .space-y-37 {
          & > *:not(:first-child) {
            margin-top: 2.31rem;
          }
        }
        .pos_2 {
          position: absolute;
          right: 6.13rem;
          top: 12rem;
        }
        .section_5 {
          padding: 15.25rem 4.56rem 8rem;
          background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/649947d8726ab50011f379b8/16882057513498483953.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          width: 61.88rem;
          .text-wrapper_3 {
            padding: 0.63rem 0 0.88rem;
            background-color: #f7f7f7;
            border-radius: 0.5rem;
            width: 13.19rem;
            border: solid 0.19rem #cb8234;
            .text_10 {
              color: #3b2a2a;
            }
          }
          .text-wrapper_4 {
            padding: 0.63rem 0 0.81rem;
            background-color: #f7f7f7;
            border-radius: 0.5rem;
            width: 13.19rem;
            border: solid 0.19rem #cb8234;
            .text_14 {
              color: #292e35;
            }
          }
        }
        .space-y-178 {
          & > *:not(:first-child) {
            margin-top: 11.13rem;
          }
        }
        .pos_3 {
          position: absolute;
          left: 16rem;
          top: 16rem;
        }
        .text-wrapper_5 {
          padding: 0.63rem 0 0.88rem;
          background-color: #f7f7f7;
          border-radius: 0.5rem;
          width: 13.13rem;
          border: solid 0.19rem #cb8234;
          .text_15 {
            color: #433232;
          }
        }
        .pos_6 {
          position: absolute;
          left: 27.19rem;
          top: 42.25rem;
        }
        .font_4 {
          font-size: 1.38rem;
          font-family: Adobe Heiti Std;
          line-height: 1.31rem;
        }
      }
      .text_9 {
        text-transform: uppercase;
      }
    }
  }
  input::placeholder {
    color: #000000;
    font-size: 1.2em;
  }
  .unit {
    display: inline-block;
  }

  .van-tabbar-item {
    /* Adjust these to change the style of the tabs */
    width: 150px;
    text-align: center;
    border-radius: 10px 10px 0 0;
    border: 2px solid orange;
    font-size: 1.2rem;
  }
  
  .van-tabbar-item.active {
    color: #164683;
    border-bottom: none;
    font-weight: 300;
  }
</style>
