import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../pages/login/login.vue';
import Caidanlan_chanpin3 from '../pages/caidanlan_chanpin3/caidanlan_chanpin3.vue';
import Caidanlan_chanpin4 from '../pages/caidanlan_chanpin4/caidanlan_chanpin4.vue';
import Chanpin4 from '../pages/chanpin4/chanpin4.vue';
import Chanpin6 from '../pages/chanpin6/chanpin6.vue';
import Chanpin1 from '../pages/chanpin1/chanpin1.vue';
import Chanpin3 from '../pages/chanpin3/chanpin3.vue';
import Caidanlan_chanpin2 from '../pages/caidanlan_chanpin2/caidanlan_chanpin2.vue';
import Chanpin2 from '../pages/chanpin2/chanpin2.vue';
import Caidanlan1 from '../pages/caidanlan1/caidanlan1.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
  },
  {
    path: '/caidanlan_chanpin3',
    name: 'caidanlan_chanpin3',
    component: Caidanlan_chanpin3,
  },
  {
    path: '/caidanlan_chanpin4',
    name: 'caidanlan_chanpin4',
    component: Caidanlan_chanpin4,
  },
  {
    path: '/chanpin4',
    name: 'chanpin4',
    component: Chanpin4,
  },
  {
    path: '/chanpin1',
    name: 'chanpin1',
    component: Chanpin1,
  },
	{
	  path: '/chanpin6',
	  name: 'chanpin6',
	  component: Chanpin6,
	},
  {
    path: '/chanpin3',
    name: 'chanpin3',
    component: Chanpin3,
  },
  {
    path: '/caidanlan_chanpin2',
    name: 'caidanlan_chanpin2',
    component: Caidanlan_chanpin2,
  },
  {
    path: '/chanpin2',
    name: 'chanpin2',
    component: Chanpin2,
  },
  {
    path: '/caidanlan1',
    name: 'caidanlan1',
    component: Caidanlan1,
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;