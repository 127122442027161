import { render, staticRenderFns } from "./caidanlan_chanpin2.vue?vue&type=template&id=0d6656e2&scoped=true&"
import script from "./caidanlan_chanpin2.vue?vue&type=script&lang=js&"
export * from "./caidanlan_chanpin2.vue?vue&type=script&lang=js&"
import style0 from "./caidanlan_chanpin2.vue?vue&type=style&index=0&id=0d6656e2&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d6656e2",
  null
  
)

export default component.exports